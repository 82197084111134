<template>
  <div>

		<!-- Layer Banner -->
    <transition name="el-fade-in">
      <div class="layerBackdrop" v-if="layerBanner.open">
        <div ref="layerBannerModal" @click="clickLayerBanner()">
          <display-container :section="2">
            <div slot-scope="{ component, row }">
              <div class="ad_wrap">
                <div class="banner_wrap modal">
                  <div class="ad_google">
                    <img :src="component.getImage(row)">
                  </div>
                  <div class="btn_wrap">
                    <a class="right" @click.stop="closeLayerBanner()">닫기</a>
                  </div>
                </div>
              </div>
            </div>
          </display-container>
          <q-btn round dense color="black" icon="close" size="md" class="absolute-top-right" @click.stop="closeLayerBanner()" />
        </div>
      </div>
    </transition>

    <!-- Bottom Toast Banner -->
    <transition name="el-zoom-in-top">
      <div class="toastBackdrop" v-if="toastBanner.bottom">
        <div @click="clickToggleBanner()" style="position: relative; padding: 11px 11px 0">
          <display-container :section="3">
            <div slot-scope="{ component, row }">
              <div class="ad_wrap">
                <div class="banner_wrap toast">
                  <div class="ad_google">
                    <img :src="component.getImage(row)" width="320" height="100">
                  </div>
                  <div class="btn_wrap">
                    <a class="left">오늘 하루 열지 않기</a>
                    <a class="right" @click.stop="closeToastBanner()">닫기</a>
                  </div>
                </div>
              </div>
            </div>
          </display-container>
          <!--<q-btn round dense color="black" icon="close" size="md" class="absolute-top-right" style="top: -0px; right: -0px" @click.stop="closeToastBanner()" />-->
        </div>
      </div>
    </transition>

    <!-- 공유 -->
		<q-dialog v-model="isOpenShareDialog">
			<div class="share_pop">
				<a class="close_btn" @click="toggleShareDialog(false)">팝업 닫기</a>
				<ul class="chan">
					<li class="kakaotalk" onclick="javascript:sendLink();"><i></i><p>카카오톡</p></li>
					<li class="kakaostory" onclick="window.open('https://story.kakao.com/share?url=' + location.href, '', 'scrollbars=no, width=600, height=600'); return false; "><i></i><p>카카오스토리</p></li>
					<li class="facebook" onclick="window.open('https://www.facebook.com/sharer/sharer.php?u=' + location.href, '', 'scrollbars=no, width=600, height=600'); return false;"><i></i><p>페이스북</p></li>
					<li class="twitter" onclick="window.open('https://twitter.com/intent/tweet?text=' + document.title + '&url=' + location.href, '', 'scrollbars=no, width=600, height=600'); return false;"><i></i><p>트위터</p></li>
					<li class="naver" onclick="window.open('https://share.naver.com/web/shareView.nhn?url=' + encodeURI(location.href) + '&title=' + encodeURI(document.title), '', 'scrollbars=no, width=600, height=600'); return false;"><i></i><p>네이버</p></li>
					<li class="band" onclick="window.open('http://band.us/plugin/share?body=' + location.href + '%0A' + encodeURI(document.title), '', 'scrollbars=no, width=584, height=635'); return false;"><i></i><p>밴드</p></li>
					<li class="line" onclick="window.open('https://social-plugins.line.me/lineit/share?url=' + location.href, '', 'scrollbars=no, width=584, height=635'); return false;"><i></i><p>라인</p></li>
					<li class="link" onclick="copyUrl();"><i></i><p>URL 복사</p></li>
				</ul>
				<input type="text" id="shareUrl" value="">
			</div>
		</q-dialog>

    <b v-model="isLoadedDisplayData"></b>

    <q-dialog
      v-model="drawerRight"
      :maximized="true"
      transition-show="slide-left"
      transition-hide="slide-right"
    >
      <q-layout class="bg-primary drawerLayout">

        <q-header class="bg-primary">

          <q-toolbar class="q-px-none q-mt-md" style="margin-top:0;">
            <q-toolbar-title />
            <span class="btn_hbg_close" @click="drawerRight=false"></span>
          </q-toolbar>

          <q-toolbar class="q-px-lg q-mt-md no-flex-center" style="margin-top:8px;">
            <div class="hd_search on">
              <div class="sch_box">
                <input type="text" placeholder="호텔, 콘텐츠 검색" :disabled="tagVisible" v-model="keyword" ref="keyword" @keyup.enter="setSearchVisible" @click="setKeywordHide">

                <!-- 검색창 고정 키워드 태그 -->
                <q-chip clickable removable v-model="tagVisible" class="tag" text-color="white"></q-chip>
                <a class="sch_btn" @click="setSearchVisible" onclick="gtag('event', '버튼클릭', {'event_category': 'GNB','event_label': '통합검색',  'non_interaction': true});">검색하기</a>
              </div>

            </div>
          </q-toolbar>

        </q-header>

        <q-page-container>
          <q-page class="q-px-lg">
            <q-list separator class="q-pt-lg">

							<q-btn flat class="text-h5 q-pa-none full-width" align="left" @click="gotoRoute('/home')" onclick="gtag('event', '버튼클릭', {'event_category': 'BURGER','event_label': 'HOME',});" :class="{on: isFooterVisible('Home')}">Home</q-btn>
							<q-btn flat class="text-h5 q-pa-none full-width" align="left" @click="gotoRoute('/chart/hot100')" onclick="gtag('event', '버튼클릭', {'event_category': 'BURGER','event_label': 'RANKING',});" :class="{on: isFooterVisible('ChartView')}">Ranking</q-btn>
							<q-btn flat class="text-h5 q-pa-none full-width" align="left" @click="gotoRoute('/search')" onclick="gtag('event', '버튼클릭', {'event_category': 'BURGER','event_label': 'SEARCH',});" :class="{on: isFooterVisible('Search')|| isFooterVisible('SearchHotel') || isFooterVisible('HotelDetail')}">Search</q-btn>
							<!--<q-btn flat class="text-h5 q-pa-none full-width" align="left" @click="gotoRoute('/contents')" onclick="gtag('event', '버튼클릭', {'event_category': 'BURGER','event_label': 'CONTENTS',});">CONTENTS</q-btn>-->

              <q-expansion-item class="text-h5" label="CONTENTS" onclick="gtag('event', '버튼클릭', {'event_category': 'BURGER','event_label': 'CONTENTS',});" :class="{on: isFooterVisible('Contents')	|| isFooterVisible('ContentsDetail') || isFooterVisible('ContentsEditor')}">
                <q-item class="text-grey-5 menu_2depth first">
                  <q-btn flat class="text-h6 q-py-none full-width" align="left"  @click="gotoRoute('/contents/check-in')">Check-in</q-btn>
                </q-item>
                <q-item class="text-grey-5 menu_2depth">
                  <q-btn flat class="text-h6 q-py-none full-width" align="left"  @click="gotoRoute('/contents/pick')">Pick</q-btn>
                </q-item>
                <q-item class="text-grey-5 menu_2depth">
                  <q-btn flat class="text-h6 q-py-none full-width" align="left"  @click="gotoRoute('/contents/story')">Story</q-btn>
                </q-item>
                <q-item class="text-grey-5 menu_2depth last">
                  <q-btn flat class="text-h6 q-py-none full-width" align="left"  @click="gotoRoute('/contents/news')">News</q-btn>
                </q-item>
              </q-expansion-item>

							<q-btn flat class="text-h5 q-pa-none full-width" align="left" @click="gotoRoute('/airline')" onclick="gtag('event', '버튼클릭', {'event_category': 'BURGER','event_label': 'FLIGHT',});" :class="{on: isFooterVisible('Airline') || isFooterVisible('AirlineDetail')}">Flight</q-btn>
							<q-btn flat class="text-h5 q-pa-none full-width" align="left" @click="gotoRoute('/video')" onclick="gtag('event', '버튼클릭', {'event_category': 'BURGER','event_label': 'VIDEO',});" :class="{on: isFooterVisible('Video') || isFooterVisible('VideoDetail')}">Video</q-btn>
							<!--<q-btn flat class="text-h5 q-pa-none full-width" align="left" @click="gotoRoute('/special')" onclick="gtag('event', '버튼클릭', {'event_category': 'BURGER','event_label': 'RECOMMENDED',});">Recommended</q-btn>-->

              <!--<q-item>
                <q-item-section class="menu_ftr sns">
                  <a href="https://www.youtube.com/channel/UCOrATGUGs2DuBbZwQFJ0QpQ" target="_blank"><img src="/statics/img/sns_youtube.png"></a>
                  <a href="https://m.post.naver.com/card-gorilla" target="_blank"><img src="/statics/img/sns_n_post.png"></a>
                  <a href="https://brunch.co.kr/@cardgorilla" target="_blank"><img src="/statics/img/sns_brunch.png"></a>
                  <a href="https://www.instagram.com/cardgorilla_official/?hl=ko" target="_blank"><img src="/statics/img/sns_instagram.png"></a>
                  <a href="https://pf.kakao.com/_aaPGT" target="_blank"><img src="/statics/img/sns_kakao.png"></a>
                </q-item-section>
              </q-item>-->

              <!-- 하단 큐레이션 -->
							<!--<div class="menu_ftr">
                <display-container :section="4">
                  <div slot-scope="{ component, row }">
                    <div class="ad_google curation">
                      <img :src="component.getImage(row)" width="238px" height="100px"/>
                    </div>
                  </div>
                </display-container>
							</div>-->

            </q-list>
          </q-page>
        </q-page-container>

      </q-layout>
    </q-dialog>

    <!-- 이용약관 -->
		<q-dialog v-model="isOpenTermsDialog" maximized>
			<div class="share_pop info_pop">
				<a class="close_btn" @click="toggleTermsDialog(false);">팝업 닫기</a>
				<h3>이용약관</h3>
				<div class="contain">
					<h6>제1조 (목적)</h6>
					<p>이 약관은 주식회사 고릴라디스트릭트(이하 ‘회사’라 합니다)가 제공하는 럭셔리 호텔항공 리뷰 플랫폼 ‘프레스티지고릴라' 인터넷관련 서비스(이하 ‘서비스’라 합니다)의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</p>
					<h6>제2조 (정의)</h6>
					<p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
					<ol>
						<li>‘회원’이라 함은 ‘회사’의 ‘서비스’에 접속하여 이 약관에 따라 ‘회사’와 이용계약을 체결하고 ‘회사’가 제공하는 ‘서비스’를 이용하는 고객을 말합니다.</li>
						<li>‘서비스’라 함은 구현되는 단말기(PC, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 ‘회원’이 이용할 수 있는 프레스티지고릴라 관련 제반 서비스를 의미합니다.</li>
						<li>‘아이디(ID)’라 함은 ‘회원’의 식별과 서비스 이용을 위하여 ‘회원’이 정하고 ‘회사’가 승인하는 ’회원’의 전자메일 주소를 의미합니다.</li>
						<li>‘비밀번호’라 함은 ‘회원’이 부여받은 ‘아이디와 일치되는 ‘회원’임을 확인하고 비밀보호를 위해 ‘회원’ 자신이 정한 문자 또는 숫자의 조합을 의미합니다.</li>
						<li>‘운영자(관리자)’라 함은 서비스의 전반적인 관리와 원활한 운영을 위하여 회사에서 선정한 사람 또는 회사를 말합니다.</li>
						<li>이 약관에서 정의되지 않은 용어는 관련법령이 정하는 바에 따릅니다.</li>
					</ol>
					<h6>제3조 (약관의 게시와 개정)</h6>
					<ol>
						<li>‘회사’는 이 약관의 내용을 ‘회원’이 쉽게 알 수 있도록 서비스 초기 화면에 게시합니다.</li>
						<li>‘회사’는 ‘약관의 규제에 관한법률’, ‘정보통신망 이용촉진 및 정보보호에 관한법률(이하 ‘정보통신망법’)’ 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</li>
						<li>‘회사’가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 30일 전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리한 내용으로 약관을 개정하는 경우에는 공지 외에 회원정보에 등록된 이메일 등 전자적 수단을 통해 별도로 명확히 통지하도록 합니다.</li>
						<li>‘회사’가 전항에 따라 공지하면서 회원에게 30일 기간 이내에 의사표시를 하지 않으면 승인한 것으로 본다는 뜻을 명확하게 공지하였음에도 회원이 명시적으로 거부의사를 밝히지 않은 경우에 회원이 개정약관에 동의한 것으로 봅니다.</li>
						<li>‘회원’이 개정약관에 동의하지 않는 경우 회사는 개정약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.</li>
					</ol>
					<h6>제4조 (약관의 해석)</h6>
					<ol>
						<li>회사는 개별 서비스에 대해서는 별도의 이용약관 및 정책(‘기타 약관 등’이라 함)을 둘 수 있으며, 해당 내용이 이 약관과 상충할 경우에는 ‘기타 약관 등’이 우선하여 적용됩니다.</li>
						<li>이 약관에서 정하지 아니한 사항이나 해석에 대해서는 ‘기타 약관 등’ 및 관계법령 또는 상관례에 따릅니다.</li>
					</ol>
					<h6>제5조 (이용계약 체결)</h6>
					<ol>
						<li>이용계약은 ‘회원’이 되고자 하는 자(이하 ‘가입신청자’)가 본 이용약관, 개인정보 처리방침의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 ‘회사’가 이러한 신청에 대하여 승낙함으로써 체결됩니다.</li>
						<li>‘회사’는 ‘가입신청자’의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 다만, ‘회사’는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.<br>
							가. 가입신청자가 가입신청일 당시 만 14세 미만일 경우<br>
							나. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우<br>
							다. 허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우<br>
							라. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우</li>
						<li>‘회사’는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.</li>
						<li>제2항과 제3항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, ‘회사’는 원칙적으로 이를 가입신청자에게 알리도록 합니다.</li>
						<li>이용계약의 성립 시기는 ‘회사’가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.
						<li>‘회사’는 ‘회원’에 대해 회사정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.</li>
					</ol>
					<h6>제6조 (회원정보의 변경)</h6>
					<ol>
						<li>‘회원’은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 아이디 등은 수정이 불가능합니다.</li>
						<li>‘회원’은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 ‘회사’에 대하여 그 변경사항을 알려야 합니다.</li>
						<li>제2 항의 변경사항을 ‘회사’에 알리지 않아 발생한 불이익에 대하여 ‘회사’는 책임지지 않습니다.</li>
					</ol>
					<h6>제7조 (개인정보보호 의무)</h6>
					<ol>
						<li>‘회사’는 ‘정보통신망법’ 등 관계 법령이 정하는 바에 따라 ‘회원’의 ‘개인정보’를 보호하기 위해 노력합니다. ‘개인정보’의 보호 및 사용에 대해서는 관련법 및 ‘회사’의 개인정보보호정책이 적용됩니다. 다만, ‘회사’의 공식 사이트 이외의 링크된 사이트에서는 ‘회사’의 개인정보보호정책이 적용되지 않습니다.</li>
					</ol>
					<h6>제8조 (‘회원’의 ‘아이디’ 및 ‘비밀번호’의 관리에 대한 의무)</h6>
					<ol>
						<li>‘회원’의 ‘아이디’와 ‘비밀번호’에 관한 관리책임은 ‘회원’에게 있으며, 이를 제3 자가 이용하도록 하여서는 안 됩니다.</li>
						<li>‘회사’는 회원의 ‘아이디’가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 회사 및 회사의 운영자로 오인한 우려가 있는 경우, 해당 ‘아이디’의 이용을 제한할 수 있습니다.</li>
						<li>‘회원’은 ‘아이디’및 ‘비밀번호’가 도용되거나 제3 자가 사용하고 있음을 인지한 경우에는 이를 즉시 ‘회사’에 통지하고 ‘회사’의 안내에 따라야 합니다.</li>
						<li>제3 항의 경우에 해당 ‘회원’이 ‘회사’에 그 사실을 통지하지 않거나, 통지한 경우에도 ‘회사’의 안내에 따르지 않아 발생한 불이익에 대하여 ‘회사’는 책임지지 않습니다.</li>
					</ol>
					<h6>제9조 (‘회원’에 대한 통지)</h6>
					<ol>
						<li>‘회사’가 ‘회원’에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 ‘회원’이 지정한 전자우편주소로 할 수 있습니다.</li>
						<li>‘회사’는 ‘회원’ 전체에 대한 통지의 경우 7 일 이상 ‘회사’의 게시판에 게시함으로써 제1 항의 통지에 갈음할 수 있습니다.</li>
					</ol>
					<h6>제10조 (‘회사’의 의무)</h6>
					<ol>
						<li>‘회사’는 관련법과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 ‘서비스’를 제공하기 위하여 최선을 다하여 노력합니다.</li>
						<li>‘회사’는 ‘회원’이 안전하게 ‘서비스’를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보보호정책을 공시하고 준수합니다.</li>
						<li>‘회사’는 서비스이용과 관련하여 ‘회원’으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 회원이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 ‘회원’에게 처리과정 및 결과를 전달합니다.</li>
					</ol>
					<h6>제11조 (서비스 이용의 중지 또는 계약의 해지)</h6>
					<ol>
						<li>‘회원’은 다음 행위를 하여서는 안 됩니다.<br>
							가. 신청 또는 변경 시 허위내용의 등록<br>
							나. 타인의 정보도용<br>
							다. ‘회사’에 게시된 정보의 변경<br>
							라. ‘회사’가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시<br>
							마. ‘회사’와 기타 제3 자의 저작권 등 지적재산권에 대한 침해<br>
							바. ‘회사’ 및 기타 제3 자의 명예를 손상시키거나 업무를 방해하는 행위<br>
							사. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 ‘회사’에 공개 또는 게시하는 행위<br>
							아. 회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위<br>
							자. 기타 불법적이거나 부당한 행위</li>
						<li>‘회원’은 관계법, 본 약관의 규정, 이용안내 및 ‘서비스’와 관련하여 공지한 주의사항, ‘회사’가 통지하는 사항 등을 준수하여야 하며, 기타 ‘회사’의 업무에 방해되는 행위를 하여서는 안 됩니다.</li>
					</ol>
					<h6>제12조 (서비스의 제공 등)</h6>
					<ol>
						<li>회사는 회원에게 아래와 같은 서비스를 제공합니다.<br>
							가. 프레스티지고릴라: 호텔과 항공 예약을 위한 호텔 예약서비스 및 글.영상 형태의 콘텐츠<br>
							나. 기타 회사가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 회원에게 제공하는 일체의 서비스(기존 서비스를 활용하기 위한 API 서비스 등 포함)</li>
						<li>회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다. 다만 이러한 경우에는 그 내용을 사전에 공지합니다.</li>
						<li>서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.</li>
						<li>‘회사’는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 ‘회사’는 제9 조[‘회원’에 대한 통지]에 정한 방법으로 ‘회원’에게 통지합니다. 다만, ‘회사’가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.</li>
						<li>‘회사’는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스제공화면에 공시한 바에 따릅니다.</li>
					</ol>
					<h6>제13조 (서비스의 변경)</h6>
					<ol>
						<li>‘회사’는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 서비스를 변경할 수 있습니다.</li>
						<li>서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전 7 일 이상 해당 서비스 초기화면에 게시하여야 합니다.</li>
						<li>회사는 무료로 제공되는 토큰 및 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.</li>
					</ol>
					<h6>제14조 (정보의 제공 및 광고의 게재)</h6>
					<ol>
						<li>회사’는 ‘회원’이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로 ‘회원’에게 제공할 수 있습니다.</li>
						<li>다만, ‘회원’은 관련법에 따른 거래관련 정보, 고객센터 답변 등을 제외하고 언제든지 전자우편 등을 통하여 수신 거절을 할 수 있습니다.</li>
						<li>제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 ‘회원’의 사전 동의를 받아서 전송합니다.</li>
						<li>‘회사’는 ‘서비스’의 운영과 관련하여 서비스 화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편을 수신한 ‘회원’은 수신거절을 ‘회사’에게 할 수 있습니다.</li>
					</ol>
					<h6>제15조 (권리의 귀속)</h6>
					<ol>
						<li>‘서비스’에 대한 저작권 및 지적재산권은 회사에 귀속됩니다. 단, 회원의 게시물 및 제휴계약에 따라 제공된 저작물 등은 제외합니다.</li>
						<li>‘회사’는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 계정, 아이디, 콘텐츠 등을 이용할 수 있는 이용권만을 부여하며, ‘회원’은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.</li>
					</ol>
					<h6>제16조 (‘회원’의 계약해제, 해지 등)</h6>
					<ol>
						<li>‘회원’은 언제든지 자기 정보 관리 메뉴를 통하여 서비스 탈퇴 신청을 할 수 있으며, ‘회사’는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.</li>
						<li>‘회원’이 계약을 해지할 경우, 관련법 및 개인정보보호정책에 따라 회사가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 회원의 모든 데이터는 소멸됩니다.</li>
						<li>‘회원’이 계약을 해지하였으나 본인 계정에 등록하고 공개된 데이터는 보존됩니다.</li>
					</ol>
					<h6>제17조 (이용제한 등)</h6>
					<ol>
						<li>‘회사’는 ‘회원’이 본 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우, 서비스 이용을 경고, 일시정지, 계약해지로 단계적으로 제한할 수 있습니다.</li>
						<li>‘회사’는 전항에도 불구하고, ‘저작권법’ 및 ‘컴퓨터프로그램보호법’을 위반한 불법프로그램의 제공 및 운영방해, ‘정보통신망법’을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과같이 관련법을 위반한 경우에는 즉시 계약해지를 할 수 있습니다. 본 항에 따른 계약해지 시 서비스 이용을 통해 획득한 혜택 등도 모두 소멸되며, 회사는 이에 대해 별도로 보상하지 않습니다.</li>
						<li>‘회사’는 회원이 계속해서 3개월 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수 있습니다.</li>
						<li>회사는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 이용제한정책 등에서 정한 바에 의합니다.</li>
						<li>본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는 ‘회사’는 제9 조[‘회원’에 대한 통지]에 따라 통지합니다.</li>
						<li>‘회원’은 본 조에 따른 이용제한 등에 대해 ‘회사’가 정한 절차에 따라 이의신청을 할 수 있습니다. 이때 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.</li>
					</ol>
					<h6>제18조 (책임제한)</h6>
					<ol>
						<li>‘회사’는 천재지변 또는 이에 준하는 불가항력으로 인하여 ‘서비스’를 제공할 수 없는 경우에는 ‘서비스’ 제공에 관한 책임이 면제됩니다.</li>
						<li>‘회사’는 ‘서비스’ 내 링크, 다운로드, 광고 등을 포함하여 프레스티지고릴라를 통해 배포, 전송, 노출되는 정보에 대해서 정확성이나 신뢰성이 있는 정보를 제공하기 위해 노력하지만, 그 과정에서 발생할 수 있는 정보의 정확성이나 신뢰성에 대해서는 어떤 보증도 하지 않으며, 정보의 오류로 인해 발생하는 모든 직접, 간접, 파생적, 징벌적, 부수적인 손해에 대해 책임을 지지 않습니다.</li>
						<li>‘회사’에서 제공하는 정보(상품정보, 가격정보, 이미지 등)에 대한 신뢰 여부는 전적으로 이용자 본인의 책임이며, 상품에 대한 자세한 정보는 해당 판매자 사이트에서 이용자 본인이 반드시 확인해야 하며 상품 주문, 배송, 환불의 의무와 책임은 해당 판매자에 있습니다.</li>
						<li>‘회사’에 입점한 모든 예약사이트는 해당 사이트가 독자적으로 소유하고 운영하며 해당 사이트의 전적인 책임 하에 있습니다. 프레스티지고릴라는 사이트의 활동, 사이트에서 제공되는 상품이나 서비스, 또는 게재되는 내용, 사이트의 접속 또는 접속 불능으로 인한 어떠한 손해, 손실, 상해 등 이용자와 사이트 간에 행하여지는 거래에 대해서 명시적으로 어떠한 책임이나 의무도 부담하지 아니합니다. 사이트 등이 제공하는 다양한 상품이나 서비스에 대한 질문과 불만에 대하여는 해당 사이트 등에 직접 연락을 취하시기 바랍니다. 모든 사이트 홈페이지에 연락처 정보를 제공하는 링크를 가지고 있습니다.</li>
						<li>‘회사’는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.</li>
					</ol>
					<h6>제19조 (준거법 및 재판관할)</h6>
					<ol>
						<li>‘회사’와 ‘회원’간 제기된 소송은 대한민국법을 준거법으로 합니다.</li>
						<li>‘회사’와 ‘회원’간 발생한 분쟁에 관한 소송은 민사소송법 상의 관할법원에 제소합니다.</li>
					</ol>
					<h6>부칙</h6>
					<ol>
						<li>이 약관은 2021 년 05월 31일부터 적용됩니다.</li>
						<li>위치정보관리책임자는 2021년 05월 31일을 기준으로 다음과 같이 지정합니다.</li>
					</ol>
					<p>
						회사명 : ㈜고릴라디스트릭트<br>
						대표자 : 고승훈<br>
						연락처 : 02-456-3882<br>
						이메일 : contact_p@gorilladistrict.com<br>
						주소 : 서울시 성동구 서울숲4길 12-7, 스타힐스 2층
					</p>
				</div>
			</div>
		</q-dialog>
    <!-- 이용약관 //-->

    <!-- 개인정보 처리방침 -->
		<q-dialog v-model="isOpenPolicyDialog" maximized>
			<div class="share_pop info_pop">
				<a class="close_btn" @click="togglePolicyDialog(false);">팝업 닫기</a>
				<h3>개인정보 처리방침</h3>
				<div class="contain">
					<p>㈜고릴라디스트릭트(이하 "회사"라 합니다)는 회사가 제공하는 프레스티지고릴라 서비스(이하 "서비스"라 합니다) 회원의 개인정보보호를 매우 중요시하며, 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』등 개인정보와 관련된 법령 상의 개인정보보호규정을 준수하고 있습니다.</p>
					<p>회사는 아래와 같이 개인정보처리방침을 명시하여 회원이 회사에 제공한 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치를 취하는지 알려드립니다.</p>
					<p>회사의 서비스 개인정보처리방침은 정부의 법률 및 지침의 변경과 당사의 약관 및 내부 정책에 따라 변경될 수 있으며 이를 개정하는 경우 회사는 변경사항에 대하여 즉시 서비스 화면에 게시합니다. 회원님께서는 사이트 방문 시 수시로 확인하시기 바랍니다.</p>
					<h6>1. 프레스티지고릴라는 이용하는 서비스의 형태에 따라 다음과 같은 개인정보를 수집 및 이용∙제공∙파기하고 있습니다.</h6>
					<p>① 개인정보의 수집 ∙ 이용 목적 및 항목</p>
					<p>회사가 제공하는 서비스 이용을 위해 수집 ∙ 이용하는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 목적 변경 시 별도의 동의를 받는 등 필요한 조치를 이행합니다.</p>
					<table border="0" cellspacing="0" cellpadding="0">
						<tr>
							<th width="20%">수집방법</th>
							<th width="40%">수집항목</th>
							<th width="40%">수집 및 이용목적</th>
						</tr>
						<tr>
							<td>회원가입(필수)</td>
							<td>이름, 이메일 주소, 휴대전화 번호, 성별, 생년월일</td>
							<td>서비스 이용 및 예약을 위한 이용자 식별<br>맞춤형 콘텐츠 및 상품 추천 등 마케팅 활용</td>
						</tr>
						<tr>
							<td rowspan="2">회원가입(선택)</td>
							<td>카카오톡, 네이버 사용자 ID</td>
							<td>카카오톡, 네이버 등 SNS 계정을 통한 간편 가입시</td>
						</tr>
						<tr>
							<td>프로필사진</td>
							<td>회원서비스 제공</td>
						</tr>
						<tr>
							<td>이벤트 참여</td>
							<td>프레스티지고릴라 회원이름 및 아이디, 소셜미디어 아이디</td>
							<td>이벤트 참여자 식별 및 당첨자 선정</td>
						</tr>
						<tr>
							<td></td>
							<td>※ 이벤트 참여 회원이라 함은 프레스티지고릴라에서 운영하는 소셜미디어(인스타그램, 포스트, 유튜브 등) 및 프레스티지고릴라 사이트 및 앱 등을 통하여 진행하는 이벤트에 참여한 회원을 의미합니다.</td>
							<td></td>
						</tr>
						<tr>
							<td>이벤트 당첨</td>
							<td>이름, 휴대전화 번호, 이메일, 주소</td>
							<td>이벤트 당첨에 따른 경품 전달</td>
						</tr>
					</table>
					<p>② 개인정보 수집 방법</p>
					<p>1) 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우, 해당 개인정보를 수집합니다.</p>
					<p>2) 고객센터를 통한 상담 과정에서 웹 페이지, 메일, 팩스, 전화 등을 통해 이용자의 개인정보가 수집될 수 있습니다.</p>
					<p>3) 회사와 제휴한 외부 기업이나 단체로부터 개인정보를 제공받을 수 있으며, 이러한 경우에는 제휴사에서 이용자에게 개인정보 제공 동의를 받은 후 수집합니다.</p>
					<p>③ 개인정보 보유 ∙ 이용 기간</p>
					<p>1) 회사는 이용자로부터 개인정보 수집 시에 동의 받은 보유 · 이용기간 내에서 개인정보를 처리·보유합니다.</p>
					<p>- 회원 정보: 회원탈퇴 후 1개월 일까지<br>
						- 서비스 이용에 따른 채권·채무관계 정산 시까지</p>
					<p>2) 1 년 간 회원의 서비스 이용 기록이 없는 경우, 『정보통신망 이용촉진 및 정보보호등에 관한 법률』 제29 조에 근거하여 회원에게 사전 통지하고, 별도 보관합니다.</p>
					<p>3) 회원에서 탈퇴한 후 회원 재가입, 임의해지 등을 반복적으로 행하여 회사가 제공하는 할인쿠폰, 이벤트 혜택 등으로 경제상의 이익을 취하거나 이 과정에서 명의를 무단으로 사용하는 편법과 불법행위를 하는 회원을 차단하고자 회원 탈퇴 후 1개월 동안 보관합니다.</p>
					<table border="0" cellspacing="0" cellpadding="0">
						<tr>
							<th width="35%">법령</th>
							<th width="50%">항목</th>
							<th width="15%">기간</th>
						</tr>
						<tr>
							<td rowspan="3">전자상거래 등에서의 소비자보호에 관한 법률</td>
							<td>계약 또는 청약철회 등에 관한 기록</td>
							<td>5년</td>
						</tr>
						<tr>
							<td>대금결제 및 재화 등의 공급에 관한 기록</td>
							<td>5년</td>
						</tr>
						<tr>
							<td>소비자의 불만 또는 분쟁 처리에 관한 기록</td>
							<td>3년</td>
						</tr>
						<tr>
							<td>위치정보의 보호 및 이용 등에 관한 법률</td>
							<td>개인위치정보에 관한 기록</td>
							<td>6개월</td>
						</tr>
						<tr>
							<td>전자금융거래법</td>
							<td>전자금융 거래에 관한 기록</td>
							<td>5년</td>
						</tr>
						<tr>
							<td>통신비밀보호법</td>
							<td>서비스 이용 관련 개인정보(로그기록)</td>
							<td>3개월</td>
						</tr>
					</table>
					<h6>2. 서비스의 제공 또는 법령에 정해진 책임의 준수를 위하여 회사가 보관하고 있는 개인정보가 제3자에게 수집 목적 범위 내에서 제공될 수 있습니다.</h6>
					<p>① 회사는 이용자의 개인정보를 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정에 해당하는 경우에만 개인정보를 제3 자에게 제공합니다.</p>
					<p>② 회사는 다음과 같이 개인정보를 제3 자에게 제공하고 있습니다.</p>
					<table border="0" cellspacing="0" cellpadding="0">
						<tr>
							<th width="25%">개인정보 제공 받는자</th>
							<th width="25%">제공목적</th>
							<th width="25%">제공항목</th>
							<th width="25%">보유 및 이용기간</th>
						</tr>
						<tr>
							<td>호텔스컴바인</td>
							<td>예약서비스 이용 계약 이행(서비스 제공, 이용자 확인, 이용정보 확인)</td>
							<td>이름, 이메일 주소, 휴대전화 번호</td>
							<td>숙박 이용 완료 후 1년</td>
						</tr>
					</table>
					<h6>3. 회사는 서비스 향상과 효과적인 업무처리를 위하여 다음과 같이 개인정보를 처리 위탁하고 있습니다.</h6>
					<p>① 회사는 원활하고 향상된 서비스를 위하여 개인정보 처리를 타인에게 위탁할 수 있습니다. 이 경우 회사는 사전에 다음 각 호의 사항 모두를 이용자에게 미리 알리고 동의를 받습니다.</p>
					<p>(1) 개인정보 처리위탁을 받는 자</p>
					<p>(2) 개인정보 처리위탁을 하는 업무의 내용</p>
					<p>② 회사는 개인정보의 처리와 관련하여 아래와 같이 업무를 위탁하고 있으며, 관계법령에 따라 위탁 계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 조치를 하고 있습니다. 회사는 위탁 계약 시 수탁자의 개인정보 보호조치 능력을 고려하고, 개인정보의 안전한 관리 및 파기 등 수탁자의 의무 이행 여부를 주기적으로 확인합니다. 또한 위탁처리하는 정보는 원활한 서비스를 제공하기 위하여 필요한 최소한의 정보에 국한됩니다.</p>
					<table border="0" cellspacing="0" cellpadding="0">
						<tr>
							<th>수탁업체</th>
							<th>위탁업무 내용</th>
						</tr>
						<tr>
							<td align="center">나이스평가정보 주식회사</td>
							<td align="center">휴대폰 인증 서비스</td>
						</tr>
					</table>
					<h6>4. 회사는 이용자에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다.</h6>
					<p>회사가 이용자에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공하는 경우, 회사는 외부사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다.</p>
					<p>회사가 포함하고 있는 링크를 클릭(click)하여 타사이트(site)의 페이지로 옮겨 갈 경우 해당 사이트의 개인정보보호정책은 회사와 무관하므로 새로 방문한 사이트의 정책을 검토해보시기 바랍니다.</p>
					<h6>5. 이용자 및 법정대리인은 아래와 같은 권리를 행사할 수 있습니다.</h6>
					<p>① 이용자 및 법정대리인은 언제든지 수집 정보에 대하여 수정, 동의철회, 삭제 등을 요청하실 수 있습니다. 다만, 동의 철회․삭제시 서비스의 일부 또는 전부 이용이 제한될 수 있습니다.</p>
					<p>② (조회/수정 및 정정) 홈페이지에서 ‘마이페이지>’계정관리’ 메뉴를 이용하여 처리할 수 있습니다. 처리가 완료될 때까지 해당 정보를 이용하거나 타인에게 제공하지 않을 것입니다. 또한 합리적인 사유로 잘못된 개인정보를 제3자에게 이미 제공한 경우, 정정 처리의 결과를 지체 없이 제3자에게 통지하여 정정하도록 조치합니다.</p>
					<p>③ (회원 탈퇴) 홈페이지의 ‘마이페이지>계정관리>회원탈퇴’ 메뉴를 이용하여 편리하게 직접 탈퇴를 진행할 수 있습니다.</p>
					<p>④ (서비스 재이용) ‘이메일 찾기’와 ‘비밀번호 찾기’ 를 통하여 미이용자 여부를 확인할 수 있으며, 반드시 비밀번호를 변경하신 후 계정 재이용이 가능합니다.</p>
					<h6>6. 자동 수집되는 개인정보 및 거부에 관한 사항은 다음과 같습니다.</h6>
					<p>회사는 이용자의 정보를 수시로 저장하고 찾아내는 쿠키(cookie) 등을 운용합니다. 쿠키는 웹사이트가 이용자의 컴퓨터 브라우저에 보내는 아주 작은 텍스트 파일로 이용자의 컴퓨터 하드디스크에 저장됩니다.</p>
					<p>① 쿠키의 사용 목적</p>
					<p>- 회원의 접속 빈도나 방문 시간 등의 분석, 이용 형태 및 관심분야 파악, 자취 추적, 각종 이벤트 참여 정도, 방문 횟수 파악 등을 통한 타겟 마케팅 등 개인별 맞춤 서비스를 제공하기 위해 쿠키를 사용합니다.</p>
					<p>② 쿠키의 설치, 운영과 거부</p>
					<p>- 이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다.<br>
						- 쿠키의 설정은 웹브라우저별 옵션 선택을 통해 ①모든 쿠키를 허용, ②쿠키가 저장될 때 마다 확인, ③모든 쿠키의 저장을 거부 할 수 있습니다.<br>
						- 이러한 쿠키의 설정 방법은 웹브라우저에 따라 차이가 있으므로, 자세한 사항은 각 브라우저별 도움말을 참고해 주시기 바랍니다.<br>
						- Internet Explorer : 도구 메뉴 선택 > 인터넷 옵션 선택 > 개인정보 탭 클릭 > 개인정보처리 수준 설정<br>
						- Chrome : 설정 메뉴 선택 > 고급 설정 표시 선택 > 개인정보-콘텐츠 설정 선택 > 쿠키 수준 설정<br>
						- firefox : 옵션 메뉴 선택 > 개인정보 선택 > 방문기록-사용자 정의 설정 > 쿠키 수준 설정<br>
						- safari : 환경설정 메뉴 선택 > 개인정보 탭 선택 > 쿠키 및 웹 사이트 데이터 수준 설정<br>
						- 단, 쿠키의 저장을 거부하였을 경우에는 로그인이 필요한 서비스 등 일부 서비스 이용에 어려움이 있을 수 있습니다.</p>
					<h6>7. 맞춤형 광고</h6>
					<p>고객에게 맞춤형 광고를 제공하기 위하여 회사는 웹 브라우저에서는 ‘쿠키’, 모바일 앱에서는 광고식별자(ADID)를 수집하여 사용합니다. 회사는 쿠키 및 광고식별자를 통해 고객의 서비스 사용 이력을 자동으로 수집하고 이를 활용하여 고객 맞춤 광고를 진행합니다. 회사에서 수집하는 쿠키 및 광고식별자는 다른 개인정보와 연계되지 않으며 개인을 식별하지 않습니다.</p>
					<p>사용자는 언제든지 이러한 맞춤형 광고 수신을 거부할 수 있습니다.</p>
					<h6>8. 회사는 이용자들의 개인정보를 처리함에 있어 개인정보의 기술적/관리적 보호 대책을 마련하여 정보보호를 위해 노력하고 있습니다.</h6>
					<p>회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 보호대책을 강구하고 있습니다.</p>
					<p>① 비밀번호의 암호화</p>
					<p>- 이용자의 비밀번호는 일방향 암호화하여 저장 및 관리되고 있으며, 개인정보의 확인 및 변경은 비밀번호를 알고 있는 본인에 의해서만 가능합니다.</p>
					<p>② 해킹 등에 대비한 대책</p>
					<p>- 회사는 해킹이나 바이러스 등 회사 정보통신망 침입에 의한 이용자 개인정보의 분실, 도난, 유출, 변조 또는 훼손 등 피해 방지를 위해 침입탐지 및 침입차단 시스템을 24시간 가동하고 있으며, 만일의 사태에 대비하여 모든 침입탐지 시스템과 침입차단 시스템은 이중화로 구성하여 운영하고 있습니다.<br>
						- 개인정보의 훼손 피해에 대비하여 중요한 데이터를 주기적으로 백업하고 있으며, 백신 프로그램을 이용하여 개인정보나 중요한 데이터의 유출 방지를 위해 노력하고 있습니다.<br>
						- 민감한 개인정보는 정보통신망을 통해 주고받는 과정에서 암호화 통신을 적용하여 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.<br>
						- 이 외에도 보안 시스템 도입 및 전문인력 확충 등 보안성 확보를 위해 지속적으로 노력하고 있습니다.</p>
					<p>③ 개인정보 처리자의 최소화 및 주기적인 교육</p>
					<p>- 회사의 개인정보 관련 처리자는 담당자로 한정하여 개인정보 처리자를 최소화하고 있으며, 퇴직 및 직무변경 등 인사이동이 있는 경우 지체 없이 권한을 변경, 말소 하여 개인정보 접근 권한을 통제 하고 있습니다.<br>
						- 개인정보 처리자에 대해 주기적으로 교육을 진행하여 개인정보보호의 중요성을 인식시키고, 안전하게 관리할 수 있도록 최선의 노력을 다하고 있습니다.</p>
					<h6>9. 프레스티지고릴라는 귀하의 안전한 개인정보 보호를 위해 개인정보 보호책임자를 지정하고 있습니다.</h6>
					<p>① 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</p>
					<p>개인정보보호책임자</p>
					<p>성명 : 박 준 영<br>
						연락처 : 02-456-3887<br>
						contact_p@gorilladistrict.com</p>
					<p>② 회사가 제공하는 서비스(또는 사업)를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 고객지원센터로 문의하실 수 있으며, ㈜고릴라디스트릭트는 이런 문의에 대해 지체 없이 답변 및 처리할 것입니다.</p>
					<p>③ 개인정보가 침해 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</p>
					<p>‣ 개인정보 침해신고센터 (한국인터넷진흥원 운영) : (국번없이) 118 / privacy.kisa.or.kr<br>
						‣ 대검찰청 사이버범죄수사단 : (국번없이) 1301 / www.spo.go.kr<br>
						‣ 경찰청 사이버안전국 : (국번없이) 182 / www.cyber.go.kr<br>
						‣ 전자거래분쟁조정위원회 : 1661-5714 / www.ecmc.or.kr</p>
					<h6>10. 개인정보 처리방침 변경에 관한 정책은 다음과 같습니다.</h6>
					<p>이 개인정보 처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다. 또한 관련 법령이나 회사 정책의 변경으로 불가피하게 처리방침을 변경해야 하는 경우, 웹사이트 공지사항을 통해 빠르게 알려드리고 있으니 참고하여 주시기 바랍니다.</p>
				</div>
			</div>
		</q-dialog>
    <!-- 개인정보 처리방침 //-->

    <transition name="el-fade-in-linear">
      <Header @drawer="toggleDrawer()" />
    </transition>

    <!-- contentArea -->
    <section class="contentArea">
      <!-- <SubHeader/> -->

      <!--<transition name="el-fade-in-linear">-->
        <keep-alive :exclude="excludeComponentNames">
          <router-view></router-view>
        </keep-alive>
      <!--</transition>-->

      <SubFooter/>

    </section>
    <!-- //contentArea -->

    <Footer/>

    <search-hotel-modal ref="modal" />

  </div>
</template>

<script>
  import Header from '../components/Header'
  import Footer from '../components/Footer'
  import SubHeader from '../components/SubHeader'
  import SubFooter from '../components/SubFooter'
  import DisplayContainer from '../components/DisplayContainer'
  import SearchHotelModal from '../components/SearchHotelModal'
  import CommonComputed from 'src/_mixins/CommonComputed'
  import CommonMethods from 'src/_mixins/CommonMethods'
  import { QLayout,QHeader,QDrawer,QScrollArea,QPageContainer,QPage,QToolbar,QToolbarTitle,QDialog,QBtn,QList,QItem,QItemSection,QItemLabel,QExpansionItem,QChip } from 'quasar'
  import { mapGetters } from 'vuex'
  const keyword = '인피니티풀'
  export default {
    name: "DefaultLayout",
    components: {
      DisplayContainer,
      SearchHotelModal,
      Header,
      Footer,
      SubHeader,
      SubFooter,
      QLayout,
      QHeader,
      QDrawer,
      QScrollArea,
      QPageContainer,
      QPage,
      QToolbar,
      QToolbarTitle,
      QDialog,
      QBtn,
      QList,
      QItem,
      QItemSection,
      QItemLabel,
      QExpansionItem,
      QChip
    },
    data () {
      return {
        drawerRight: false,
        keyword: keyword,
        tagVisible: false,
        compareSlot: {
          visible: true,
          open: true
        },
        layerBanner: {
          open: false,
          click: false
        },
        toastBanner: {
          bottom: false
        },
        excludeComponentNames: [
          'Contents',
          'ContentsDetail',
          'ContentsEditor',
          'AirlineDetail',
          'HotelDetail',
          'HotelBridge',
          'ChartView',
          'SearchHotel',
          'SearchAll'
        ]
      }
    },
    watch: {
      'getRoutePath' () {
        this.$nextTick(function() {

          setTimeout(function() {
            //TERA LOG - App 키설정
            window['_LA']=function(){
              (window['_LA'].q=window['_LA'].q||[]).push(arguments)
            }
            // window._nSA('CP4B3643229850','ngc1.nsm-corp.com','sa-w.js?',location.protocol,document,window._nSA||{},location.hostname);   //테라 실계정 App
            // window._nSA('TR8703005846','ngc1.nsm-corp.com','sa-w.js?',location.protocol,document,window._nSA||{},location.hostname);   //테라 테스트계정 App
          }, 1000)

          //GOOGLE LOG - app 키설정
          const page_path = window.location.pathname + window.location.search
          window.gtag('config', 'UA-88021356-1', {'page_path': page_path})
        })
      }
    },
    created () {
      // Android call
      if (window.JS_INTERFACE) {
        window.JS_INTERFACE.fullscreen(false)
      }
      // chart call
      this.getChartData
    },
    computed: {
      ...CommonComputed,
      ...mapGetters([
        'isToastBannerSmallSize',
        'getChartData'
      ]),
      getRoutePath () {
        this.isLoadedDisplayData
        this.keyword = keyword //this.getQueryKeyword
        return this.$route.path + this.$route.params + this.$route.query
      },
      getQueryKeyword () {
        return this.$route.query.keyword ? this.$route.query.keyword : keyword ? keyword : ''
      },
      getScreenWidth () {
        return screen.width
      },
      isLoadedDisplayData () {
        const rtn = this.$store.getters.getDisplayDataCnt
        if (rtn > 10) {
          this.openLayerBanner()
          this.openToastBanner()
        }
        return rtn > 10
      },
      isOpenShareDialog: {
        get () {
          return this.$store.getters.isOpenShareDialog
        },
        set (flag) {
          this.$store.dispatch('setOpenShareDialog', flag)
        }
      },
			isOpenTermsDialog: {
        get () {
          return this.$store.getters.isOpenTermsDialog
        },
        set (flag) {
          this.$store.dispatch('setOpenTermsDialog', flag)
        }
      },
			isOpenPolicyDialog: {
        get () {
          return this.$store.getters.isOpenPolicyDialog
        },
        set (flag) {
          this.$store.dispatch('setOpenPolicyDialog', flag)
        }
      }
    },
    methods: {
      ...CommonMethods,
      toggleDrawer () {
        this.drawerRight = !this.drawerRight
      },
      setSearchVisible () {
        if (this.keyword.trim() !== '') {
          this.$router.push({path: '/search/all', query: {keyword: this.keyword}})
          this.toggleDrawer()
        }
      },
      setKeywordHide () {
        this.keyword = ''
      },
      gotoRoute (path) {
        if (this.$route.path === path) {
          this.drawerRight = false
        } else {
          this.$router.push({path: path})
        }
      },
      openLayerBanner () {
        if (this.getExistDisplayDataBySectionIdx(2)) {
          if (!this.$cookies.isKey("closedLayerBanner") && !this.layerBanner.click) {
            if (this.getRouteName !== 'Login' && this.getRouteName !== 'Join') {
              setTimeout(function () {
                this.layerBanner.open = true
              }.bind(this), 4000)
            }
          }
        }
      },
      closeLayerBanner () {
        this.layerBanner.open = false
        this.layerBanner.click = true
        this.$cookies.set("closedLayerBanner", true, "1d")
      },
      clickLayerBanner () {
        this.closeLayerBanner ()
      },
      openToastBanner () {
        if (this.getExistDisplayDataBySectionIdx(3)) {
          if (!this.$cookies.isKey("closedToastBanner")) {
            if (this.getRouteName !== 'Login' && this.getRouteName !== 'Join' ) {
              setTimeout(function () {
                this.toastBanner.bottom = true
                this.$store.dispatch('setToastBannerSmallSize', false)
              }.bind(this), 1000)
            }
          }
        }
      },
      closeToastBanner () {
        this.$cookies.set("closedToastBanner", true, "1d")
        this.$store.dispatch('setToastBannerSmallSize', true)
        this.toastBanner.bottom = false
      },
      clickToggleBanner () {
        this.closeToastBanner()
      },
      getExistDisplayDataBySectionIdx (idx) {
        const data = this.$store.getters.getDisplayData
        return data.find(row => row.section.idx === idx)
      },
      clickKeywordTag (tag) {
        this.tagVisible = false
        this.keyword = tag
        this.setSearchVisible()
      },
			toggleShareDialog (flag) {
        this.$store.dispatch('setOpenShareDialog', flag)
      },
			toggleTermsDialog (flag) {
        this.$store.dispatch('setOpenTermsDialog', flag)
      },
			togglePolicyDialog (flag) {
        this.$store.dispatch('setOpenPolicyDialog', flag)
      },
      isCurrentRouteName (name) {
        return this.getRouteName === name
      },
      isFooterVisible (name) {
        return this.isCurrentRouteName(name)
      },
    }
  }
</script>

<style scoped>
	.q-dialog__inner--maximized > div {
		width: 85%;
		position: absolute;
		right: 0;
	}
	.q-page-container {
		box-sizing: border-box;
		overflow-x: hidden;
	}
  .contentArea {
    width: 960px;
    /* overflow: hidden; */
    margin: 100px auto;
  }
  .drawerLayout .hd_search {
    position: relative;
    width: 100%;
		padding: 0 32.5px;
  }
  .drawerLayout .hd_search .sch_btn {
		display: inline-block;
    width: 50px;
    height: 50px;
    font-size: 0;
    text-indent: -9999px;
    background: url('/statics/img/hd_search_icon.png') no-repeat center center;
    transition: all 0.2s;
  }
  .drawerLayout .hd_search .sch_btn:hover {
    opacity: 0.9;
  }
  .drawerLayout .hd_search .sch_box {
    width: 100%;
    height: 50px;
    background: #f5f5f5;
  }
  .drawerLayout .hd_search .sch_box input {
    width: calc(100% - 50px);
    height: 50px;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 400;
		letter-spacing: -0.05em;
    color: rgba(0, 0, 0, 0.4);
    border: 0;
		display: inline-block;
  }
  .drawerLayout .hd_search .sch_box input::placeholder {
    font-size: 14px;
  }
  .drawerLayout .hd_search .sch_box input:disabled {
    background: #243745 !important;
  }
  .drawerLayout .hd_search .sch_box input::-webkit-input-placeholder {
    color: #6f7a82;
  }
  .drawerLayout .hd_search .sch_box input:-moz-placeholder {
    color: #6f7a82;
  }
  .drawerLayout .hd_search .sch_box input::-moz-placeholder {
    color: #6f7a82;
  }
  .drawerLayout .hd_search .sch_box input:-ms-input-placeholder {
    color: #6f7a82;
  }
  .drawerLayout .hd_search .sch_box .tag {
    position: absolute;
    left: 7px;
    top: 8px;
    background: #243745;
    font-weight: 500;
    cursor: pointer;
  }
  /deep/ .q-chip__icon--remove {
    margin-left: 0.5em !important;
  }
  .drawerLayout .hd_search.on .sch_btn {
    background-color: inherit;
    background-image: url('/statics/img/ico_sch_sch.png');
		background-size: 18px 18px;
  }
  .drawerLayout .hd_search.on .sch_btn:hover {
    background-color: #ff3f2e;
    opacity: 1;
  }
  .drawerLayout .hd_search.on .sch_box {
    display: block;
  }

  .drawerLayout .no-flex-center {
    align-items: initial !important;
  }
  /deep/ .q-expansion-item__content {
    background: #fff;
    width: 100vw !important;
    margin-left: -25px;
		box-shadow: 0 0 30px rgba(0,0,0,0.07) inset;
  }
  .drawerLayout .top_line {
    border-top: 1px solid #293d4d;
  }
  .q-list--separator>.q-item-type+.q-item-type {
    border-top: 0px solid #293d4d !important;
    height: 50px;
  }
  .drawerLayout .q-item {
    min-height: 50px;
    padding: 0 10px 0 15px;
  }
  .drawerLayout .q-item.letter {
    padding: 0 15px;
  }
  .drawerLayout .q-item.payapp {
    border-top: 1px solid #293d4d !important;
    border-bottom: 1px solid #293d4d !important;
    padding: 20px 10px 20px 15px !important;
    height: unset !important;
  }
  .menu_2depth {
    padding: 0px 15px 0px 10px !important;
    min-height: unset !important;
  }
  .menu_2depth.first {
    padding-top: 20px !important;
  }
  .menu_2depth.last {
    padding-bottom: 20px !important;
  }
  .q-list .q-btn {
    padding: 6px 0 6px 0;
  }
	.q-list .q-expansion-item .q-btn {
    padding: 0 0 0 15px;
  }
	.btn_hbg_close {
		display: inline-block;
		width: 60px;
		height: 60px;
		background: url('/statics/img/btn_x_40.png') center no-repeat;
		background-size: 20px auto;
	}
  .item-new {
    font-size: 13px !important;
  }
  .item-new span {
    color: #ff6e61;
  }
  .item-new:before {
    content: "★";
    position: absolute;
    font-size: 12px;
    font-weight: 700;
    color: #ff6e61;
    top: -7px;
    left: 296px;
    transform: translatey(0px);
    animation: float 1s ease-in-out infinite;
  }
  @keyframes float {
    from { transform: rotate(20deg); }
    50%  { transform: rotate(-20deg); }
    to   { transform: rotate(20deg); }
  }
  /*.item-new:before {
    content: "HOT";
    position: absolute;
    font-size: 9px;
    font-weight: 700;
    color: #ff6e61;
    margin-top: 1px;
    margin-left: 282px;
  }*/
  .item-new:after {
    content: "생년월일로 알아보는 2021년 내 소비 아이템";
    font-size: 14px;
    font-weight: 400;
    color: #aaa;
    position: absolute;
    margin-left: 45px;
    margin-top: 0;
    text-align: left;
    letter-spacing: -0.01em;
  }
  .link-new {
    display: block;
    width: 100%;
    font-size: 0.9em;
    color: #aaa;
    padding: 0 17px 20px;
    border-bottom: 1px solid #293d4d;
    line-height: 1.0em;
  }
  /*.link-new:before {
    content: "└";
    padding-right: 5px;
    color: #777;
  }*/
  .link-new:after {
    content: "＞";
    font-size: 0.6em;
    font-weight: 300;
    line-height: 1.8em;
    padding-left: 10px;
  }
  .link-new span {
    color: #ff6e61;
    font-size: 0.9em;
    font-weight: 500;
    margin-right: 3px;
    border: 1px solid #ff6e61;
    border-radius: 2px;
    padding: 0 5px;
    line-height: 1.0em;
  }
  .layerBanner {
    margin: 0 auto;
    /*width: 320px;
    height: 320px;*/
    position: fixed;
    top: 20vh;
    z-index: 7778;
    padding: 10px;
    max-width: 340px;
  }
  .layerBackdrop {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 7777;
    pointer-events: all;
    background: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .toastBanner {
    min-width: 300px;
    min-height: 100px;
    position: fixed;
    bottom: 0;
    z-index: 6667;
  }
  /deep/ .toastBanner img {
    width: 320px !important;
    height: auto !important;
  }
  .toastBackdrop {
    width:100vw;
    height: auto;
    position: fixed;
    z-index: 6666;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .compare_layer .big {
    position: fixed;
    left: 50%;
    bottom: 0;
    z-index: 6668;
    transform: translate(-50%, 0);
    width: 100vw;
    height: 80px;
    text-align: center;
    background: rgba(27, 40, 56, 0.95);
  }
  .compare_layer .big .compare_lst {
    display: inline-block;
    padding-right: 0px;
  }
  .compare_layer .big .compare_lst:after {
    display: block;
    clear: both;
    content: "";
  }
  .compare_layer .big .compare_lst li {
    float: left;
    display: table;
    width: 60px;
    height: 80px;
    margin-right: 40px;
    background: url('/statics/img/compare_lst_bg.png') no-repeat center center;
  }
  .compare_layer .big .compare_lst li .card {
    display: table-cell;
    vertical-align: middle;
  }
  .compare_layer .big .compare_lst li .card .img {
    display: inline-block;
    position: relative;
  }
  .compare_layer .big .compare_lst li .card .del_btn {
    position: absolute;
    left: calc(100% - 8px);
    top: -9px;
    height: 18px;
    text-align: left;
    background: #ff6e61;
    border-radius: 9px;
    cursor: pointer;
    transition: all .15s;
  }
  .compare_layer .big .compare_lst li .card .del_btn:hover {
    background-color: #ff3f2e;
  }
  .compare_layer .big .compare_lst li .card .del_btn i {
    display: inline-block;
    width: 18px;
    height: 18px;
    font-size: 0;
    text-indent: -9999px;
    background: url('/statics/img/del_x_01.png');
  }
  .compare_layer .big .compare_lst li .card .del_btn span {
    display: inline-block;
    height: 18px;
    line-height: 16px;
    padding: 0 0 0 0px;
    font-size: 12px;
    color: #fff;
    font-weight: 500;
  }
  .compare_layer .big .compare_lst li.recmd {
    width: 150px;
    margin-left: -50px;
  }
  .compare_layer .big .compare_lst li.recmd .card {
    width: 150px;
    height: 80px;
    margin-left: 0px;
  }
  .compare_layer .big .compare_lst li.recmd .card .del_btn {
    width: 50px;
  }
  .compare_layer .big .compare_btn {
    display: inline-block;
    width: 100px;
    height: 40px;
    line-height: 38px;
    margin-top: 20px;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    background: #ff6e61;
    border-radius: 3px;
    opacity: .7;
  }
  .compare_layer .big .compare_btn.on {
    opacity: 1;
    cursor: pointer;
  }
  .compare_layer .big .compare_btn.on:hover {
    background-color: #ff3f2e;
  }
  .menu_ftr {
    display: inline-block;
		width: 100%;
		padding: 0 16px;
		margin-top: 20px;
  }
	.menu_ftr .curation {
		width: 100%;
		height: 100px;
		text-align: center;
		line-height: 100px;
	}
  .menu_ftr.sns a img {
    height: 26px;
    margin-right: 21px;
    filter: invert(100%);
    opacity: 0.4;
  }
  .menu_ftr.sns a:last-child img {
    margin-right: 0;
  }
  .text-h5 {
    font-size: 16px !important;
    font-weight: 700 !important;
    letter-spacing: -0.03em;
  }
  .text-h6 {
    font-size: 15px !important;
    color: #222;
		font-weight: 400;
    letter-spacing: -0.03em;
  }
  .bold {
    color: #fff;
  }
  .bold:after {
    content: "N";
    font-size: 0.7em;
    color: #fff;
    font-weight: 900;
    margin-left: 86px;
    position: absolute;
    background: #ff6e61;
    border-radius: 100%;
    width: 16px;
    height: 16px;
    line-height: 16px;
  }
  .q-pt-lg {
    padding-top: 0;
  }
  .justify-center {
    justify-content: unset !important;
  }

	/* 공유 팝업 */
	.share_pop {
    position: relative;
    width: unset !important;
		height: unset !important;
		max-width: 360px;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
		padding: 45px 25px 35px;
  }
  .share_pop .close_btn {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    font-size: 0;
    text-indent: -9999px;
    background: url(/statics/img/close_01.png);
		background-size: 100%;
		z-index: 1;
  }
	.share_pop ul li {
		position: relative;
		font-size: 12px;
		text-align: center;
		display: inline-block;
		width: 25%;
		margin-bottom: 20px;
		cursor: pointer;
	}
	.share_pop ul li:nth-child(5), .share_pop ul li:nth-child(6), .share_pop ul li:nth-child(7), .share_pop ul li:nth-child(8) {
		margin-bottom: 0;
	}
	.share_pop ul li:hover i {
		box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
	}
	.share_pop ul li i {
		display: inline-block;
		width: 50px;
		height: 50px;
		background-size: 100%;
		background-position: center;
		border-radius: 5%;
		margin-bottom: 5px;
	}
	.share_pop ul li.kakaotalk i {
		background-image:  url('/statics/img/icon_share_kakaotalk.png');
	}
	.share_pop ul li.kakaostory i {
		background-image:  url('/statics/img/icon_share_kakaostory.png');
	}
	.share_pop ul li.facebook i {
		background-image:  url('/statics/img/icon_share_facebook.png');
	}
	.share_pop ul li.twitter i {
		background-image:  url('/statics/img/icon_share_twitter.png');
	}
	.share_pop ul li.naver i {
		background-image:  url('/statics/img/icon_share_naver.png');
	}
	.share_pop ul li.band i {
		background-image:  url('/statics/img/icon_share_band.png');
	}
	.share_pop ul li.line i {
		background-image:  url('/statics/img/icon_share_line.png');
	}
	.share_pop ul li.link i {
		background-image:  url('/statics/img/icon_share_link.png');
	}
	#shareUrl {
		position: absolute;
		top: -9999px
	}
	.info_pop {
    width: 100vw !important;
		height: 100vh !important;
		max-width: unset;
    border-radius: 0;
		padding: 50px 0 0;
	}
	.info_pop h3 {
		position: absolute;
		top: 6px;
		left: 0;
		width: 100vw;
    font-size: 18px;
		font-weight: 500;
		text-align: center;
	}
	.info_pop .close_btn {
		position: absolute;
		right: 12px;
		top: 12px;
		cursor: pointer;
		width: 20px;
		height: 20px;
		font-size: 0;
		text-indent: -9999px;
		background: url(/statics/img/close_01.png);
		background-size: 100%;
		z-index: 1;
	}
	.info_pop .contain {
		width: 100%;
		height: 100%;
		padding: 0 20px;
		overflow-y: scroll;
		font-size: 13px;
		font-weight: 300;
		color: #222;
		line-height: 1.7em;
		word-break: break-all;
	}
	.info_pop .contain h6 {
		font-size: 15px;
		font-weight: 500;
		letter-spacing: -0.03em;
		margin: 15px 0 10px;
		line-height: 1.6em;
	}
	.info_pop .contain p {
		margin: 4px 0 10px;
	}
	.info_pop .contain ol li {
		list-style: decimal;
    margin-left: 18px;
    margin-bottom: 6px;
	}
	.info_pop .contain ol li:last-child {
    margin-bottom: 18px;
	}
	.info_pop .contain ul li {
		list-style-type: "-";
    margin-left: 18px;
    margin-bottom: 6px;
	}
	.info_pop .contain ul li:last-child {
    margin-bottom: 18px;
	}
	.info_pop table {
		width: 100%;
		margin-bottom: 15px;
		font-size: 13px;
		line-height: 1.4em;
		padding: 10px;
	}
	.info_pop table td {
		border: 1px solid #ddd;
		padding: 10px;
		vertical-align: middle;
	}
	.info_pop table th {
		text-align: center;
		border: 1px solid #ddd;
		padding: 10px;
		font-size: 13px;
		font-weight: 400;
		vertical-align: middle;
	}
  .layerBackdrop > div {
    position: relative;
    padding: 15px;
  }
  /deep/ .layerBackdrop a > img {
    max-width: 320px !important;
  }
</style>
