import * as types from './mutation_types'
import {UPDATE_HOTEL_HASH_TAG_DATA, UPDATE_SEARCH_HOTEL_RESULT} from "./mutation_types";
// import getters from "./getters"

export default {
  [types.PUSH_LIST_OF_CALLED_API] (state, payload) {
    let index = state.listOfCalledAPI.indexOf(payload)
    if (index < 0) state.listOfCalledAPI.push(payload)
  },
  [types.UPDATE_DISPLAY_CATE_DATA] (state, payload) {
    state.displayCateData = payload
  },
  [types.UPDATE_DISPLAY_DATA] (state, payload) {
    state.displayData = payload
  },
  [types.UPDATE_CHART_DATA] (state, payload) {
    state.chartData[payload.key] = payload.data
  },
  [types.UPDATE_HOTEL_REGION_DATA] (state, payload) {
    state.hotelRegionData = payload
  },
  [types.UPDATE_HOTEL_HASH_TAG_DATA] (state, payload) {
    state.hotelHashTagData = payload
  },
  [types.CHANGE_SIZE_TOAST_BANNER] (state, payload) {
    state.smallToastBanner = payload
  },
  [types.UPDATE_AIRLINE_CORP_DATA] (state, payload) {
    state.airlineCorpData = payload
  },
  [types.UPDATE_CURRENT_CHART_TITLE] (state, payload) {
    state.currentChartTitle = payload
  },
  [types.UPDATE_OPEN_SEARCH_HOTEL_DIALOG] (state, payload) {
    state.openSearchHotelDialog = payload
  },
  [types.CHANGE_OPEN_ALL_CHART_DIALOG] (state, payload) {
    state.openAllChartDialog = payload
  },
  [types.CHANGE_OPEN_SHARE_DIALOG] (state, payload) {
    state.openShareDialog = payload
  },
  [types.CHANGE_OPEN_TERMS_DIALOG] (state, payload) {
    state.openTermsDialog = payload
  },
  [types.CHANGE_OPEN_POLICY_DIALOG] (state, payload) {
    state.openPolicyDialog = payload
  },
  [types.UPDATE_CURRENT_CALENDAR_LABEL] (state, payload) {
    state.currentCalendarLabel = payload
  },
  [types.CHANGE_OPEN_CALENDAR_DIALOG] (state, payload) {
    state.openCalendarDialog = payload
  },
  [types.UPDATE_OPEN_DROPDOWN] (state, payload) {
    state.openTeamDropdown = payload
  },
  [types.UPDATE_CURRENT_CONTENTS_DATA] (state, payload) {
    state.currentContentsData = payload
  },
  [types.UPDATE_CURRENT_HOTEL] (state, payload) {
    state.currentHotel = payload
  },
  [types.UPDATE_SEARCH_HOETL_QUERY] (state, payload) {
    state.searchHotelQuery = payload
  },
  [types.UPDATE_SEARCH_HOTEL_RESULT] (state, payload) {
    state.searchHotelResult = payload
  },
  [types.UPDATE_CALENDAR_FOR_YEAR] (state, payload) {
    state.calendarForYear = payload
  },
  [types.UPDATE_LOGIN_USER_DATA] (state, payload) {
    state.loginUserData = payload
  },
  [types.UPDATE_APP_PLATFORM] (state, payload) {
    state.appPlatform = payload
  }
}
