import Vue from 'vue'
import Vuex from 'vuex'
import Module from './module'


Vue.use(Vuex)

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */

export default function (/* { ssrContext } */) {
  const Store = new Vuex.Store({
    modules: {
      Module
    },

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: false //process.env.DEV
  })

  return Store
}
