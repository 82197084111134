<script>
  import SearchHotelModal from 'src/pc/components/SearchHotelModal'
  export default {
    extends: SearchHotelModal,
    computed: {
      getAccess () {
        return 'app'
      }
    }
  }
</script>

<style scoped>

  /* modal */
  .modal {
    width: 100%;
    height: unset !important;
    background: #fff;
    position: relative;
  }
  .modal .header {
    height: 60px;
    font-size: 1.15em;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    position: sticky;
    top: 0;
    z-index: 2;
    background: #fff;
  }
  .modal .header .btn_back {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
  }
  .modal .header .btn_back:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    width: 12px;
    height: 12px;
    border: solid #888;
    border-width: 0 0 1.5px 1.5px;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .modal .header .btn_back.btn_close:after {
    display: none;
  }
  .modal .header .btn_back i {
    position: absolute;
    font-size: 20px;
    color: #888;
  }
  .modal .sect {
    padding: 25px 20px;
    border-bottom: 0;
  }
  .modal .sect .title {
    font-size: 1.15em;
    font-weight: 500;
    margin-bottom: 20px;
    letter-spacing: -0.03em;
  }
  .modal .sect .sch_lst {
    padding: 10px;
  }
  .modal .sect .main_txt {
    font-size: 1.1em;
    letter-spacing: -0.02em;
  }
  .modal .sect .sub_txt {
    font-size: 0.9em;
    color: #888;
  }
  .modal .sect .btn_wrap {
    text-align: center;
    margin-bottom: 10px;
  }
  .modal .sect .btn_wrap .btn_select {
    display: inline-block;
    width: 48%;
    border: 1px solid #ddd;
    font-size: 14px;
    font-weight: 500;
    color: #222;
    text-align: center;
    padding: 14px;
    margin: 1%;
  }
  .modal .sect .btn_wrap .btn_select.on {
    border: 1px solid #01e1c9;
    color: #222;
    background: #01e1c9;
  }
  .modal .sect input[type=text] {
    width: 100%;
    height: 50px;
    background: #fff;
    border: 0;
    margin-bottom: 10px;
    line-height: 50px;
    padding-left: 45px;
    font-size: 14px;
    color: #222;
    background-size: 18px 18px;
    background-position: 16px center;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
  }
  .modal .sect input[type=text].ipt_sch {
    background-image: url('/statics/img/ico_sch_sch.png');
  }
  .modal .sect p.btn {
    display: inline-block;
    line-height: 50px;
    font-size: 13px;
    font-weight: 700;
    width: 100%;
    height: 50px;
    text-align: center;
    background-color: #fff;
    background-size: 18px 18px;
    background-position: 15px center;
		background-repeat: no-repeat;
    border: 1px solid #ccc;
    color: #222;
    cursor: pointer;
    margin-bottom: 10px;
    filter: grayscale(1.0);
  }
  .modal .sect p.btn.sel {
    background-color: #eefaf9;
    border: 1px solid #00897a;
    color: #00897a;
    filter: grayscale(0.0);
  }
  .modal .sect .ipt_area {
    background-image: url('/statics/img/ico_sch_area.png');
  }
  .modal .sect .ipt_theme {
    background-image: url('/statics/img/ico_sch_theme.png');
    margin-right: 0 !important;
  }
  .modal .sect .btn_reset {
    display: block;
    font-size: 13px;
    font-weight: 500;
    color: #777;
    margin-top: 20px;
    text-align: right;
	}
	.modal .sect .btn_reset:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 19px;
    background-image: url('/statics/img/icon_reset.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 6px;
	}
  .modal .sect.area .item {
    display: inline-block;
    width: 33.3333%;
    height: 46px;
    margin-top: -1px;
    margin-left: -1px;
    position: relative;
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    color: #666;
    text-align: center;
    line-height: 46px;
    letter-spacing: -0.5px;
    transition: all 0.15s;
    border: solid #ddd;
    border-width: 1px 1px 1px 0;
  }
  .modal .sect.area .item:nth-child(3n+1) {
    border-width: 1px;
  }
  .modal .sect.area .item.on {
    font-weight: 700;
    color: #00897a;
    background: #ccf9f4;
    border: 1px solid #00897a;
    z-index: 1;
  }
  .modal .sect.theme .item {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    color: #666;
    letter-spacing: -0.03em;
    transition: all 0.15s;
    border: solid #e3e3e3;
    border-width: 1px;
    border-radius: 2.0em;
    padding: 10px 12px;
    margin: 0 5px 5px 0;
    line-height: 1.0em;
  }
  .modal .sect.theme .item.on {
    font-weight: 500;
    color: #00897a;
    background: #ccf9f4;
    border: 1px solid #00897a;
    z-index: 1;
  }
  .modal .day {
    height: 50px;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid #ddd;
    background: #fff;
    padding: 0 20px;
    line-height: 50px;
  }
  .modal .day li {
    display: inline-block;
    width: 14.2857%;
    text-align: center;
    font-size: 13px;
    color: #222;
  }
  .modal .sect.calendar {
    height: calc(100vh - 80px);
    position: relative;
    overflow-y: scroll;
    border: 0;
  }
  .modal .calendar .month {
    margin-bottom: 40px;
  }
  .modal .calendar .month:last-child {
    margin-bottom: 0;
  }
  .modal .calendar .month .title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .modal .calendar .month li {
    display: inline-block;
    width: 14.2857%;
    height: calc((100vw - 80px) / 7);
    line-height: calc((100vw - 80px) / 7);
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color: #222;
    margin: 5px 0;
  }
  .modal .calendar .month li.disable {
    color: #ccc;
  }
  .modal .calendar .month li.check {
    background: #01e1c9;
    font-weight: 700;
  }
  .modal .calendar_ftr {
    position: sticky;
    bottom: 0;
    background: #fff;
    border-top: 1px solid #ddd;
    z-index: 1003;
    text-align: center;
    padding: 0 !important;
  }
  .modal .calendar_ftr .date_wrap ul {
    display: inline-block;
    width: 50%;
    margin: 15px 0;
    padding: 5px 30px;
    border-right: 1px solid #ddd;
    letter-spacing: -0.02em;
  }
  .modal .calendar_ftr .date_wrap ul:last-child {
    border-right: 0;
  }
  .modal .calendar_ftr .date_wrap ul li.chk {
    font-size: 13px;
    font-weight: 500;
    color: #888;
    margin-bottom: 2px;
  }
  .modal .calendar_ftr .date_wrap ul li.date {
    font-size: 16px;
    font-weight: 500;
  }
  .modal .btm {
    padding: 0;
  }
  .modal .btm.btm_fixed {
    position: sticky;
    bottom: 0;
    z-index: 2;
  }
  .modal .btm .apply {
    font-size: 1.1em;
    font-weight: 700;
    color: #fff;
    background: #00897a;
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 16px;
  }
  .modal .sect .opt_lst {
    display: block;
    width: 100%;
    padding: 18px 0;
    position: relative;
    border-bottom: 1px solid #ccc;
  }
  .modal .sect .opt_lst:last-child {
    border-bottom: 0;
  }
  .modal .sect .opt_lst span {
    display: inline-block;
    width: 50%;
    font-size: 15px;
    letter-spacing: -0.02em;
    line-height: 28px;
  }
  .modal .sect .opt_lst span:first-child {
    text-align: left;
  }
  .modal .sect .opt_lst span:last-child {
    text-align: right;
  }
  .modal .sect .opt_lst span:last-child i {
    font-size: 18px;
    font-weight: 500;
    display: inline-block;
    width: 20px;
    text-align: center;
    margin: 0 12px;
  }
  .modal .sect .opt_lst span:last-child button {
    border: 1px solid #00897a;
    border-radius: 100%;
    width: 28px;
    height: 28px;
    font-size: 24px;
    color: #00897a;
  }
  .modal .sect .opt_lst span input {
    border: 1px solid #fff;
    text-align: right;
  }
</style>
