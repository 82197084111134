import Vue from 'vue'
import axios from 'axios'
import { date } from 'quasar'
import Integer from "vuelidate/lib/validators/integer";

export default {
  cloneData: () => original => {
    const data = JSON.parse(JSON.stringify(original))
    const rtn = data instanceof Array ? Object.assign([], data)
      : data instanceof Object ? Object.assign({}, data)
      : data
    return rtn
  },
  getSearchHotelQueryOrigin: (state) => {
    return state.searchHotelQueryOrigin
  },
  getSearchHotelQuery: (state, context) => {
    const route = vm.$route
    const query = route.query

    if (Object.keys(state.searchHotelQuery).length < 1) {
      state.searchHotelQuery = context.cloneData(state.searchHotelQueryOrigin)
    }
    if (context.isActivatedRoute) {
      if (Object.keys(query).length > 0) {
        if (query.keyword) state.searchHotelQuery.keyword = query.keyword
        if (query.cate) state.searchHotelQuery.cate = query.cate
        if (query.region) state.searchHotelQuery.region = JSON.parse(`[${query.region}]`)
        if (query.hashTags) state.searchHotelQuery.hashTags = Number(query.hashTags.toString().replace(/\,/g, '')) ? JSON.parse(`[${query.hashTags}]`) : query.hashTags
        if (query.isReview) state.searchHotelQuery.isReview = query.isReview
        if (context.hasKorean(query.hashTags)) {
          state.searchHotelQuery.hashTags = [context.getHotelHashTagIdx(query.hashTags)]
        }
      } else {
        if (Object.keys(state.searchHotelQuery).length > 0) {
          const query2 = {}
          if (state.searchHotelQuery.keyword) query2.keyword = state.searchHotelQuery.keyword
          if (state.searchHotelQuery.cate) query2.cate = state.searchHotelQuery.cate
          if (state.searchHotelQuery.region.length > 0) query2.region = JSON.stringify(state.searchHotelQuery.region).replace('[','').replace(']','')
          if (state.searchHotelQuery.hashTags.length > 0) query2.hashTags = JSON.stringify(state.searchHotelQuery.hashTags).replace('[','').replace(']','')
          if (state.searchHotelQuery.isReview) query2.isReview = state.searchHotelQuery.isReview
          context.setRouteQuery(query2)
        }
      }
    } else {
      context.removeRouteQueryWithSearchHotelQuery
    }
    return state.searchHotelQuery
  },
  isActivatedRoute: (state) => {
    const route = vm.$route
    return state.activatedRoutes.includes(route.name)
  },
  hasKorean: () => text => {
    const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/
    return korean.test(text)
  },
  removeRouteQueryWithSearchHotelQuery: (state, context) => {
    // if (context.isActivatedRoute) {
      const query = vm.$route.query
      delete query.keyword
      delete query.cate
      delete query.region
      delete query.hashTags
    // }
  },
  setRouteQuery: (state) => query => {
    vm.$router.replace({ query: query })
  },
  getSearchHotelQueryKeyword: (state) => {
    return state.searchHotelQuery.keyword
  },
  getSearchHotelResult: (state, context) => {
    if (Object.keys(state.searchHotelResult).length < 1) state.searchHotelResult = context.cloneData(state.searchHotelResultOrigin)
    return state.searchHotelResult
  },
  getSearchHotelResultCnt: (state, context) => {
    return context.getSearchHotelResult.data.length
  },
  getAutocompleteSearch: state => payload => {
    const params = {params: {keyword: payload.keyword, cate: payload.cate}}
    return axios.get('/hotels/autocomplete', params).then(rs => {
      return rs
    })
  },
  getLoginUserData: state => {
    return state.loginUserData ? state.loginUserData : {}
  },
  getExistLoginUserData: (state, context) => {
    return Object.keys(context.getLoginUserData).length > 0
  },
  getLoginUserWishHotelsCnt: (state, context) => {
    return context.getLoginUserData.wish_hotels ? context.getLoginUserData.wish_hotels.length : 0
  },
  getLoginUserWishHotels: (state, context) => {
    return context.getExistLoginUserData ? state.loginUserData.wish_hotels : []
  },
  getLoginUserWishHotelsData: (state, context) => {
    return context.getExistLoginUserData ? state.loginUserData.wish_hotels_data : []
  },
  getDisplayCateData: state => {
    return state.displayCateData
  },
  getDisplayCateDataCnt: state => {
    return state.displayCateData.length
  },
  getDisplayData: state => {
    return state.displayData
  },
  getDisplayDataCnt: state => {
    return state.displayData.length
  },
  isExistListOfCalledAPI: state => {
    return api => state.listOfCalledAPI.indexOf(api) > -1
  },
  getChartData: (state, context) => {
    if (!state.chartData[context.getLatestChartKey('monthly').key]) {
      vm.$store.dispatch('setLatestChartData')
    }
    return state.chartData
  },
  getChartTopCnt: state => {
    return state.chartData.length
  },
  getAirlineCorpData: state => {
    return state.airlineCorpData
  },
  getAirlineCorpDataCnt: state => {
    return state.airlineCorpData.length
  },
  getHotelRegionData: state => {
    return state.hotelRegionData
  },
  getHotelRegionDataCnt: state => {
    return state.hotelRegionData.length
  },
  getHotelRegionName: state => payload => {
    const obj = state.hotelRegionData.find(row => row.idx === Number(payload))
    return obj ? obj.name : ''
  },
  getHotelHashTagData: state => {
    return state.hotelHashTagData
  },
  getHotelHashTagDataCnt: state => {
    return state.hotelHashTagData.length
  },
  getHotelHashTagName: state => payload => {
    const obj = state.hotelHashTagData.find(row => row.idx === Number(payload))
    return obj ? obj.name : ''
  },
  getHotelHashTagIdx: state => payload => {
    const obj = state.hotelHashTagData.find(row => row.name === payload)
    return obj ? obj.idx : ''
  },
  searchHotelDialog: state => {
    return state.openSearchHotelDialog
  },
  isToastBannerSmallSize: state => {
    return state.smallToastBanner
  },
  getCurrentChartTitle: state => {
    return state.currentChartTitle
  },
  isOpenAllChartDialog: (state) => {
    return state.openAllChartDialog
  },
  isOpenShareDialog: (state) => {
		return state.openShareDialog
	},
  isOpenTermsDialog: (state) => {
		return state.openTermsDialog
	},
  isOpenPolicyDialog: (state) => {
		return state.openPolicyDialog
	},
  getCurrentCalendarLabel: state => {
    return state.currentCalendarLabel
  },
  getCurrentContentsData: state => {
    return state.currentContentsData
  },
  isOpenCalendarDialog: (state) => {
    return state.openCalendarDialog
  },
  getCurrentHotel: (state) => {
    return state.currentHotel
  },
  getFroalaAlertText: (state) => {
    return state.froalaAlertText
  },
  getLatestChartKey: (state, context) => term => {
    const today = new Date()
    let day

    if (term === 'weekly') {
      const mondayIndex = 1 - date.getDayOfWeek(today)
      day = date.formatDate(date.addToDate(today, {days: mondayIndex}), 'YYYY-MM-DD')
    } else if (term === 'monthly') {
      day = date.formatDate(date.addToDate(today, {days: 0}), 'YYYY-MM-01')
    } else {
      return null
    }

    const chart = 'hot100'
    const key = chart + '_' + day + term

    return { day: day, key: key }
  },
  getRankingByHotelIdx: (state, context) => idx => {
    const charts = context.getChartData
    const key = context.getLatestChartKey('monthly').key
    const chart = charts[key]
    if (chart) {
      const obj = chart.find(row => row.hotel_idx === idx)
      return !obj ? null : obj.ranking && obj.ranking < 6 ? obj.ranking : null
    } else {
      return null
    }
  },
  getAppPlatform: (state) => {
    return state.appPlatform

  }
}
