<script>
	import DefaultLayout from 'src/pc/layouts/DefaultLayout'
  import Header from '../components/Header'
  import Footer from '../components/Footer'
  import SubHeader from '../components/SubHeader'
  import SubFooter from '../components/SubFooter'
  import DisplayContainer from '../components/DisplayContainer'
  import SearchHotelModal from '../components/SearchHotelModal'
  export default {
    extends: DefaultLayout,
    components: {
      Header,
      Footer,
      SubHeader,
      SubFooter,
      DisplayContainer,
      SearchHotelModal
    }
  }
</script>

<style scoped>
  .q-dialog__inner--maximized > div {
    width: 85%;
    position: absolute;
    right: 0;
  }
  .q-page-container {
    box-sizing: border-box;
    overflow-x: hidden;
  }
  .contentArea {
    min-width: 100vw !important;
    max-width: 100vw !important;
    /*overflow: hidden !important;*/
    margin-top: 60px;
  }
  .drawerLayout .hd_search {
    position: relative;
    width: 100%;
    padding: 0 12.5px;
  }
  .drawerLayout .hd_search .sch_btn {
    display: inline-block;
    width: 50px;
    height: 50px;
    font-size: 0;
    text-indent: -9999px;
    background: url('/statics/img/hd_search_icon.png') no-repeat center center;
    transition: all 0.2s;
  }
  .drawerLayout .hd_search .sch_btn:hover {
    opacity: 0.9;
  }
  .drawerLayout .hd_search .sch_box {
    width: 100%;
    height: 50px;
    background: #f5f5f5;
  }
  .drawerLayout .hd_search .sch_box input {
    width: calc(100% - 50px);
    height: 50px;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.05em;
    color: rgba(0, 0, 0, 0.4);
    border: 0;
    display: inline-block;
  }
  .drawerLayout .hd_search .sch_box input::placeholder {
    font-size: 14px;
  }
  .drawerLayout .hd_search .sch_box input:disabled {
    background: #243745 !important;
  }
  .drawerLayout .hd_search .sch_box input::-webkit-input-placeholder {
    color: #6f7a82;
  }
  .drawerLayout .hd_search .sch_box input:-moz-placeholder {
    color: #6f7a82;
  }
  .drawerLayout .hd_search .sch_box input::-moz-placeholder {
    color: #6f7a82;
  }
  .drawerLayout .hd_search .sch_box input:-ms-input-placeholder {
    color: #6f7a82;
  }
  .drawerLayout .hd_search .sch_box .tag {
    position: absolute;
    left: 7px;
    top: 8px;
    background: #243745;
    font-weight: 500;
    cursor: pointer;
  }
  /deep/ .q-chip__icon--remove {
    margin-left: 0.5em !important;
  }
  .drawerLayout .hd_search.on .sch_btn {
    background-color: inherit;
    background-image: url('/statics/img/ico_sch_sch.png');
    background-size: 18px 18px;
  }
  .drawerLayout .hd_search.on .sch_btn:hover {
    background-color: #ff3f2e;
    opacity: 1;
  }
  .drawerLayout .hd_search.on .sch_box {
    display: block;
  }

  .drawerLayout .no-flex-center {
    align-items: initial !important;
  }
  /deep/ .q-expansion-item__content {
    background: #fff;
    width: 100vw !important;
    margin-left: -25px;
    box-shadow: 0 0 30px rgba(0,0,0,0.07) inset;
  }
  .drawerLayout .top_line {
    border-top: 1px solid #293d4d;
  }
  .q-list--separator>.q-item-type+.q-item-type {
    border-top: 0px solid #293d4d !important;
    height: 50px;
  }
  .drawerLayout .q-item {
    min-height: 50px;
    padding: 0 10px 0 15px;
  }
  .drawerLayout .q-item.letter {
    padding: 0 15px;
  }
  .drawerLayout .q-item.payapp {
    border-top: 1px solid #293d4d !important;
    border-bottom: 1px solid #293d4d !important;
    padding: 20px 10px 20px 15px !important;
    height: unset !important;
  }
  .menu_2depth {
    padding: 0px 15px 0px 10px !important;
    min-height: unset !important;
  }
  .menu_2depth.first {
    padding-top: 20px !important;
  }
  .menu_2depth.last {
    padding-bottom: 20px !important;
  }
  .q-list .q-btn {
    padding: 6px 0 6px 0;
  }
  .q-list .q-expansion-item .q-btn {
    padding: 0 0 0 15px;
  }
  .btn_hbg_close {
    display: inline-block;
    width: 60px;
    height: 60px;
    background: url('/statics/img/btn_x_40.png') center no-repeat;
    background-size: 20px auto;
  }
  .item-new {
    font-size: 13px !important;
  }
  .item-new span {
    color: #ff6e61;
  }
  .item-new:before {
    content: "★";
    position: absolute;
    font-size: 12px;
    font-weight: 700;
    color: #ff6e61;
    top: -7px;
    left: 296px;
    transform: translatey(0px);
    animation: float 1s ease-in-out infinite;
  }
  @keyframes float {
    from { transform: rotate(20deg); }
    50%  { transform: rotate(-20deg); }
    to   { transform: rotate(20deg); }
  }
  /*.item-new:before {
    content: "HOT";
    position: absolute;
    font-size: 9px;
    font-weight: 700;
    color: #ff6e61;
    margin-top: 1px;
    margin-left: 282px;
  }*/
  .item-new:after {
    content: "생년월일로 알아보는 2021년 내 소비 아이템";
    font-size: 14px;
    font-weight: 400;
    color: #aaa;
    position: absolute;
    margin-left: 45px;
    margin-top: 0;
    text-align: left;
    letter-spacing: -0.01em;
  }
  .link-new {
    display: block;
    width: 100%;
    font-size: 0.9em;
    color: #aaa;
    padding: 0 17px 20px;
    border-bottom: 1px solid #293d4d;
    line-height: 1.0em;
  }
  /*.link-new:before {
    content: "└";
    padding-right: 5px;
    color: #777;
  }*/
  .link-new:after {
    content: "＞";
    font-size: 0.6em;
    font-weight: 300;
    line-height: 1.8em;
    padding-left: 10px;
  }
  .link-new span {
    color: #ff6e61;
    font-size: 0.9em;
    font-weight: 500;
    margin-right: 3px;
    border: 1px solid #ff6e61;
    border-radius: 2px;
    padding: 0 5px;
    line-height: 1.0em;
  }
  .layerBanner {
    margin: 0 auto;
    /*width: 320px;
    height: 320px;*/
    position: fixed;
    top: 20vh;
    z-index: 7778;
    padding: 10px;
    max-width: 340px;
  }
  .layerBackdrop {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 7777;
    pointer-events: all;
    background: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .toastBanner {
    min-width: 300px;
    min-height: 100px;
    position: fixed;
    bottom: 0;
    z-index: 6667;
  }
  /deep/ .toastBanner img {
    width: 320px !important;
    height: auto !important;
  }
  .toastBackdrop {
    width:100vw;
    height: auto;
    position: fixed;
    z-index: 6666;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .compare_layer .big {
    position: fixed;
    left: 50%;
    bottom: 0;
    z-index: 6668;
    transform: translate(-50%, 0);
    width: 100vw;
    height: 80px;
    text-align: center;
    background: rgba(27, 40, 56, 0.95);
  }
  .compare_layer .big .compare_lst {
    display: inline-block;
    padding-right: 0px;
  }
  .compare_layer .big .compare_lst:after {
    display: block;
    clear: both;
    content: "";
  }
  .compare_layer .big .compare_lst li {
    float: left;
    display: table;
    width: 60px;
    height: 80px;
    margin-right: 40px;
    background: url('/statics/img/compare_lst_bg.png') no-repeat center center;
  }
  .compare_layer .big .compare_lst li .card {
    display: table-cell;
    vertical-align: middle;
  }
  .compare_layer .big .compare_lst li .card .img {
    display: inline-block;
    position: relative;
  }
  .compare_layer .big .compare_lst li .card .del_btn {
    position: absolute;
    left: calc(100% - 8px);
    top: -9px;
    height: 18px;
    text-align: left;
    background: #ff6e61;
    border-radius: 9px;
    cursor: pointer;
    transition: all .15s;
  }
  .compare_layer .big .compare_lst li .card .del_btn:hover {
    background-color: #ff3f2e;
  }
  .compare_layer .big .compare_lst li .card .del_btn i {
    display: inline-block;
    width: 18px;
    height: 18px;
    font-size: 0;
    text-indent: -9999px;
    background: url('/statics/img/del_x_01.png');
  }
  .compare_layer .big .compare_lst li .card .del_btn span {
    display: inline-block;
    height: 18px;
    line-height: 16px;
    padding: 0 0 0 0px;
    font-size: 12px;
    color: #fff;
    font-weight: 500;
  }
  .compare_layer .big .compare_lst li.recmd {
    width: 150px;
    margin-left: -50px;
  }
  .compare_layer .big .compare_lst li.recmd .card {
    width: 150px;
    height: 80px;
    margin-left: 0px;
  }
  .compare_layer .big .compare_lst li.recmd .card .del_btn {
    width: 50px;
  }
  .compare_layer .big .compare_btn {
    display: inline-block;
    width: 100px;
    height: 40px;
    line-height: 38px;
    margin-top: 20px;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    background: #ff6e61;
    border-radius: 3px;
    opacity: .7;
  }
  .compare_layer .big .compare_btn.on {
    opacity: 1;
    cursor: pointer;
  }
  .compare_layer .big .compare_btn.on:hover {
    background-color: #ff3f2e;
  }
  .menu_ftr {
    display: inline-block;
    width: 100%;
    padding: 0 16px;
    margin-top: 20px;
  }
  .menu_ftr .curation {
    width: 100%;
    height: 100px;
    text-align: center;
    line-height: 100px;
  }
  .menu_ftr.sns a img {
    height: 26px;
    margin-right: 21px;
    filter: invert(100%);
    opacity: 0.4;
  }
  .menu_ftr.sns a:last-child img {
    margin-right: 0;
  }
  .text-h5 {
    font-size: 16px !important;
    font-weight: 700 !important;
    letter-spacing: -0.03em;
  }
  .text-h5.on {
    color: #00897a;
  }
  .text-h6 {
    font-size: 15px !important;
    color: #222;
    font-weight: 400;
    letter-spacing: -0.03em;
  }
  .bold {
    color: #fff;
  }
  .bold:after {
    content: "N";
    font-size: 0.7em;
    color: #fff;
    font-weight: 900;
    margin-left: 86px;
    position: absolute;
    background: #ff6e61;
    border-radius: 100%;
    width: 16px;
    height: 16px;
    line-height: 16px;
  }
  .q-pt-lg {
    padding-top: 0;
  }
  .justify-center {
    justify-content: unset !important;
  }

  /* 공유 팝업 */
  .share_pop {
    position: relative;
    width: unset !important;
    height: unset !important;
    max-width: 360px;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    padding: 45px 25px 35px;
  }
  .share_pop .close_btn {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    font-size: 0;
    text-indent: -9999px;
    background: url(/statics/img/close_01.png);
    background-size: 100%;
    z-index: 1;
  }
  .share_pop ul li {
    position: relative;
    font-size: 12px;
    text-align: center;
    display: inline-block;
    width: 25%;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .share_pop ul li:nth-child(5), .share_pop ul li:nth-child(6), .share_pop ul li:nth-child(7), .share_pop ul li:nth-child(8) {
    margin-bottom: 0;
  }
  .share_pop ul li:hover i {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
  }
  .share_pop ul li i {
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: 100%;
    background-position: center;
    border-radius: 5%;
    margin-bottom: 5px;
  }
  .share_pop ul li.kakaotalk i {
    background-image:  url('/statics/img/icon_share_kakaotalk.png');
  }
  .share_pop ul li.kakaostory i {
    background-image:  url('/statics/img/icon_share_kakaostory.png');
  }
  .share_pop ul li.facebook i {
    background-image:  url('/statics/img/icon_share_facebook.png');
  }
  .share_pop ul li.twitter i {
    background-image:  url('/statics/img/icon_share_twitter.png');
  }
  .share_pop ul li.naver i {
    background-image:  url('/statics/img/icon_share_naver.png');
  }
  .share_pop ul li.band i {
    background-image:  url('/statics/img/icon_share_band.png');
  }
  .share_pop ul li.line i {
    background-image:  url('/statics/img/icon_share_line.png');
  }
  .share_pop ul li.link i {
    background-image:  url('/statics/img/icon_share_link.png');
  }
  #shareUrl {
    position: absolute;
    top: -9999px
  }
  .info_pop {
    width: 100vw !important;
    height: 100vh !important;
    max-width: unset;
    border-radius: 0;
    padding: 50px 0 0;
  }
  .info_pop h3 {
    position: absolute;
    top: 6px;
    left: 0;
    width: 100vw;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
  .info_pop .close_btn {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    font-size: 0;
    text-indent: -9999px;
    background: url(/statics/img/close_01.png);
    background-size: 100%;
    z-index: 1;
  }
  .info_pop .contain {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    overflow-y: scroll;
    font-size: 12px;
    font-weight: 300;
    color: #222;
    line-height: 1.7em;
    word-break: break-all;
  }
  .info_pop .contain h6 {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.03em;
    margin: 15px 0 10px;
    line-height: 1.6em;
  }
  .info_pop .contain p {
    margin: 4px 0 10px;
  }
  .info_pop .contain ol li {
    list-style: decimal;
    margin-left: 18px;
    margin-bottom: 6px;
  }
  .info_pop .contain ol li:last-child {
    margin-bottom: 18px;
  }
  .info_pop .contain ul li {
    list-style-type: "-";
    margin-left: 18px;
    margin-bottom: 6px;
  }
  .info_pop .contain ul li:last-child {
    margin-bottom: 18px;
  }
  .info_pop table {
    width: 100%;
    margin-bottom: 15px;
    font-size: 10px;
    line-height: 1.4em;
    padding: 5px;
  }
  .info_pop table td {
    border: 1px solid #ddd;
    padding: 5px;
    vertical-align: middle;
  }
  .info_pop table th {
    text-align: center;
    border: 1px solid #ddd;
    padding: 5px;
    font-size: 10px;
    font-weight: 400;
    vertical-align: middle;
  }
  .layerBackdrop > div {
    position: relative;
    padding: 15px;
  }
  /deep/ .layerBackdrop a > img {
    max-width: 320px !important;
  }
</style>
