var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{attrs:{"id":"header"}},[_c('div',{ref:"header",staticClass:"header_wrap"},[_c('div',{staticStyle:{"width":"100vw"}})]),_c('div',{staticClass:"ftr_fixed_menu"},[_c('router-link',{class:{on: _vm.isFooterVisible('ChartView')},attrs:{"to":"/chart/hot100"}},[_vm._v("Ranking")]),_c('router-link',{class:{on: _vm.isFooterVisible('Search')|| _vm.isFooterVisible('SearchHotel') || _vm.isFooterVisible('HotelDetail')},attrs:{"to":"/search"}},[_vm._v("Search")]),_c('router-link',{class:{on: _vm.isFooterVisible('Contents')	|| _vm.isFooterVisible('ContentsDetail') || _vm.isFooterVisible('ContentsEditor')},attrs:{"to":"/contents/check-in"}},[_vm._v("Contents")]),_c('router-link',{class:{on: _vm.isFooterVisible('Airline') || _vm.isFooterVisible('AirlineDetail')},attrs:{"to":"/airline"}},[_vm._v("Flight")])],1),_c('transition-group',{attrs:{"appear":"","enter-active-class":"animated fadeIn","leave-active-class":"animated fadeOut"}},[(
				_vm.isHeaderVisible('Search')
				|| _vm.isHeaderVisible('Contents')
				|| _vm.isHeaderVisible('ContentsEditor')
				|| _vm.isHeaderVisible('Video')
				|| _vm.isHeaderVisible('VideoDetail')
				|| _vm.isHeaderVisible('Airline')
				|| _vm.isHeaderVisible('Special')
				|| _vm.isHeaderVisible('SearchAll'))?_c('div',{key:"Search || Contents || ContentsEditor || Video || Airline || Special || SearchAll",staticClass:"{ fixed: fixed }"},[_c('div',{staticClass:"header_bar"},[_c('a',{staticClass:"btn_back",on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('q-btn',{staticClass:"hbg_btn",attrs:{"unelevated":""},on:{"click":function($event){return _vm.toggleDrawer()}}})],1)]):_vm._e(),(
				_vm.isHeaderVisible('ChartView')
				|| _vm.isHeaderVisible('ContentsDetail')
				|| _vm.isHeaderVisible('AirlineDetail'))?_c('div',{key:"ChartView || ContentsDetail || AirlineDetail",staticClass:"{ fixed: fixed }"},[_c('div',{staticClass:"header_bar ct_view"},[_c('a',{staticClass:"btn_back white",on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('q-btn',{staticClass:"hbg_btn",attrs:{"unelevated":""},on:{"click":function($event){return _vm.toggleDrawer()}}})],1)]):_vm._e(),(
				_vm.isHeaderVisibleScroll('ChartView')
				|| _vm.isHeaderVisibleScroll('ContentsDetail')
				|| _vm.isHeaderVisibleScroll('AirlineDetail'))?_c('div',{key:"",staticClass:"{ fixed: fixed }"},[_c('div',{staticClass:"header_bar"},[_c('a',{staticClass:"btn_back",on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('q-btn',{staticClass:"hbg_btn",attrs:{"unelevated":""},on:{"click":function($event){return _vm.toggleDrawer()}}})],1)]):_vm._e(),(_vm.isHeaderVisible('SearchHotel'))?_c('div',{key:"SearchHotel",staticClass:"{ fixed: fixed }"},[_c('div',{staticClass:"header_bar ht_lst"},[_c('a',{staticClass:"btn_back white",on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('div',{staticClass:"cont"},[_c('span',[_vm._v(_vm._s(_vm.getSearchHotelQueryKeyword)+" 호텔 검색 결과")]),_c('p',[_vm._v("...")])]),_c('div',{staticClass:"btn_rgt"},[_c('a',{on:{"click":function($event){return _vm.showSearchHotelModal(true)}}},[_vm._v("변경")])])])]):_vm._e(),(_vm.isHeaderVisible('HotelDetail'))?_c('div',{key:"HotelDetail",staticClass:"{ fixed: fixed }"},[_c('div',{staticClass:"header_bar ht_detail"},[_c('a',{staticClass:"btn_back",on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('div',{staticClass:"cont"},[_c('span',[_vm._v(_vm._s(_vm.getCurrentHotel.name))]),_c('p',[_vm._v("...")])]),_c('div',{staticClass:"btn_rgt"},[_c('a',{on:{"click":function($event){return _vm.showSearchHotelModal(true)}}},[_vm._v("변경")])])])]):_vm._e(),(_vm.isHeaderVisible('Home'))?_c('div',{key:"Home",staticClass:"{ fixed: fixed }"},[_c('div',{staticClass:"header_bar"},[_c('router-link',{staticClass:"q-pl-xs bi",attrs:{"to":"/home"}},[_vm._v("logo")]),_c('q-btn',{staticClass:"hbg_btn",attrs:{"unelevated":""},on:{"click":function($event){return _vm.toggleDrawer()}}})],1)]):_vm._e(),(
				_vm.isHeaderVisible('Login')
				|| _vm.isHeaderVisible('Mypage')
				|| _vm.isHeaderVisible('MypageAccount')
				|| _vm.isHeaderVisible('MypageHotel')
				|| _vm.isHeaderVisible('MypagePassword')
				|| _vm.isHeaderVisible('MypageHelp')
				|| _vm.isHeaderVisible('MypageSendmail'))?_c('div',{key:"Login|| JoinBasic || JoinAdd || Mypage || 'MypageAccount\t|| MypageHotel || MypagePassword || MypageHelp || MypageSendmail",staticClass:"{ fixed: fixed }"},[_c('div',{staticClass:"header_bar"},[_c('a',{staticClass:"btn_back",on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('q-btn',{staticClass:"hbg_btn",attrs:{"unelevated":""},on:{"click":function($event){return _vm.toggleDrawer()}}})],1)]):_vm._e()]),_c('q-scroll-observer',{on:{"scroll":_vm.handleScroll}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }