import Vue from 'vue'
import vuex from '../store/index'
import { LocalStorage } from 'quasar'

// const requireAuth = (from, to, next) => {
//   const store = vuex()
//   const accessToken = LocalStorage.getItem('accessToken')
//   const guide = LocalStorage.getItem('guide')
//   if (!guide) {
//     next('/guide')
//   }
//   else if (!accessToken) {
//     delete Vue.axios.defaults.headers.common['Accept']
//     delete Vue.axios.defaults.headers.common['Authorization']
//     store.dispatch('setLoginUserData', null)
//     next('/login')
//   } else {
//     Vue.axios.defaults.headers.common['Accept'] = 'application/json'
//     Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
//     Vue.axios.get('/user/detail').then(rs => {
//       if (!store.getters.getExistLoginUserData || from.name === 'MypageAccount') store.dispatch('setLoginUserData', rs.data)
//       next()
//     }).catch(e => {
//       alert('동일한 아이디로 다른 디바이스에서 로그인되었습니다.\n자동 로그아웃되어 로그인화면으로 이동합니다.')
//       LocalStorage.remove('accessToken')
//       delete Vue.axios.defaults.headers.common['Accept']
//       delete Vue.axios.defaults.headers.common['Authorization']
//       store.dispatch('setLoginUserData', null)
//       next('/login')
//     })
//   }
// }

// const requireToken = (from, to, next) => {
//   const store = vuex()
//   const accessToken = LocalStorage.getItem('accessToken')
//   if (accessToken) {
//     Vue.axios.defaults.headers.common['Accept'] = 'application/json'
//     Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
//     Vue.axios.get('/user/detail').then(rs => {
//       if (!store.getters.getExistLoginUserData) store.dispatch('setLoginUserData', rs.data)
//     })
//   }
//   next()
// }

const routes = [
  {
    path: '/',
    redirect: 'home'
  },
  { // 홈
    path: '/home',
    name: 'Home',
    component: () => import('src/mobile/pages/Home'),
    // beforeEnter: requireAuth
  },
  // { // 랭킹홈
  //   path: '/chart',
  //   name: 'Chart',
  //   component: () => import('src/mobile/pages/Chart')
  // },
  { // 랭킹리스트
    path: '/chart/:view',
    name: 'ChartView',
    component: () => import('src/mobile/pages/ChartView'),
    // beforeEnter: requireToken
  },
  { // 전체검색
    path: '/search/all',
    name: 'SearchAll',
    component: () => import('src/mobile/pages/SearchAll'),
    // beforeEnter: requireToken
  },
  { // 검색홈
    path: '/search',
    name: 'Search',
    component: () => import('src/mobile/pages/Search')
  },
  { // 검색 리스트
    path: '/search/hotel',
    name: 'SearchHotel',
    component: () => import('src/mobile/pages/SearchHotel'),
    // beforeEnter: requireToken
  },
  { // 호텔상세
    path: '/hotel/detail/:idx',
    name: 'HotelDetail',
    component: () => import('src/mobile/pages/HotelDetail'),
    // beforeEnter: requireToken
  },
  {
    path: '/hotel/bridge/:idx',
    name: 'HotelBridge',
    component: () => import('src/mobile/pages/HotelBridge'),
    // beforeEnter: requireToken
  },
  { // 콘텐츠
    path: '/contents/:cate',
    name: 'Contents',
    component: () => import('src/mobile/pages/Contents'),
    // beforeEnter: requireToken
  },
  { // 콘텐츠상세페이지
    path: '/contents/detail/:idx',
    name: 'ContentsDetail',
    component: () => import('src/mobile/pages/ContentsDetail'),
    // beforeEnter: requireToken
  },
  { // 비디오
    path: '/video/',
    name: 'Video',
    component: () => import('src/mobile/pages/Video'),
    // beforeEnter: requireToken
  },
  { // 비디오
    path: '/video/:idx',
    name: 'VideoDetail',
    component: () => import('src/mobile/pages/Video'),
    // beforeEnter: requireToken
  },
  { // 항공사홈
    path: '/airline',
    name: 'Airline',
    component: () => import('src/mobile/pages/Airline'),
    // beforeEnter: requireToken
  },
  { // 항공사페이지
    path: '/airline/detail/:idx',
    name: 'AirlineDetail',
    component: () => import('src/mobile/pages/AirlineDetail'),
    // beforeEnter: requireToken
  },
  { // 에디터리스트
    path: '/contents/editor/:idx',
    name: 'ContentsEditor',
    component: () => import('src/mobile/pages/ContentsEditor'),
    // beforeEnter: requireToken
  },
  { // 추천페이지
    path: '/special',
    name: 'Special',
    component: () => import('src/mobile/pages/Special'),
    // beforeEnter: requireToken
  },
  /* -- App 에서 Web 으로 재전환하면서 회원제 사용안함 (21.11.22) -- //
  { // 가이드 스크린
    path: '/guide',
    name: 'Guide',
    component: () => import('src/mobile/pages/Guide')
  },
  { // 이용약관
    path: '/terms',
    name: 'Terms',
    component: () => import('src/mobile/pages/Terms')
  },
  { // 개인정보 처리방침
    path: '/policy',
    name: 'Policy',
    component: () => import('src/mobile/pages/Policy')
  },
  { // 로그인
    path: '/login',
    name: 'Login',
    component: () => import('src/mobile/pages/Login')
  },
  { // 회원가입 - (통합)
    path: '/join',
    name: 'Join',
    component: () => import('src/mobile/pages/Join')
  },
  // { // 회원가입 - 1페이지
  //   path: '/join/basic',
  //   name: 'JoinBasic',
  //   component: () => import('src/mobile/pages/JoinBasic')
  // },
  // { // 회원가입 - 2페이지
  //   path: '/join/add',
  //   name: 'JoinAdd',
  //   component: () => import('src/mobile/pages/JoinAdd')
  // },
  { // 마이페이지 - 메인
    path: '/mypage',
    name: 'Mypage',
    component: () => import('src/mobile/pages/Mypage'),
    beforeEnter: requireAuth
  },
  { // 마이페이지 - 계정관리
    path: '/mypage/account',
    name: 'MypageAccount',
    component: () => import('src/mobile/pages/MypageAccount'),
    beforeEnter: requireAuth
  },
  { // 마이페이지 - 찜한호텔
    path: '/mypage/hotel',
    name: 'MypageHotel',
    component: () => import('src/mobile/pages/MypageHotel'),
    beforeEnter: requireAuth
  },
  { // 마이페이지 - 도움말
    path: '/mypage/help',
    name: 'MypageHelp',
    component: () => import('src/mobile/pages/MypageHelp'),
    beforeEnter: requireAuth
  },
  { // 마이페이지 - 이메일문의
    path: '/mypage/sendmail',
    name: 'MypageSendmail',
    component: () => import('src/mobile/pages/MypageSendmail'),
    beforeEnter: requireAuth
  },
  // { // 마이페이지 - 비밀번호변경
  //   path: '/mypage/password',
  //   name: 'MypagePassword',
  //   component: () => import('src/mobile/pages/MypagePassword'),
  //   beforeEnter: requireAuth
  // },
  // -- App 에서 Web 으로 재전환하면서 회원제 사용안함 (21.11.22) -- */
  {
    path: '*',
    redirect: 'home'
  }
]

export default routes
