import Vue from 'vue'

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
//
// import VueLocalstorage from 'vue-localstorage'
// Vue.use(VueLocalstorage)
//
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

var VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo)
// You can also pass in the default options
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: true,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})



import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

export default {}
