export const UPDATE_DISPLAY_CATE_DATA = 'UPDATE_DISPLAY_CATE_DATA'
export const UPDATE_DISPLAY_DATA = 'UPDATE_DISPLAY_DATA'
export const PUSH_LIST_OF_CALLED_API = 'PUSH_LIST_OF_CALLED_API'
export const UPDATE_CHART_DATA = 'UPDATE_CHART_DATA'
export const UPDATE_HOTEL_REGION_DATA = 'UPDATE_HOTEL_REGION_DATA'
export const UPDATE_HOTEL_HASH_TAG_DATA = 'UPDATE_HOTEL_HASH_TAG_DATA'
export const UPDATE_AIRLINE_CORP_DATA = 'UPDATE_AIRLINE_CORP_DATA'
export const CHANGE_SIZE_TOAST_BANNER = 'CHANGE_SIZE_TOAST_BANNER'
export const UPDATE_CURRENT_CHART_TITLE = 'UPDATE_CURRENT_CHART_TITLE'
export const UPDATE_OPEN_SEARCH_HOTEL_DIALOG = 'UPDATE_OPEN_SEARCH_HOTEL_DILALOG'
export const CHANGE_OPEN_ALL_CHART_DIALOG = 'CHANGE_OPEN_ALL_CHART_DIALOG'
export const CHANGE_OPEN_SHARE_DIALOG = 'CHANGE_OPEN_SHARE_DIALOG'
export const CHANGE_OPEN_TERMS_DIALOG = 'CHANGE_OPEN_TERMS_DIALOG'
export const CHANGE_OPEN_POLICY_DIALOG = 'CHANGE_OPEN_POLICY_DIALOG'
export const UPDATE_CURRENT_CALENDAR_LABEL = 'UPDATE_CURRENT_CALENDAR_LABEL'
export const CHANGE_OPEN_CALENDAR_DIALOG = 'CHANGE_OPEN_CALENDAR_DIALOG'
export const UPDATE_OPEN_DROPDOWN = 'UPDATE_OPEN_DROPDOWN'
export const UPDATE_CURRENT_CONTENTS_DATA = 'UPDATE_CURRENT_CONTENTS_DATA'
export const UPDATE_CURRENT_HOTEL = 'UPDATE_CURRENT_HOTEL'
export const UPDATE_SEARCH_HOETL_QUERY = 'UPDATE_SEARCH_HOETL_QUERY'
export const UPDATE_SEARCH_HOTEL_RESULT = 'UPDATE_SEARCH_HOTEL_RESULT'
export const UPDATE_CALENDAR_FOR_YEAR = 'UPDATE_CALENDAR_FOR_YEAR'
export const UPDATE_LOGIN_USER_DATA = 'UPDATE_LOGIN_USER_DATA'
export const UPDATE_APP_PLATFORM = 'UPDATE_APP_PLATFORM'
