import Vue from 'vue'
import App from './App.vue'
import routes from 'src/router/routes_mobile'
import store from 'src/store'
import qboot_Bootaxios from 'src/boot/axios'
import VueRouter from 'vue-router'
import VueRouterPrefetch from 'vue-router-prefetch'
import qboot_Bootimports from 'src/boot/imports'

// library imports
import './pollyfills'
import './registerServiceWorker'

// quasar imports
import 'quasar/dist/quasar.ie.polyfills.js'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import 'quasar/dist/quasar.styl'
import 'src/css/app.styl'

// NavigationDuplicated 대응
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

// plugin setup
Vue.use(VueRouter)
Vue.use(VueRouterPrefetch)


import Quasar, {
  QLayout,QHeader,QDrawer,QScrollArea,QPageContainer,QPage,QToolbar,QToolbarTitle,QBtn,QIcon,QList,QItem,QImg,QItemSection,QItemLabel,QSlideTransition,QDialog,QInfiniteScroll,QSpinnerDots,QCarousel,QCarouselControl,QCarouselSlide,QTabPanels,QTabPanel,QSkeleton,QForm,QInput,QDate,QExpansionItem,QChip
} from 'quasar'
Vue.use(Quasar, {
  config: {
    brand: {
      primary: '#fff',
      secondary: '#ff6e61'
    }
  },
  components: {
    QLayout,QHeader,QDrawer,QScrollArea,QPageContainer,QPage,QToolbar,QToolbarTitle,QBtn,QIcon,QList,QItem,QImg,QItemSection,QItemLabel,QSlideTransition,QDialog,QInfiniteScroll,QSpinnerDots,QCarousel,QCarouselControl,QCarouselSlide,QTabPanels,QTabPanel,QSkeleton,QForm,QInput,QDate,QExpansionItem,QChip
  },
})


// configure router
const router = new VueRouter({
  mode: 'history',
  hash: false,
  // linkActiveClass: 'active',
  routes, // short for routes: routes
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {selector: to.hash}
    } else if (to.name === from.name && to.query !== from.query && (to.name.substr(0, 6) !== 'Search' && to.name.substr(0, 5) !== 'Chart')) {
      return {x: 0, y: 0}
    } else if (to.name === from.name) {
      if (from.name === 'ChartView') {
        if (from.params !== to.params || from.query.date !== to.query.date) return {x: 0, y: 0}
      }
      return
    } else {
      return {x: 0, y: 0}
    }
  }
})


Vue.config.devtools = true
Vue.config.productionTip = false

console.info('[Quasar] Running SPA.')

async function start () {

  const app = {
    el: '#q-app',
    router,
    store,
    render: h => h(App)
  }

  const bootFiles = [qboot_Bootaxios, qboot_Bootimports]
  for (let i = 0; i < bootFiles.length; i++) {
    if (typeof bootFiles[i] !== 'function') {
      continue
    }

    try {
      await bootFiles[i]({
        app,
        router,
        store,
        Vue,
        ssrContext: null
      })
    }
    catch (err) {
      if (err && err.url) {
        window.location.href = err.url
        return
      }
      console.error('[Quasar] boot error:', err)
      return
    }
  }

  new Vue(app)

}

start()