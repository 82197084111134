<script>
  import SubHeader from 'src/pc/components/SubHeader'
  import DisplayContainer from './DisplayContainer'
  export default {
    extends: SubHeader,
    components: {
      DisplayContainer
    }
  }
</script>

<style scoped>
  .con_hd_ad {
    float: right;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>

<style>
  .con_hd {
    height: auto !important;
  }
  .con_hd .inner {
    background: #fff !important;
  }
  .con_hd .con_hd_ad {
    width: 100% !important;
    height: auto !important;
    max-width: 100vm !important;
    padding: 5px 0;
  }
  .con_hd .con_hd_ad img {
    width: 320px !important;
    max-width: 100vm !important;
    height:auto !important;
  }
  .con_hd .inner h2 {
    display: none !important;
  }
</style>