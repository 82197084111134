<template>
  <div>

    <!-- 검색 -->
    <q-dialog v-model="searchHotelDialog.main" @show="showSearchDialog" @hide="hideSearchDialog">
      <div class="modal basic" style="height: 340px">
        <div class="header">호텔 검색<div class="btn_back btn_close" @click="searchHotelDialog.main = false"><i>&#10005;</i></div></div>
        <div class="sect">
          <p class="btn ipt_area" :class="{ sel: search.region.length > 0 || search.cate }" @click="searchHotelDialog.region = true">{{ getRegionText }}</p>
          <p class="btn ipt_theme" :class="{ sel: search.hashTags.length > 0 }" @click="searchHotelDialog.hashTags = true">{{ getHashTagText }}</p>
          <input :value="search.keyword" type="text" class="ipt_city" readonly="readonly" placeholder="호텔 이름으로 검색해주세요." @click="searchHotelDialog.keyword = true">
          <a class="btn_confirm" :disabled="isDisabledSearchHotel" @click="setSearchHotel">검색</a>
          <a class="btn_reset" @click="resetSearchHotel()">검색 초기화</a>
        </div>
      </div>
    </q-dialog>
    <!-- //검색 -->

    <!-- 도시,호텔검색 modal -->
    <q-dialog v-model="searchHotelDialog.keyword" @show="showSearchHotelDialog" @hide="hideSearchHotelDialog" full-height>
      <div class="modal hotel">
        <div>
          <div class="header">호텔 검색<div class="btn_back btn_close" @click="searchHotelDialog.keyword = false"><i>&#10005;</i></div></div>
          <div class="sect">
            <input ref="keyword" v-model="keywordTemp" type="text" class="ipt_sch" placeholder="호텔 이름으로 검색해주세요." @keyup="searchAutocomplete()" @keydown.enter="searchKeywordDirectly()" />
            <ul class="sch_lst" v-for="(data, i) in autocompleteData.data" :key="i" @click="setKeyword(data)">
              <li class="main_txt">{{data.name}}</li>
              <li class="sub_txt">{{data.place}}</li>
            </ul>
          </div>
        </div>
      </div>
    </q-dialog>
    <!--//도시,호텔검색 modal -->

    <!-- 지역 선택 modal -->
    <q-dialog v-model="searchHotelDialog.region" @show="showSearchRegionDialog" @hide="hideSearchRegionDialog" full-height>
      <div class="modal">
        <div class="header">지역 선택<div class="btn_back btn_close" @click="searchHotelDialog.region = false"><i>&#10005;</i></div></div>
        <div class="sect area">
          <p class="item" :class="{ on: isOnCate('DOMESTIC') }" @click="setCateTemp('DOMESTIC')">국내 전체</p>
          <p class="item" :class="{ on: isOnRegion(row.idx) }" @click="setRegionTemp(row.idx)" v-for="row in getHotelRegionData" :key="row.idx">{{row.name}}</p>
          <p class="item" :class="{ on: isOnCate('OVERSEAS') }" @click="setCateTemp('OVERSEAS')">해외 전체</p>
        </div>
        <div class="btm btm_fixed">
          <a class="apply" @click="setRegion()">선택 완료</a>
        </div>
      </div>
    </q-dialog>
    <!--//지역 선택 modal -->

    <!-- 테마 선택 modal -->
    <q-dialog v-model="searchHotelDialog.hashTags" @show="showSearchHashTagsDialog" @hide="hideSearchHashTagsDialog" full-height>
      <div class="modal">
        <div class="header">테마 선택<div class="btn_back btn_close" @click="searchHotelDialog.hashTags = false"><i>&#10005;</i></div></div>
        <div class="sect theme">
          <p class="item" :class="{ on: isOnThisHashTag(row.idx) }" @click="setHashTagsTemp(row)" v-for="row in getHotelHashTagData" :key="row.idx">{{row.name}}</p>
        </div>
        <div class="btm btm_fixed">
          <a class="apply" @click="setHashTags()">선택 완료</a>
        </div>
      </div>
    </q-dialog>
    <!--//테마 선택 modal -->

  </div>
</template>

<script>
  import CommonComputed from 'src/_mixins/CommonComputed'
  import CommonMethods from 'src/_mixins/CommonMethods'
  import { mapGetters } from 'vuex'
  import { date } from 'quasar'
  export default {
    name: 'SearchHotelModal',
    components: {
    },
    // props: {
      // directApply: { // 검색결과를 바로 적용
      //   default: false
      // },
      // keepSearchQuery: { // 모달창을 닫으면 검색값 유지
      //   default: false
      // }
    // },
    data () {
      return {
        // isOpenSearchDialog: false,
        // searchHotelDialog: false,
        // isOpenSearchRegionDialog: false,
        // isOpenSearchHashTagsDialog: false,
        search: {},
        keywordTemp: '',
        regionTemp: [],
        cateTemp: '',
        hashTagsTemp: [],
        autocompleteData: {
          data: [],
          selected: {}
        },
        msg: {
          maxCount: '한 번에 최대 9개의 객실과 16명의 투숙객만 검색하실 수 있어요.'
        }
      }
    },
    created () {
      this.search = this.cloneData(this.getSearchHotelQueryOrigin)
      this.$store.dispatch('setHotelRegionData')
      this.$store.dispatch('setHotelHashTagData')
    },
    computed: {
      ...CommonComputed,
      ...mapGetters([
        'getSearchHotelQuery',
        'getSearchHotelQueryOrigin',
        'getAutocompleteSearch',
        'getHotelRegionData',
        'getHotelHashTagData',
        'getHotelRegionName',
        'getHotelHashTagName',
        'searchHotelDialog',
        'isActivatedRoute'
      ]),
      getAccess () {
        return 'app'
      },
      isDisabledSearchHotel () {
        return !this.search.keyword.trim() && !this.getSearchHotelQuery.cate && this.search.region.length < 1 && this.search.hashTags.length < 1
      },
      getRegionText () {
        const cate = this.getSearchHotelQuery.cate
        const region = this.getSearchHotelQuery.region
        const cnt = region.length
        if (cnt < 1 && !cate) {
          return '지역 선택'
        } else {
          if (cate === 'OVERSEAS') {
            return '해외 전체'
          } else if (cate === 'DOMESTIC') {
            return '국내 전체'
          } else {
            const name = this.getHotelRegionName(region[0])
            if (cnt > 1) {
              return `${name} 외 ${cnt - 1}건`
            } else {
              return name
            }
          }
        }
      },
      getHashTagText () {
        const hashTags = this.getSearchHotelQuery.hashTags
        const cnt = hashTags.length
        if (cnt < 1) {
          return '테마 선택'
        } else {
          const name = this.getHotelHashTagName(hashTags[0])
          return (cnt > 1) ? `${name} 외 ${cnt - 1}건` : name
        }
      }
    },
    methods: {
      ...CommonMethods,
      isOnRegion (idx) {
        return this.regionTemp.includes(idx)
      },
      isOnCate (cate) {
        return this.cateTemp === cate
      },
      isOnThisHashTag (idx) {
        return this.hashTagsTemp.includes(idx)
      },
      showSearchDialog () {
        this.search = this.cloneData(this.getSearchHotelQuery)
      },
      hideSearchDialog () {
        this.autocompleteData.data = []
        this.autocompleteData.selected = {}
        this.keywordTemp = ''
        this.cateTemp = ''
        this.regionTemp = []
        this.hashTagsTemp = []
        this.search = this.cloneData(this.getSearchHotelQueryOrigin)
      },
      setSearchHotel () {
        if (!this.isDisabledSearchHotel) {
          this.$store.dispatch('setSearchHotelQuery', this.cloneData(this.search))
          this.searchHotelDialog.main = false
          if (!this.isActivatedRoute) this.$router.push('/search/hotel')
        }
      },
      resetSearchHotel () {
        this.hideSearchDialog()
        this.$store.dispatch('resetSearchHotelQuery')
      },
      showSearchHotelDialog () {
        if (this.$refs.keyword) this.$refs.keyword.focus()
        if (!this.keywordTemp) this.keywordTemp = this.cloneData(this.getSearchHotelQuery.keyword)
        if (this.search.keyword) {
          this.searchAutocomplete()
        }
      },
      hideSearchHotelDialog () {
        this.keywordTemp = this.cloneData(this.getSearchHotelQuery.keyword)
      },
      showSearchRegionDialog () {
        this.search = this.cloneData(this.getSearchHotelQuery)
        this.cateTemp = this.cloneData(this.search.cate)
        this.regionTemp = this.cloneData(this.search.region)
      },
      hideSearchRegionDialog () {
        this.regionTemp = this.cloneData(this.search.region)
      },
      showSearchHashTagsDialog () {
        this.search = this.cloneData(this.getSearchHotelQuery)
        this.hashTagsTemp = this.cloneData(this.search.hashTags)
      },
      hideSearchHashTagsDialog () {
        this.hashTagsTemp = this.cloneData(this.search.hashTags)
      },
      setKeyword (obj) {
        this.search.keyword = obj.name
        this.$store.dispatch('setSearchHotelQuery', this.search)
        this.searchHotelDialog.keyword = false
      },
      setRegionTemp (idx) {
        const pos = this.regionTemp.indexOf(idx)
        if (pos > -1) {
          this.regionTemp.splice(pos, 1)
        } else {
          this.cateTemp = ''
          this.regionTemp.push(idx)
        }
      },
      setCateTemp (cate) {
        if (this.cateTemp === cate) {
          this.cateTemp = ''
        } else {
          this.cateTemp = cate
        }
        this.regionTemp = []
      },
      setRegion () {
        this.search.cate = this.cloneData(this.cateTemp)
        this.search.region = this.cloneData(this.regionTemp)
        this.$store.dispatch('setSearchHotelQuery', this.search)
        this.searchHotelDialog.region = false
      },
      setHashTagsTemp (obj) {
        const pos = this.hashTagsTemp.indexOf(obj.idx)
        pos > -1 ? this.hashTagsTemp.splice(pos, 1) : this.hashTagsTemp.push(obj.idx)
      },
      setHashTags () {
        this.search.hashTags = this.cloneData(this.hashTagsTemp)
        this.$store.dispatch('setSearchHotelQuery', this.search)
        this.searchHotelDialog.hashTags = false
      },
      searchAutocomplete (keep = false) {
        this.$refs.keyword.dispatchEvent(new Event('change'))
        if (this.keywordTemp.trim()) {
          if (this.search.keyword !== this.keywordTemp) {
            const payload = {
              cate: '',
              keyword: this.keywordTemp
            }
            this.getAutocompleteSearch(payload).then(rs => {
              this.autocompleteData.data = rs.data
              const keepSelectedItem = this.autocompleteData.selected
              this.autocompleteData.selected = null
              if (keep) {
                setTimeout(() => {
                  this.autocompleteData.selected = keepSelectedItem
                })
              }
            }).catch(() => {
              alert('데이터를 가져올 수 없습니다. 잠시 후 다시 시도해 보세요.')
              this.autocompleteData.selected = null
            })
          }
        } else {
          this.autocompleteData.data = []
          this.autocompleteData.selected = null
          return false
        }
      },
      searchKeywordDirectly () {
        this.search.keyword = this.keywordTemp
        this.$store.dispatch('setSearchHotelQuery', this.cloneData(this.search))
        this.searchHotelDialog.keyword = false
      }
    }
  }
</script>

<style scoped>

  /* modal */
  .modal {
    width: 100%;
    height: unset !important;
    max-height: 80% !important;
    background: #fff;
    position: relative;
  }
  .desktop .modal.hotel {
    min-height: 78vh;
  }
  .modal .header {
    height: 60px;
    font-size: 1.15em;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 10;
  }
  .modal .header .btn_back {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    cursor: pointer;
  }
  .modal .header .btn_back:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    width: 12px;
    height: 12px;
    border: solid #888;
    border-width: 0 0 1.5px 1.5px;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .modal .header .btn_back.btn_close:after {
    display: none;
  }
  .modal .header .btn_back i {
    position: absolute;
    font-size: 20px;
    color: #888;
  }
  .modal .sect {
    padding: 25px 20px;
    border-bottom: 0;
  }
  .modal .sect .title {
    font-size: 1.15em;
    font-weight: 500;
    margin-bottom: 20px;
    letter-spacing: -0.03em;
  }
  .modal .sect .sch_lst {
    padding: 10px;
    cursor: pointer;
  }
  .modal .sect .sch_lst:hover {
    background: #f5f5f5;
  }
  .modal .sect .main_txt {
    font-size: 1.1em;
    letter-spacing: -0.02em;
  }
  .modal .sect .sub_txt {
    font-size: 0.9em;
    color: #888;
  }
  .modal .sect .btn_wrap {
    text-align: center;
    margin-bottom: 10px;
  }
  .modal .sect .btn_wrap .btn_select {
    display: inline-block;
    width: 48%;
    border: 1px solid #ddd;
    font-size: 14px;
    font-weight: 500;
    color: #222;
    text-align: center;
    padding: 14px;
    margin: 1%;
  }
  .modal .sect .btn_wrap .btn_select.on {
    border: 1px solid #01e1c9;
    color: #222;
    background: #01e1c9;
  }
  .modal .sect input[type=text] {
    width: 100%;
    height: 50px;
    background: #fff;
    border: 0;
    margin-bottom: 10px;
    line-height: 50px;
    padding-left: 45px;
    font-size: 14px;
    color: #222;
    border: 1px solid #ccc;
    background-size: 18px 18px;
    background-position: 16px center;
    background-repeat: no-repeat;
  }
  .modal .sect input[type=text].ipt_sch {
    background-image: url('/statics/img/ico_sch_sch.png');
  }
  .modal .sect p.btn {
    display: inline-block;
    line-height: 50px;
    font-weight: 700;
    width: 100%;
    height: 50px;
    text-align: center;
    background-color: #fff;
    background-size: 18px 18px;
    background-position: 3.0% center;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    color: #222;
    cursor: pointer;
    margin-bottom: 10px;
    filter: grayscale(1.0);
  }
  .modal .sect p.btn.on {
    background-color: #40b8b3;
    color: #007869;
  }
  .modal .sect p.btn.sel {
    background-color: #eefaf9;
    border: 1px solid #00897a;
    color: #00897a;
    filter: grayscale(0.0);
  }
  .modal .sect p.btn:hover {
    background-color: #f5f5f5;
  }
  .modal .sect .ipt_area {
    background-image: url('/statics/img/ico_sch_area.png');
  }
  .modal .sect .ipt_theme {
    background-image: url('/statics/img/ico_sch_theme.png');
    margin-right: 0 !important;
  }
  .modal .sect .btn_confirm {
    width: 100%;
  }
  .modal .sect .btn_reset {
    display: block;
    font-weight: 700;
    color: #666;
    margin-top: 20px;
    text-align: right;
	}
	.modal .sect .btn_reset:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 19px;
    background-image: url('/statics/img/icon_reset.png');
    background-repeat: no-repeat;
    margin-right: 6px;
	}
  .modal .sect.area .item {
    display: inline-block;
    width: 25%;
    height: 50px;
    margin-top: -1px;
    margin-left: -1px;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: #666;
    text-align: center;
    line-height: 50px;
    letter-spacing: -0.5px;
    transition: all 0.15s;
    border: solid #ddd;
    border-width: 1px 1px 1px 0;
  }
  .modal .sect.area .item:nth-child(4n+1) {
    border-width: 1px;
  }
  .modal .sect.area .item.on {
    font-weight: 700;
    color: #00897a;
    background: #ccf9f4;
    border: 1px solid #00897a;
    z-index: 1;
  }
  .modal .sect.theme .item {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: #666;
    letter-spacing: -0.5px;
    transition: all 0.15s;
    border: solid #ddd;
    border-width: 1px;
    border-radius: 2.0em;
    padding: 6px 14px;
    margin: 0 10px 10px 0;
  }
  .modal .sect.theme .item.on {
    font-weight: 400;
    color: #00897a;
    background: #ccf9f4;
    border: 1px solid #00897a;
    z-index: 1;
  }
  .modal .day {
    height: 50px;
    position: sticky;
    top: 60px;
    left: 0;
    right: 0;
    border-bottom: 1px solid #ddd;
    background: #fff;
    padding: 0 20px;
    line-height: 50px;
    z-index: 10;
  }
  .modal .day li {
    display: inline-block;
    width: 14.2857%;
    text-align: center;
    font-size: 13px;
    color: #222;
  }
  .modal .sect.calendar {
    height: calc(100% - 250px);
    position: relative;
    border: 0;
  }
  .modal .calendar .month {
    margin-bottom: 40px;
  }
  .modal .calendar .month:last-child {
    margin-bottom: 0;
  }
  .modal .calendar .month .title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .modal .calendar .month li {
    display: inline-block;
    width: 14.2857%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color: #222;
    margin: 5px 0;
    cursor: pointer;
  }
  .modal .calendar .month li.disable {
    color: #ccc;
  }
  .modal .calendar .month li.check {
    background: #01e1c9;
    font-weight: 700;
  }
  .modal .calendar_ftr {
    position: sticky;
    bottom: 0;
    background: #fff;
    border-top: 1px solid #ddd;
    z-index: 1003;
    text-align: center;
    padding: 0 !important;
  }
  .modal .calendar_ftr .date_wrap ul {
    display: inline-block;
    width: 50%;
    margin: 15px 0;
    padding: 5px 30px;
    border-right: 1px solid #ddd;
    letter-spacing: -0.02em;
  }
  .modal .calendar_ftr .date_wrap ul:last-child {
    border-right: 0;
  }
  .modal .calendar_ftr .date_wrap ul li.chk {
    font-size: 13px;
    font-weight: 500;
    color: #888;
    margin-bottom: 2px;
  }
  .modal .calendar_ftr .date_wrap ul li.date {
    font-size: 16px;
    font-weight: 500;
  }
  .modal .btm {
    padding: 0;
  }
  .modal .btm.btm_fixed {
    position: sticky;
    bottom: 0;
    z-index: 2;
  }
  .modal .btm .apply {
    font-size: 1.1em;
    font-weight: 700;
    color: #fff;
    background: #00897a;
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 16px;
  }
  .modal .sect .opt_lst {
    display: block;
    width: 100%;
    padding: 18px 0;
    position: relative;
    border-bottom: 1px solid #ccc;
  }
  .modal .sect .opt_lst:last-child {
    border-bottom: 0;
  }
  .modal .sect .opt_lst span {
    display: inline-block;
    width: 50%;
    font-size: 15px;
    letter-spacing: -0.02em;
    line-height: 28px;
  }
  .modal .sect .opt_lst span:first-child {
    text-align: left;
  }
  .modal .sect .opt_lst span:last-child {
    text-align: right;
  }
  .modal .sect .opt_lst span:last-child i {
    font-size: 18px;
    font-weight: 500;
    display: inline-block;
    width: 20px;
    text-align: center;
    margin: 0 12px;
  }
  .modal .sect .opt_lst span:last-child button {
    border: 1px solid #00897a;
    border-radius: 100%;
    width: 28px;
    height: 28px;
    font-size: 24px;
    color: #00897a;
    cursor: pointer;
  }
  .modal .sect .opt_lst span input {
    border: 1px solid #fff;
    text-align: right;
  }
</style>
