<script>
  import App from 'src/pc/App'
  import DefaultLayout from './layouts/DefaultLayout'
  import BridgeLayout from './layouts/BridgeLayout'
  // import GuideLayout from './layouts/GuideLayout'
  export default {
    extends: App,
    components: {
      DefaultLayout,
      BridgeLayout
      // GuideLayout
    }
  }
</script>

<style>
  body {
    background-color: #fff !important;
  }
  .contentArea {
    background: #fff;
  }
  .con_hd {
    height: 100px;
    background: #fff;
  }
  .con_hd h2 {
    float: left;
    line-height: 90px;
    padding-top: 10px;
    font-size: 30px;
    font-weight: bold;
    color: #222;
    letter-spacing: -0.5px;
  }
  .con_hd .con_hd_ad {
    float: right;
  }
  .content {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  /* 공통 */
  .box_basic {
    background: #fff;
    padding: 35px 32.5px;
    /*margin: 0 auto 8px;*/
    display: inline-block;
    width: 100%;
    color: #222;
    border-bottom: 8px solid #f2f2f2;
  }
  .box_basic:last-child {
    border-bottom: 0;
  }
  .box_basic.last {
    border-bottom: 0;
  }
  .hotel_list .box_basic {
    border-bottom: 8px solid #f2f2f2;
  }
  .box_basic a {
    color: #222;
  }
  .tit_l {
    font-size: 18px;
    color: #222;
    font-weight: 500;
    line-height: 1.4em;
    margin-bottom: 20px;
    letter-spacing: -0.03em;
  }
  .tit_l span {
    display: block;
  }
  .tit_s {
    font-size: 16px;
    color: #222;
    font-weight: 500;
    line-height: 1.0em;
    margin-bottom: 15px;
    letter-spacing: -0.02em;
  }
  .tit_rev {
    color: #00897a;
    margin-bottom: 35px;
  }
  .tit_rev:before {
    content: "";
    display: inline-block;
    width: 13px;
    height: 17px;
    background: url('/statics/img/ico_pin_badge.png');
    background-size: 13px auto;
    margin-right: 5px;
  }
  .tit_rec:before {
    content: "";
    display: inline-block;
    width: 13px;
    height: 17px;
    background: url('/statics/img/tips_ico_pin_title.png');
    background-size: 13px auto;
    margin-right: 5px;
  }
  .con_add .tag {
    margin-bottom: 20px;
  }
  .con_add .tag li {
    display: inline-block;
    font-size: 13px;
    color: #222;
    font-weight: 700;
    letter-spacing: -0.03em;
    padding: 7px 15px;
    border: 1px solid #ccc;
  }
  .con_add .tag.mgn_top {
    margin-top: 35px;
  }
  .box_basic .scroller {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100vw;
    margin-left: -32.5px;
    padding-left: 32.5px;
    padding-bottom: 15px;
    margin-bottom: -15px;
  }
  .box_basic .scroller .inner {
    max-width: unset !important;
    white-space: nowrap;
  }
  /*
  .inner_l {
    width: calc((100vw - 50px) * 3 + 17.5px);
  }
  .inner_l.num-1 {
    width: calc((100vw - 50px) * 1 + 17.5px);
  }
  .inner_l.num-2 {
    width: calc((100vw - 50px) * 2 + 17.5px);
  }
  .inner_l.num-3 {
    width: calc((100vw - 50px) * 3 + 17.5px);
  }
  .inner_l.num-4 {
    width: calc((100vw - 50px) * 4 + 17.5px);
  }
  .inner_m {
    width: calc((66vw + 15px) * 3 + 17.5px);
  }
  .inner_m.num-2 {
    width: calc((66vw + 15px) * 2 + 17.5px);
  }
  .inner_m.num-3 {
    width: calc((66vw + 15px) * 3 + 17.5px);
  }
  .inner_m.num-4 {
    width: calc((66vw + 15px) * 4 + 17.5px);
  }
  .inner_m.num-5 {
    width: calc((66vw + 15px) * 5 + 17.5px);
  }
  .inner_s {
    width: calc((39vw + 15px) * 3 + 17.5px);
  }
  .inner_s.num-4 {
    width: calc((39vw + 15px) * 4 + 17.5px);
  }
  .inner_s.num-5 {
    width: calc((39vw + 15px) * 5 + 17.5px);
  }
  .inner_s.num-6 {
    width: calc((39vw + 15px) * 6 + 17.5px);
  }
  .inner_xs {
    width: calc((25vw + 15px) * 3 + 17.5px);
  }
  .inner_xs.num-4 {
    width: calc((25vw + 15px) * 4 + 17.5px);
  }
  .inner_xs.num-5 {
    width: calc((25vw + 15px) * 5 + 17.5px);
  }
  .inner_xs.num-6 {
    width: calc((25vw + 15px) * 6 + 17.5px);
  }
  */
  .ctnr_l {
    width: calc(100vw - 65px);
  }
  .ctnr_m {
    width: calc(66vw);
  }
  .ctnr_s {
    width: calc(39vw);
  }
  .ctnr_xs {
    width: calc(25vw);
  }
  .bdr_circle {
    border-radius: 100%;
    margin-bottom: 1px;
  }
  .img_wrap {
    position: relative;
  }
  .img_wrap img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .fitimg {
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .ctnr_l .img_wrap {
    height: 50vw;
  }
  .ctnr_m .img_wrap {
    height: 66vw;
  }
  .ctnr_s .img_wrap {
    height: 39vw;
  }
  .ctnr_xs .img_wrap {
    height: 25vw;
  }
  .con_circle .inner .container .img_wrap:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
  }
  .con_circle .inner .container .tit {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    letter-spacing: -0.03em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .ctnr_m .con_tit {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .box_basic.box_sch {
    margin: 0;
    padding: 0 32.5px 45px;
  }
  .box_sch input[type=text] {
    width: 100%;
    height: 50px;
    background: #f1f1f1;
    border: 0;
    margin-bottom: 10px;
    line-height: 50px;
    padding-left: 45px;
    color: #888;
  }
  .box_sch input[type=text] {
    background-size: 18px 18px;
    background-position: 16px center;
    background-repeat: no-repeat;
  }
  .box_sch input[type=text].ipt_sch {
    background-image: url('/statics/img/ico_sch_sch.png');
  }
  .box_sch input[type=text].ipt_date {
    background-image: url('/statics/img/ico_sch_date.png');
  }
  .box_sch input[type=text].ipt_person {
    background-image: url('/statics/img/ico_sch_person.png');
  }
  ::placeholder {
    font-size: 13px;
    color: #888;
  }
  .box_sch .btn_confirm {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    background: #0e6057;
    display: block;
    text-align: center;
    width: 100%;
    height: 50px;
    line-height: 50px;
  }
  .box_roll {
    padding: 0;
  }
  .box_roll .container {
    position: relative;
  }
  .box_roll .container .img_wrap {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 58vw;
  }
  .box_roll .container.ctnr_dark .img_wrap:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0,0,0,0.6), rgba(0,0,0,0));
  }
  .box_roll .container.ctnr_dark .con_tit {
    font-size: 17px;
    color: #fff;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 1.4em;
    position: absolute;
    left: 32px;
    top: 32px;
    padding-right: 32px;
    transform: none;
    z-index: 1;
    text-align: left;
    letter-spacing: -0.03em;
  }
  .box_roll .pager {
    position: absolute;
    bottom: 10px;
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .box_roll .pager li {
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 10px;
    background: #fff;
    margin: 0 3.5px;
  }
  .box_roll .pager li.on {
    background: #01e1c9;
  }
  .box_roll button {
    font-size: 0.35em !important;
  }
  .box_roll button .q-btn__wrapper {
    padding: 0.35em !important;
  }
  .box_basic .scroller .inner .container {
    display: inline-block;
    position: relative;
  }
  .box_basic .scroller .inner .container .img_wrap {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .box_basic .scroller .inner .container .img_wrap.bdr_circle img {

  }
  .box_basic .scroller .inner .container.ctnr_dark .img_wrap:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0,0,0,0.7), rgba(0,0,0,0));
  }
  .box_basic .scroller .inner .container .con_tit {
    color: #222;
    margin-top: 14px;
    font-weight: 500;
    letter-spacing: -0.03em;
    white-space: normal;
  }
  .box_basic .scroller .inner .container.ctnr_dark .con_tit {
    font-size: unset;
    color: #fff;
    letter-spacing: -0.02em;
    line-height: 1.4em;
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
    z-index: 1;
    white-space: normal;
  }
  .box_basic .scroller .inner .container .sub_txt {
    font-size: 12px;
    color: #888;
    letter-spacing: -0.02em;
    line-height: 1.6em;
    margin-top: 8px;
    max-height: 4.6em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  .box_basic .scroller .inner .container .editor {
    font-size: 12px;
    color: #888;
    font-weight: 500;
    line-height: 1.75em;
    margin-top: 12px;
  }
  .box_basic .scroller .inner .container .editor img {
    width: 20px;
    border-radius: 100%;
    display: inline-block;
    margin-right: 8px;
  }
  .box_basic .scroller .inner .display-container {
    display: inline-block;
    position: relative;
    margin-right: 15px;
  }
  .box_basic .scroller .inner .display-container:last-child {
    margin-right: 32.5px;
  }
  .share {
    text-align: center;
  }
  .share a {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin: 0 5px;
  }
  .share a img {
    width: 100%;
  }
  .ctnr_s .name_kr {
    font-size: 15px;
    color: #222;
    font-weight: 500;
    line-height: 1.3em;
    margin-top: 10px;
    letter-spacing: -0.03em;
    white-space: normal;
  }
  .ctnr_s .name_en {
    font-size: 13px;
    font-weight: 300;
    color: #222;
    line-height: 1.7em;
    white-space: normal;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .btn_more {
    display: inline-block;
    width: 100%;
    color: #222;
    font-weight: 400;
    border: 1px solid #ddd;
    text-align: center;
    padding: 15px;
    margin-top: 20px;
    position: relative;
  }
  .btn_more:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border: solid #000;
    border-width: 1.5px 1.5px 0 0;
  }
  .btn_more.drtn_right:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .btn_more.drtn_down:after {
    transform: translate(-50%, -50%) rotate(135deg);
    margin-top: -2px;
  }
  .lst_cate {
    padding: 25px 0;
    text-align: center;
    margin-bottom: 2px;
    border-bottom: 0;
  }
  .lst_cate a {
    font-size: 12px;
    padding: 10px;
    background: #f2f2f2;
    width: calc((100vw - 65px) / 3);
    display: inline-block;
  }
  .lst_cate a.on {
    font-weight: 700;
    color: #fff;
    background: #222;
  }
  .lst_more_wrap {
    background: #fff;
    padding-top: 5px;
  }
  .lst_more {
    display: block;
    font-size: 13px;
    font-weight: 500;
    color: #222;
    text-align: center;
    padding: 18px;
    position: relative;
    background: #fff;
    margin: 15px 20px;
    box-shadow: 0 5px 5px rgba(0,0,0,0.05);
  }
  .lst_more i {
    font-size: 12px;
    color: #222;
    font-weight: 900;
    display: inline-block;
    position: absolute;
    right: 8.5%;
    transform: rotate(-45deg);
    margin-top: -1px;
  }
  .lst_no {
    display: block;
    color: #888;
    text-align: center;
    padding: 18px;
    position: relative;
    margin: 15px 20px;
  }
  .ctnr_chart {
    padding: 25px 32.5px 25px 142.5px;
    position: relative;
    border-bottom: 8px solid #f2f2f2 !important;
    min-height: unset;
  }
  .ctnr_chart .chart_img {
    width: 85px;
    height: 85px;
    overflow: hidden;
    position: absolute;
    left: 32.5px;
  }
  .ctnr_chart .rank {
    font-size: 14px;
    line-height: 16px;
    width: unset;
    text-align: left;
  }
  .ctnr_chart .rank b {
    font-size: 16px;
    font-weight: 500;
  }
  .ctnr_chart .rank i {
    font-size: 12px;
    color: #888;
    margin-top: 0;
    margin-left: 8px;
    width: 28px;
    display: inline-block;
    text-align: center;
  }
  .ctnr_chart .rank i em {
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
  }
  .ctnr_chart .rank .chkin_rev {
    font-size: 10px;
    font-weight: 500;
    color: #00897a;
    letter-spacing: -0.03em;
    background: #ccf9f4;
    padding: 2px 5px;
    top: 22px;
    bottom: unset;
    right: 32.5px;
  }
  .ctnr_chart .rank .chkin_rev:before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 13px;
    margin-top: 1px;
    margin-right: 4px;
    background: url('/statics/img/ico_pin_badge.png') no-repeat;
    background-size: 10px auto;
  }  
  .ctnr_chart a.contents {
    width: unset;
    border-left: 0;
    padding-left: 0;
  }
  .ctnr_chart .h_name_kr {
    font-size: 16px;
    font-weight: 500;
    margin-top: 12px;
    letter-spacing: -0.03em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .ctnr_chart .h_name_en {
    font-size: 13px;
    font-weight: 300;
    margin-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .ctnr_chart .loc {
    font-size: 12px;
    color: #888;
    margin-top: 14px;
    line-height: 1.6em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    width: 80%;
  }
  .ctnr_chart .loc:before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 18px;
    margin-right: 5px;
    background: url(/statics/img/ico_pin.png) no-repeat;
    background-size: 100% auto;
  }
  .ctnr_chart .loc i {
    position: absolute;
    right: 32.5px;
    bottom: 29px;
    line-height: 1.0em;
  }
  .ctnr_chart .like {
    position: absolute;
    top: 22px;
    right: 30px;
    display: inline-block;
    width: 25px;
    height: 25px;
  }
  .ctnr_chart .like_on:before {
    content: "♥";
    font-size: 25px;
    line-height: 1.0em;
    color: #01e1c9;
  }
  .ctnr_chart .like_off:before {
    content: "♡";
    font-size: 25px;
    line-height: 1.0em;
    color: #ccc;
    font-weight: 900;
  }

  /* 검색 모달 */
  .modal {
    width: 100%;
    height: 100%;
    background: #fff;
    position: relative;
  }
  .modal .header {
    height: 60px;
    font-size: 1.15em;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ddd;
  }
  .modal .header .btn_back {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
  }
  .modal .header .btn_back i {
    position: absolute;
    font-size: 20px;
    color: #888;
  }
  .modal .sect {
    padding: 25px 20px;
    border-bottom: 8px solid #eee;
  }
  .modal .sect input[type=text] {
    width: 100%;
    height: 50px;
    background: #f1f1f1;
    border: 0;
    margin-bottom: 10px;
    line-height: 50px;
    padding-left: 45px;
    font-size: 14px;
    color: #222;
    background-size: 18px 18px;
    background-position: 16px center;
    background-repeat: no-repeat;
  }
  .modal .sect input[type=text].ipt_city {
    background-image: url('/statics/img/ico_sch_sch.png');
  }
  .modal .sect input[type=text].ipt_date {
    background-image: url('/statics/img/ico_sch_date.png');
  }
  .modal .sect input[type=text].ipt_person {
    background-image: url('/statics/img/ico_sch_person.png');
  }
  .modal .sect .btn_confirm {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    background: #0e6057;
    display: block;
    text-align: center;
    width: 100%;
    height: 50px;
    line-height: 50px;
  }

  /* 광고 영역 */
  .lst_ad_wrap {
    display: inline-block;
    width: 100%;
    padding: 20px 0;
    text-align: center;
    border-bottom: 8px solid #f2f2f2;
    background: #f2f2f2;
  }
  .ad_wrap {
    display: inline-block;
    width: 100%;
    margin: 20px 0;
    text-align: center;
  }
  .ad_wrap .modal {
    display: inline-block;
    width: 320px;
  }
  .ad_wrap .toast {
    display: inline-block;
    width: 320px;
  }
  .ad_google {
    display: inline-block;
    font-weight: 700;
    color: #fff;
    background: linear-gradient(to right, #7f45c0, #26dbcc);
  }
  .ad_320x50 {
    width: 320px;
    height: 50px;
    line-height: 50px;
  }
  .ad_320x100 {
    width: 320px;
    height: 100px;
    line-height: 100px;
  }
  .ad_320x480 {
    width: 320px;
    height: 480px;
    line-height: 480px;
  }
  .ad_wrap .btn_wrap {
    background: rgba(0,0,0,0.8);
    text-align: right;
  }
  .ad_wrap .btn_wrap a {
    font-size: 14px;
    color: #fff;
    display: inline-block;
    width: 50%;
    letter-spacing: -0.02em;
    padding: 10px;
  }
  .ad_wrap .btn_wrap .left {
    text-align: left;
    font-weight: 300;
  }
  .ad_wrap .btn_wrap .right {
    text-align: right;
  }
  .banner_gosty {
    margin-bottom: 8px;
  }
  .banner_gosty .pc {
    display: none;
  }
  .banner_gosty .mo {
    display: block;
  }
  .banner_gosty .mo img {
    width: 100%;
    max-width: 375px;
  }
</style>