import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

const loc = JSON.parse(JSON.stringify(window.location))
const firstname = (loc.hostname).substr(0,7) === '192.168' ? loc.hostname
  : loc.hostname.indexOf('test.') > -1 ? 'api.' + (loc.hostname.split('.').slice(-3)).join('.').replace('http://','').replace('https://','')
  : loc.hostname.split('.').length > 1 ? 'api.' + (loc.hostname.split('.').slice(-2)).join('.').replace('http://','').replace('https://','')
  : loc.hostname
Vue.prototype.$hostURL = loc.protocol + '//' + loc.hostname
Vue.prototype.$apiURL = loc.protocol + '//' + firstname + ':8080' + '/v1'

// Vue.prototype.$apiURL = 'https://api.app.prestigegorilla.net:8080/v1'
Vue.axios.defaults.baseURL = Vue.prototype.$apiURL

export default async ({ Vue }) => {
  Vue.prototype.$axios = axios
}
