<template>

  <!-- footer_area -->  
  <footer id="footer" class="footerArea">
  
  <!-- footer -->
  <div class="box_basic footer">
    <p class="menu">
      <a href="http://www.gorilladistrict.com/#about" target="_blank">about</a>
      <a href="http://www.gorilladistrict.com/#service" target="_blank">service</a>
      <a href="http://www.gorilladistrict.com/#contact" target="_blank">contact</a>
    </p>
    <ul>
      <li>㈜고릴라디스트릭트</li>
      <li>서울특별시 성동구 상원6길 8-14 빌라드 고릴라</li>
      <li>대표이사 : 고승훈<i>|</i>사업자등록번호 : 872-87-01176</li>
      <li>광고문의 : contact_p@gorilladistrict.com</li>
      <li>서비스문의 : help@gorilladistrict.com</li>
      <li><a @click="toggleTermsDialog(true);">이용약관</a><i>|</i><a @click="togglePolicyDialog(true);">개인정보 처리방침</a></li>
    </ul>
    <p class="channel">
      <a href="https://www.youtube.com/channel/UCsaJhHWL9GHEKfgdrBbw2YA" target="_blank" class="youtube"></a>
      <a href="https://www.instagram.com/prestigegorilla" target="_blank" class="insta"></a>
      <a href="https://post.naver.com/my.nhn?memberNo=38362259" target="_blank" class="post"></a>
      <a href="https://brunch.co.kr/@prestigegorilla" target="_blank" class="brunch"></a>
    </p>
  </div>

  </footer>
  <!-- //footer_area -->

</template>

<script>
  export default {
    name: "Footer",
    methods: {
      toggleTermsDialog (flag) {
        this.$store.dispatch('setOpenTermsDialog', flag)
      },
      togglePolicyDialog (flag) {
        this.$store.dispatch('setOpenPolicyDialog', flag)
      }
    }
  }
</script>

<style scoped>
  .footerArea {
    background: #fff;
  }
	.footerArea .footer {
		font-size: 13px;
		font-weight: 500;
		color: #888;
		margin: 0 auto;
    border-bottom: 0;
    width: 1220px;
    display: block;
    padding: 50px 0;
	}
	.footerArea .footer .menu {
		margin-bottom: 20px;
	}
	.footerArea .footer .menu a {
		font-size: 14px;
		font-weight: 600;
		color: #222;
		text-transform: uppercase;
		margin-top: -8px;
		margin-right: 30px;
	}
	.footerArea .footer ul li {
		line-height: 1.8em;
		letter-spacing: -0.03em;
    display: inline-block;
    margin-right: 24px;
	}
	.footerArea .footer ul li i {
		display: inline-block;
		margin: 0 12px;
		color: #ccc;
		font-weight: 500;
		line-height: 1.8em;
    text-indent: -9999px;
	}
	.footerArea .footer ul li a {
		color: #888;
	}
	.footerArea .footer ul li:last-child {
		margin-top: 6px;
	}
	.footerArea .footer ul li:last-child i {
		text-indent: inherit;
	}
	.footerArea .footer ul li a:nth-child(3) {
		font-weight: 700;
	}
	.footerArea .footer .channel {
		margin-top: 30px;
	}
	.footerArea .footer .channel a {
		display: inline-block;
		height: 34px;
		background-size: contain;
		margin-right: 30px;
		opacity: 0.4;
	}
	.footerArea .footer .channel .youtube {
		background-image: url('/statics/img/sns_youtube.png');
		width: 30px;
	}
	.footerArea .footer .channel .insta {
		background-image: url('/statics/img/sns_instagram.png');
		width: 28px;
	}
	.footerArea .footer .channel .post {
		background-image: url('/statics/img/sns_n_post.png');
		width: 19px;
	}
	.footerArea .footer .channel .brunch {
		background-image: url('/statics/img/sns_brunch.png');
		width: 24px;
	}
</style>