<template>
  <!-- header_area -->
  <header id="header">

    <div class="header_wrap" ref="header">
      <div style="width: 100vw">

        <!--
				<q-toolbar class="text-white">
          <router-link to="/home" class="q-pl-xs">LOGO</router-link>
          <q-space />
          <q-toolbar-title />
          <q-btn class="hbg_btn" @click="toggleDrawer()" />
        </q-toolbar>
        -->

      </div>
    </div>

    <!-- 하단 고정 메뉴 -->
    <div class="ftr_fixed_menu">
			<router-link to="/chart/hot100" :class="{on: isFooterVisible('ChartView')}">Ranking</router-link>
			<router-link to="/search" :class="{on: isFooterVisible('Search')|| isFooterVisible('SearchHotel') || isFooterVisible('HotelDetail')}">Search</router-link>
			<router-link to="/contents/check-in" :class="{on: isFooterVisible('Contents')	|| isFooterVisible('ContentsDetail') || isFooterVisible('ContentsEditor')}">Contents</router-link>
			<router-link to="/airline" :class="{on: isFooterVisible('Airline') || isFooterVisible('AirlineDetail')}">Flight</router-link>
		</div>

		<transition-group
			appear
			enter-active-class="animated fadeIn"
			leave-active-class="animated fadeOut"
			>
			<div class="{ fixed: fixed }" v-if="
				isHeaderVisible('Search')
				|| isHeaderVisible('Contents')
				|| isHeaderVisible('ContentsEditor')
				|| isHeaderVisible('Video')
				|| isHeaderVisible('VideoDetail')
				|| isHeaderVisible('Airline')
				|| isHeaderVisible('Special')
				|| isHeaderVisible('SearchAll')"
				key="Search || Contents || ContentsEditor || Video || Airline || Special || SearchAll">
				<!-- 상단 고정 - 공통 -->
				<div class="header_bar">
					<a @click="$router.go(-1)" class="btn_back"></a>
					<!--<span class="share" @click="toggleShareDialog(true);"><img src="/statics/img/ico_share.png"></span>-->
					<q-btn unelevated class="hbg_btn" @click="toggleDrawer()" />
				</div>
			</div>
			<div class="{ fixed: fixed }" v-if="
				isHeaderVisible('ChartView')
				|| isHeaderVisible('ContentsDetail')
				|| isHeaderVisible('AirlineDetail')"
				key="ChartView || ContentsDetail || AirlineDetail">
				<!-- 상단 고정 - 차트, 포스트상세, 항공사상세 -->
				<div class="header_bar ct_view">
					<a @click="$router.go(-1)" class="btn_back white"></a>
					<!--<span class="share white" @click="toggleShareDialog(true);"><img src="/statics/img/ico_share.png"></span>-->
					<q-btn unelevated class="hbg_btn" @click="toggleDrawer()" />
				</div>
			</div>
			<div class="{ fixed: fixed }" v-if="
				isHeaderVisibleScroll('ChartView')
				|| isHeaderVisibleScroll('ContentsDetail')
				|| isHeaderVisibleScroll('AirlineDetail')"
				key="">
				<!-- 상단 고정 - 차트, 포스트상세, 항공사상세 스크롤 -->
				<div class="header_bar">
					<a @click="$router.go(-1)" class="btn_back"></a>
					<!--<span class="share" @click="toggleShareDialog(true);"><img src="/statics/img/ico_share.png"></span>-->
					<q-btn unelevated class="hbg_btn" @click="toggleDrawer()" />
				</div>
			</div>
			<div class="{ fixed: fixed }" v-if="isHeaderVisible('SearchHotel')" key="SearchHotel">
				<!-- 상단 고정 - 호텔 리스트 -->
				<div class="header_bar ht_lst">
					<a @click="$router.go(-1)" class="btn_back white"></a>
					<div class="cont">
						<span>{{getSearchHotelQueryKeyword}} 호텔 검색 결과</span>
						<p>...</p>
					</div>
					<div class="btn_rgt">
						<a @click="showSearchHotelModal(true)">변경</a>
					</div>
				</div>
			</div>
			<div class="{ fixed: fixed }" v-if="isHeaderVisible('HotelDetail')" key="HotelDetail">
				<!-- 상단 고정 - 호텔 상세 -->
				<div class="header_bar ht_detail">
					<a @click="$router.go(-1)" class="btn_back"></a>
					<div class="cont">
						<span>{{getCurrentHotel.name}}</span>
						<p>...</p>
					</div>
					<div class="btn_rgt">
						<a @click="showSearchHotelModal(true)">변경</a>
					</div>
				</div>
			</div>
			<div class="{ fixed: fixed }" v-if="isHeaderVisible('Home')" key="Home">
				<!-- 상단 고정 - 홈 -->
				<div class="header_bar">
					<router-link to="/home" class="q-pl-xs bi">logo</router-link>
					<q-btn unelevated class="hbg_btn" @click="toggleDrawer()" />
				</div>
			</div>
			<div class="{ fixed: fixed }" v-if="
				isHeaderVisible('Login')
				|| isHeaderVisible('Mypage')
				|| isHeaderVisible('MypageAccount')
				|| isHeaderVisible('MypageHotel')
				|| isHeaderVisible('MypagePassword')
				|| isHeaderVisible('MypageHelp')
				|| isHeaderVisible('MypageSendmail')"
				key="Login|| JoinBasic || JoinAdd || Mypage || 'MypageAccount	|| MypageHotel || MypagePassword || MypageHelp || MypageSendmail">
				<!-- 상단 고정 - 로그인/마이페이지 -->
				<div class="header_bar">
					<a @click="$router.go(-1)" class="btn_back"></a>
					<!--<span class="share" @click="toggleShareDialog(true);"><img src="/statics/img/ico_share.png"></span>-->
					<q-btn unelevated class="hbg_btn" @click="toggleDrawer()" />
				</div>
			</div>
		</transition-group>

    <q-scroll-observer @scroll="handleScroll" />

		<!--<div style="position: absolute; top: 100px">===>{{getAppPlatform.os}},{{getAppPlatform.version}}<====</div>-->
		<!--<div style="position: absolute; top: 130px">===>{{getAppPlatform.device}},{{getAppPlatform.has_notch}}<====</div>-->

  </header>
  <!-- //header_area -->

</template>

<script>
  import Vue from 'vue'
  import {QToolbar,QToolbarTitle,QBtn,QSpace,QTabs,QRouteTab,QSlideTransition,QScrollObserver,QCarousel,QCarouselControl,QCarouselSlide,QLayout,QHeader,QFooter,QPageContainer,QPage, QDialog} from 'quasar'
  import {Dialog, Popover, Dropdown, DropdownMenu, DropdownItem} from 'element-ui'
  import DisplayContainer from '../components/DisplayContainer'
  import CommonComputed from 'src/_mixins/CommonComputed'
  import CommonMethods from 'src/_mixins/CommonMethods'
  import { mapGetters } from 'vuex'
  export default {
    components: {
      DisplayContainer,
      QToolbar,
      QToolbarTitle,
      QBtn,
      QSpace,
      QTabs, QRouteTab,
      QSlideTransition,
      QScrollObserver,
      QCarousel,
      QCarouselControl,
      QCarouselSlide,
      QLayout,QHeader,QFooter,QPageContainer,QPage
    },
    data () {
      return {
        tab: '/chart',
        slide: 1,
        scrollInfo: {},
        direction: 'down',
        fixed: false,
        loadedCnt: 0,
        headerTopPosition: {
          'ChartView': 360,
          'ContentsDetail': 190,
          'AirlineDetail': 190
        }
      }
    },
    mounted () {

    },
    computed: {
      ...CommonComputed,
      ...mapGetters([
        'getCurrentHotel',
        'getSearchHotelQuery',
        'getSearchHotelQueryKeyword',
				'getAppPlatform'
      ]),
      getAccess () {
        return 'app'
      }
    },
    methods: {
      ...CommonMethods,
      handleScroll (info) {
        this.scrollInfo = info
        this.direction = info.directionChanged ? info.position > info.inflexionPosition ? 'down' : 'up' : info.position < this.getTargetPosition() ? 'down' : this.direction  // ios 13 scroll bounce issue fixed
        // console.log(info.directionChanged, info.position, info.inflexionPosition, info.direction, this.direction )
        this.fixed = info.position > this.$refs.header.offsetTop

        // 카드상세화면 하단고정영역 노출 시 토스트배너 축소
        if (!this.isToastBannerSmallSize) {
          if (this.isCurrentRouteName('HotelDetail') && this.headerTopPosition.HotelDetail < info.position) {
            this.$store.dispatch('setToastBannerSmallSize', true)
          }
        }
      },
      isCurrentRouteName (name) {
        return this.getRouteName === name
      },
      isHeaderVisible (name) {
        return this.isCurrentRouteName(name)
      },
      isHeaderVisibleScroll (name) {
        return this.isCurrentRouteName(name)
					&& this.fixed
          && this.headerTopPosition[name] < this.scrollInfo.position
      },
      isFooterVisible (name) {
        return this.isCurrentRouteName(name)
      },
      toggleDrawer () {
        this.$emit('drawer')
      },
      getTargetPosition (name) {
        name = name ? name : this.getRouteName
        const target = this.headerTopPosition[name]
        const targetPosition = target ? target : this.headerTopPosition.COMMON
        return targetPosition
      },
      toggleShareDialog (flag) {
        this.$store.dispatch('setOpenShareDialog', flag)
      },
			showSearchHotelModal (flag) {
        this.$refs.modal.setSearchDialog(flag)
			},
      searchHotelModal (params) {
        if (params) {
          const params = {
            params: {
              keyword: this.getCurrentHotel.ota_entity_key,
              checkin: this.getSearchHotelQuery.checkin,
              checkout: this.getSearchHotelQuery.checkout,
              rooms: this.getSearchHotelQuery.room
            }
          }
          this.$axios.get(`/hotels/ota/single_hotel_search`, params).then(rs => {
            const hotel = this.cloneData(this.getCurrentHotel)
            hotel.ota_data_realtime = rs.data
            this.$store.dispatch('setCurrentHotel', hotel)
          })
        }
			}
    }
  }
</script>

<style>
  .q-toolbar {
    height: 60px;
		padding: 0 5px;
    /*z-index: 4;*/
  }
  .q-toolbar a img {
    width: 175px;
    height: auto;
  }
  .q-pt-sm { padding-top: 4px !important;}
  .inner {
    max-width: 100vw !important;
  }
  .fixed {
    z-index: 1002;
    top: 0 !important;
  }
  .header_wrap {
    width: 100vw;
    height: auto !important;
    min-height: 60px !important;
    position: fixed;
		top: 0;
		z-index: 1002;
    background: none;
  }
	.bg-primary {
		background: #fff !important;
	}
  .header_wrap .gnb {
    background-color: #ededed;
  }
  .header_wrap .gnb:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 40px;
    background: #fff;
  }
  .header_wrap .gnb .q-tab__label {
    font-size: 15px;
    font-weight: 700;
    letter-spacing: -0.5px;
  }
  .q-mt-sm, .q-mr-sm {
    margin: 0 !important;
  }
	.q-btn.hbg_btn {
		box-shadow: none;
		background-image: url('/statics/img/ico_hbg.png');
		background-size: 25px 19.5px;
		background-repeat: no-repeat;
		background-position: center;
		padding: 0;
		width: 60px;
		height: 60px;
		min-height: unset;
		position: absolute;
		right: 0;
	}

  /* 상단 고정 - 기본 */
  .logo_short {
    width: 34px;
    overflow: hidden;
  }
  .header_wrap.transform {
    width: 100vw;
    background-color: unset !important;
    min-height: unset !important;
  }
  .header_wrap.transform .card_ranking_toolbar {
    background-color: unset !important;
  }
  .header_wrap.transform .card_ranking_toolbar .sBox {
    background-color: #f1f1f1 !important;
    border-radius: 3px;
  }
  .header_wrap.transform .card_ranking_toolbar .lst .num {
    width: 20px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .header_wrap.transform .card_ranking_toolbar .lst .name {
    width: 48vw !important;
    max-width: 48vw !important;
  }
	.header_bar .bi {
		display: inline-block;
		width: 220px;
		height: 40px;
		margin-left: 20px;
    background-image: url('/statics/img/bi_main.png');
		background-size: 100% auto;
    background-position: left center;
		text-indent: -9999px;
  }

	/* 상단 고정 - 공통 */
	.header_bar {
		position: fixed;
		width: 100%;
		height: 60px;
		display: flex;
		align-items: center;
		background: rgba(255,255,255,0.9);
    border-bottom: 0;
	}
	.header_bar .btn_back {
		width: 60px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
    z-index: 10;
	}
	.header_bar .btn_back:after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		display: inline-block;
		width: 12px;
		height: 12px;
		border: solid #000;
		border-width: 0 0 1.5px 1.5px;
		transform: translate(-50%, -50%) rotate(45deg);
	}
	.header_bar .btn_back.white:after {
		border: solid #fff;
		border-width: 0 0 1.5px 1.5px;
	}
	.header_bar .btn_back i {
		font-size: 18px;
		font-weight: 500;
		font-style: normal;
		transform: rotate(45deg);
		display: inline-block;
		margin-left: 5px;
	}
	.header_bar .btn_rgt {
		width: 60px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: 0;
	}
	.header_bar .btn_rgt .hbg {
		width: 25px;
		height: 19.5px;
		background-image: url('/statics/img/ico_hbg.png');
		background-size: 25px 19.5px;
		position: relative;
		right: 5px;
	}
	.header_bar .btn_rgt a {
		color: #222;
	}
	.header_bar .share {
		width: 50px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: 60px;
	}
	.header_bar .share img {
		width: 24px;
		height: 24px;
		filter: brightness(0.1);
	}
	.header_bar .share.white img {
		filter: brightness(1.0);
	}

	/* 상단 고정 - 차트 */
	.header_bar.ct_view {
		width: 100%;
		background: rgba(0,0,0,0.0);
	}
	.header_bar.ct_view .btn_back i {
		color: #fff;
	}
	.header_bar.ct_view .q-btn.hbg_btn {
		background-image: url('/statics/img/ico_hbg.png');
		filter: brightness(100);
	}

	/* 상단 고정 - 호텔 리스트 */
	.header_bar.ht_lst {
		width: 100%;
		background: #00897a;
	}
	.header_bar.ht_lst .btn_back i {
		color: #fff;
	}
	.header_bar.ht_lst .cont {
    position: absolute;
		width: 100vw;
    text-align: center;
	}
	.header_bar.ht_lst .cont span {
		font-size: 16px;
		font-weight: 500;
		color: #fff;
    line-height: 1.0em;
		letter-spacing: -0.05em;
		max-height: 1.6em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
	}
	.header_bar.ht_lst .cont p {
		font-size: 12px;
		font-weight: 500;
		color: rgba(255,255,255,0.7);
    margin-left: 0;
    display: none;
	}
	.header_bar.ht_lst > .btn_rgt {
		font-size: 14px;
		font-weight: 400;
		position: absolute;
		right: 20px;
		padding: 8px 12px;
		border: 1px solid rgba(255,255,255,0.5);
		letter-spacing: -0.05em;
		width: unset;
		height: unset;
    display: none;
	}
	.header_bar.ht_lst .btn_rgt a {
		color: #fff;
	}

	/* 상단 고정 - 호텔 상세 */
	.header_bar.ht_detail {
		position: relative;
		height: 60px;
		background: #fff;
		display: flex;
		align-items: center;
		width: 100vw;
	}
	.header_bar.ht_detail .cont {
    position: absolute;
    width: 100vw;
		max-width: 100%;
    text-align: center;
    line-height: 1.0em;
	}
	.header_bar.ht_detail .cont span {
		font-size: 16px;
		font-weight: 500;
		color: #222;
    line-height: 1.2em;
		letter-spacing: -0.05em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
	}
	.header_bar.ht_detail .cont p {
		font-size: 12px;
		font-weight: 500;
		color: #888;
	}
	.header_bar.ht_detail .btn_rgt {
		font-size: 14px;
		font-weight: 400;
		position: absolute;
		right: 20px;
		padding: 8px 12px;
		border: 1px solid #ccc;
		letter-spacing: -0.05em;
		width: unset;
		height: unset;
    display: none;
	}
	.header_bar.ht_detail .btn_rgt a {
		color: #222;
	}

	/* 하단 고정 메뉴 */
	.ftr_fixed_menu {
		width: 100%;
		height: 60px;
		border-top: 1px solid #ddd;
		position: fixed;
		bottom: 0;
		background: #fff;
		z-index: 1002;
		text-align: center;
	}
	.ftr_fixed_menu a {
		display: inline-block;
		width: 24%;
		height: 30px;
		margin: 6px 0;
		padding-top: 32px;
		background-size: auto 84%;
		background-repeat: no-repeat;
		background-position: center;
		font-size: 10px;
		color: #777;
		font-weight: 500;
		text-align: center;
	}
	.ftr_fixed_menu a.on {
		font-weight: 700;
    color: #00897a;
	}
	.ftr_fixed_menu a:nth-child(1) {
		background-image: url('/statics/img/ico_ftr_fixed_ranking.png');
	}
	.ftr_fixed_menu a:nth-child(2) {
		background-image: url('/statics/img/ico_ftr_fixed_search.png');
	}
	.ftr_fixed_menu a:nth-child(3) {
		background-image: url('/statics/img/ico_ftr_fixed_contents.png');
	}
	.ftr_fixed_menu a:nth-child(4) {
		background-image: url('/statics/img/ico_ftr_fixed_flight.png');
	}
	.ftr_fixed_menu a.on:nth-child(1) {
		background-image: url('/statics/img/ico_ftr_fixed_ranking_on.png');
	}
	.ftr_fixed_menu a.on:nth-child(2) {
		background-image: url('/statics/img/ico_ftr_fixed_search_on.png');
	}
	.ftr_fixed_menu a.on:nth-child(3) {
		background-image: url('/statics/img/ico_ftr_fixed_contents_on.png');
	}
	.ftr_fixed_menu a.on:nth-child(4) {
		background-image: url('/statics/img/ico_ftr_fixed_flight_on.png');
	}
</style>
