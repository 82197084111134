import Vue from 'vue'
export default {
  unload ($event) {
    let confirmationMessage = "\o/";
    $event.returnValue = confirmationMessage
    return confirmationMessage
  },
  cloneData (original) {
    const data = JSON.parse(JSON.stringify(original))
    return data instanceof Array ? Object.assign([], data)
      : data instanceof Object ? Object.assign({}, data)
      : data
  },
  deepCopy (original) {
    let data = {}
    if (original instanceof Object) {
      Object.keys(original).map(key => {
        data[key] = JSON.parse(JSON.stringify(original[key]))
      })
    } else {
      data = this.cloneData(original)
    }
    console.log(data)
    return data
  },
  isFileExist (obj) {
    return obj && obj.url ? true : false
  },
  getFileExist (obj) {
    if (obj && obj.constructor === String) {
      obj = { url: Vue.prototype.$apiURL.replace('/v1','') + obj }
    }
      // obj.url.substr(0,7) === 'http://' ? '' : Vue.prototype.$apiURL.replace('/v1','')
      // : ''
    // console.log(domain, obj.url, Vue.prototype.$apiURL)
    return obj && obj.url ? obj.url : ''
  },
  getFileUrl (path) {
    return Vue.prototype.$apiURL.replace('/v1','') + path
  },
  getFileExistOld (obj) {
    return obj && obj.length>0 ? obj[0].url : this.getFileExist(obj)
  },
  getFileList (obj) {
    return this.isFileExist(obj) ? [obj] : []
  },
  getImgOrientation (file, obj) {
    let img = new Image()
    img.addEventListener("load", function () {
      file.orientation = img.width > img.height ? 'landscape' : 'portrait'
      obj = file
    })
    img.src = file.url
  },
  chunk (data, chunk_size) {
    if ( !data.length ) {
      return []
    }
    return [ data.slice( 0, chunk_size ) ].concat(this.chunk(data.slice(chunk_size), chunk_size))
  },
  getTextCroped(txt, limit, tail) {
    return txt ? this.getStripHtml(txt).substr(0, limit) + tail : ''
  },
  getStripHtml (txt) {
    let div = document.createElement('div')
    div.innerHTML = txt
    return div.textContent || div.innerText || ''
  },
  dataToJsonParse (data) {
    return data ? JSON.parse(data) : data
  },
  getMathAbs (v) {
    return Math.abs(v)
  },
  removeFroalaAlertText (text) {
    const froalaText = vm.$store.getters.getFroalaAlertText
    return text ? text.replace(froalaText, '') : ''
  },
  getVideo (url) {
    const arr = url.split('/')
    const new_url = `https:////www.youtube.com/embed/${arr[arr.length-1]}`
    return new_url
  },
  // getLastMonthDate (chart) {
  //   const dt = new Date()
  //   const rtn = chart === 'top100' || chart === 'check100'
  //     ? `${dt.getFullYear()}.${dt.getMonth()+1}.${dt.getDate()}`
  //     : `${dt.getFullYear()}.${dt.getMonth()}.15`
  //   return rtn
  // }
}
