<script>
  import Footer from 'src/pc/components/Footer'
  export default {
    extends: Footer
  }
</script>

<style scoped>
	.footerArea .footer {
		font-size: 11px;
		font-weight: 500;
		color: #aaa;
		margin-top: -8px;
    margin-bottom: 50px;
    border-bottom: 0;
	}
	.footerArea .footer .menu {
		margin-bottom: 20px;
	}
	.footerArea .footer .menu a {
		font-size: 13px;
		font-weight: 600;
		color: #aaa;
		text-transform: uppercase;
		margin-top: -8px;
		margin-right: 20px;
	}
	.footerArea .footer ul li {
		line-height: 1.8em;
		letter-spacing: -0.03em;
    display: block;
	}
	.footerArea .footer ul li i {
		display: inline-block;
		margin: 0 5px;
		color: #ccc;
		font-weight: 400;
		line-height: 1.8em;
	}
	.footerArea .footer ul li a {
		color: #aaa;
	}
	.footerArea .footer ul li:last-child {
		margin-top: 6px;
	}
	.footerArea .footer ul li a:nth-child(3) {
		font-weight: 700;
	}
	.footerArea .footer .channel {
		margin-top: 30px;
	}
	.footerArea .footer .channel a {
		display: inline-block;
		height: 34px;
		background-size: contain;
		margin-right: 30px;
		opacity: 0.3;
	}
	.footerArea .footer .channel .youtube {
		background-image: url('/statics/img/sns_youtube.png');
		width: 30px;
	}
	.footerArea .footer .channel .insta {
		background-image: url('/statics/img/sns_instagram.png');
		width: 28px;
	}
	.footerArea .footer .channel .post {
		background-image: url('/statics/img/sns_n_post.png');
		width: 19px;
	}
	.footerArea .footer .channel .brunch {
		background-image: url('/statics/img/sns_brunch.png');
		width: 24px;
	}
</style>