<script>
  import SubFooter from 'src/pc/components/SubFooter'
  import DisplayContainer from './DisplayContainer'
  export default {
    extends: SubFooter,
    components: {
      DisplayContainer
    }
  }
</script>

<style scoped>
  .ad_bottom {
    padding-top: 120px;
    background: #fff;
    margin-top: -8px;
    display: none;
  }
</style>

<style>
  .ad_bottom {
    /* height: 200px; */
  }
  .ad_bottom > div {
    width: 100% !important;
    height: auto !important;
  }
  .ad_bottom .display-container a > img {
    max-width: 320px !important;
  }
</style>