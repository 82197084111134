export default {
  hotelRegionData: [],
  hotelHashTagData: [],
  listOfCalledAPI: [],
  displayCateData: [],
  displayData: [],
  pgChartData: [],
  chartData: [],
  airlineCorpData: [],
  pageYOffset: 0,
  smallToastBanner: false,
  currentChartTitle: '',
  openSearchHotelDialog: {
    main: false,
    keyword: false,
    region: false,
    hashTags: false
  },
  openAllChartDialog: false,
  openShareDialog: false,
  openTermsDialog: false,
  openPolicyDialog: false,
  currentCalendarLabel: '',
  openCalendarDialog: false,
  currentContentsData: {},
  openTeamDropdown: false,
  froalaAlertText: '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a>',
  searchHotelQuery: {},
  searchHotelQueryOrigin: {
    keyword: '',
    cate: '',
    region: [],
    hashTags: [],
    isReview: false
  },
  searchHotelResult: {},
  searchHotelResultOrigin: {
    condition: '',
    data: [],
    total: 0,
    perPage: 30,
    currentPage: 1,
  },
  days: ['일', '월', '화', '수', '목', '금', '토'],
  calendarForYear: [],
  currentHotel: {},
  loginUserData: null,
  activatedRoutes: [
    'SearchHotel'
  ],
  appPlatform: {
    os: null,
    version: null,
    device: null,
    has_notch: false
  }
}
