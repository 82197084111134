<template>

  <div class="display-container"
       :style="`min-height: ${getHeight}; min-width: ${minWidth}; ${getWidth ? 'width: ' + getWidth : ''}; `"
       :class="[`${getEmptyBgColor} ${getFullHeight} relative-position`, !noOverflowHidden ? 'display-container' : '' ]"
       style="outline: 0 solid red"
       v-if="displayData.length > 0">

    <slot name="header" :component="getThis" />

    <div :class="`${getFullHeight}`" :style="`${getBodyMinHeight}`">
      <q-slide-transition>
        <q-carousel v-if="displayData.length > 0"
                    v-model="slide"
                    :autoplay="getInterval"
                    :height="getHeight"

                    :transition-prev="getEffect.prev"
                    :transition-next="getEffect.next"

                    infinite
                    animated
                    :control-color="getControlColor"

                    :arrows="getArrows"
                    :navigation="getNavigation"
                    :swipeable="getSwipeable"

                    :class="`${getFullHeight} ${getAlign} ${getStyleOverflowVisible} ${bodyClasses}`"

        >
          <template v-slot:navigation-icon="{ active, btnProps, onClick }">
            <q-btn v-if="active" size="1px" :icon="btnProps.icon" class="on" flat round dense @click="onClick" />
            <q-btn v-else size="1px" :icon="btnProps.icon" color="white" flat round dense @click="onClick" />
          </template>

          <q-carousel-slide :name="index+1" v-for="(dp, index) in displayData" :key="index" :class="`no-padding ${getAlign} ${getFullHeight} ${slideDisplay}`" class="relative-position">

            <div v-if="noAutoLink" class="full-width full-height">
              <div v-if="dp.dp_type === 'ORIGINAL' || dp.dp_type === 'THUMB'" @click="clickedAD(dp)">
                <slot :row="dp" :component="getThis"></slot>
              </div>
              <div v-else-if="dp.dp_type === 'BANNER'" class="full-width">
                <a @click="clickedAD(dp)"><img :src="getImage(dp)" :style="getImgSizeByStyle(dp)" /></a>
              </div>
              <div v-else-if="dp.dp_type === 'HTML'"
                   v-html="dp.dp_html"
                   class="full-width full-height flex_center"
                   :style="`min-height: ${minHeight}`"
                   @click="clickedAD(dp)">
              </div>
            </div>

            <router-link v-else-if="isUseRouterLink(dp)" :to="getLinkUrl(dp)" :target="getLinkTarget(dp)" class="full-width full-height">
              <div v-if="dp.dp_type === 'ORIGINAL' || dp.dp_type === 'THUMB'" @click="clickedAD(dp)">
                <slot :row="dp" :component="getThis"></slot>
              </div>
              <div v-else-if="dp.dp_type === 'BANNER'" class="full-width">
                <a @click="clickedAD(dp)"><img :src="getImage(dp)" :style="getImgSizeByStyle(dp)" /></a>
              </div>
              <div v-else-if="dp.dp_type === 'HTML'"
                   v-html="dp.dp_html"
                   class="full-width full-height flex_center"
                   :style="`min-height: ${minHeight}`"
                   @click="clickedAD(dp)">
              </div>
            </router-link>

            <a v-else :href="getLinkUrlATag(dp)" :target="getLinkTarget(dp)">
              <div v-if="dp.dp_type === 'ORIGINAL' || dp.dp_type === 'THUMB'" @click="clickedAD(dp)">
                <slot :row="dp" :component="getThis"></slot>
              </div>
              <div v-else-if="dp.dp_type === 'BANNER'" class="full-width">
                <a @click="clickedAD(dp)"><img :src="getImage(dp)" :style="getImgSizeByStyle(dp)" /></a>
              </div>
              <div v-else-if="dp.dp_type === 'HTML'"
                   v-html="dp.dp_html"
                   class="full-width full-height flex_center"
                   :style="`min-height: ${minHeight}`"
                   @click="clickedAD(dp)">
              </div>
            </a>

            <div v-if="(index+1) === slide" style="height: 0">
              <img :src="getExposedADUrl(dp)" width="0" height="0" style="max-width: 0; max-height: 0" v-show="1===0">
            </div>

          </q-carousel-slide>
        </q-carousel>
      </q-slide-transition>
    </div>

    <slot name="footer" :component="getThis" />

  </div>

</template>

<script>
  import { QCarousel, QCarouselControl, QCarouselSlide, QSlideTransition } from 'quasar'
  import CommonMethods from 'src/_mixins/CommonMethods'
  export default {
    components: {
      QCarousel,
      QCarouselControl,
      QCarouselSlide,
      QSlideTransition,
    },
    props: {
      section: {
        default: 0
      },
      width: {
        default: ''
      },
      height: {
        default: ''
      },
      align: {
        default: 'center'
      },
      minWidth: {
        default: ''
      },
      minHeight: {
        default: ''
      },
      slideDisplay: {
        default: 'flex_center'
      },
      bodyMinHeight: {
        default: ''
      },
      bodyClasses: {
        default: ''
      },
      emptyBgColor: {
        default: ''
      },
      noAutoLink: {
        default: false
      },
      useInteractiveImage: {
        default: false
      },
      noOverflowHidden: {
        default: false
      },
      duration: 0,
      arrows: false,
      navigation: false,
      swipeable: false,
      control_color: {
        default: 'secondary'
      },
      vertical: false,
      autoplay: true,
      advertising: false,
      visible: false,
    },
    data () {
      return {
        displayData: [],
        displayCate: {},
        currentDisplayRow: null,
        slide: 0,
        effect: {
          show: 'fade',
          hide: 'fade',
          prev: 'fade',
          next: 'fade'
        },
        eventExposedAD: false,
        isActivated: false
      }
    },
    watch: {
      '$route': 'init'
    },
    beforeMount () {
      this.init()
      this.triggerExposedADUrl()
    },
    activated () {
      this.isActivated = true
      this.triggerExposedADUrl()
    },
    deactivated () {
      this.isActivated = false
    },
    computed: {
      getAccess () {
        return 'app'
      },
      getAdViewUrl () {
        // return 'https://teralog.techhub.co.kr/imp?la_gc=TR8703000270&la_src=ib&la_cnfg='  // 테라 실계정 Mobile
        // return 'https://teralog.techhub.co.kr/imp?la_gc=TR8703000270&la_src=ib&la_cnfg='  // 테라 테스트계정 Mobile
        // return 'https://teralog.techhub.co.kr/imp?la_gc=CP4B3643229850&la_src=ib&la_cnfg=' // 테라 실계정 PC
        // return 'https://teralog.techhub.co.kr/imp?la_gc=TR8703005279&la_src=ib&la_cnfg=' // 테라 테스트계정 PC
      },
      getThis () {
        return this
      },
      getAlign () {
        return this.align === 'left' || this.align === 'right' ? 'text-'+this.align
          : this.align === 'top' || this.align === 'bottom' ? 'absolute-'+this.align
          : 'text-center'
      },
      getFullHeight () {
        return this.align !== 'top' && this.align !== 'bottom' ? 'full-height' : ''
      },
      getDuration () {
        // console.log(this.displayCate)
        return this.displayCate.duration ? this.displayCate.duration : this.duration
      },
      getEmptyBgColor () {
        // console.log(this.emptyBgColor)
        return this.displayData.length < 1
          ? this.emptyBgColor ? this.emptyBgColor : 'bg-grey-2'
          : ''
      },
      getCurrentDisplayDataCnt () {
        return this.displayData.length
      },
      getCurrentDisplayRow () {
        return this.currentDisplayRow && Object.keys(this.currentDisplayRow).length > 0 ? this.currentDisplayRow : null
      },
      getExclusionCodeAdChannel () {
        return 'A' //this.getAccess === 'mobile' ? 'P' : 'M'
      },
      getControlColor () {
        return this.displayCate.control_color ? this.displayCate.control_color : this.control_color
      },
      getArrows () {
        return this.displayCate.arrows ? this.displayCate.arrows : this.arrows
      },
      getNavigation () {
        return this.displayCate.navigation ? this.displayCate.navigation : this.navigation
      },
      getSwipeable () {
        return this.displayCate.swipeable ? this.displayCate.swipeable : this.swipeable
      },
      getEffect () {
        let effect = this.cloneData(this.effect)
        if (this.displayCate.effect) {
          if (this.displayCate.effect.json) {
            if (this.displayCate.effect.json.show) effect.show = this.displayCate.effect.json.show
            if (this.displayCate.effect.json.hide) effect.hide = this.displayCate.effect.json.hide
          } else {
            this.displayCate.effect.json = {}
          }
          effect.prev = this.displayCate.effect.json.prev
          effect.next = this.displayCate.effect.json.next
        }
        // console.log(effect)
        return effect
      },
      getStyleOverflowVisible () {
        // console.log(this.getEffect.prev)
        return this.getEffect.prev === 'rotate' || this.noOverflowHidden ? 'overflow_visible' : ''
      },
      getSlideIndex () {
        // console.log(this.displayData.length)
        return this.slide = this.getRandomIndex ? this.getRandomIndex : 1
      },
      getWidth () {
        return this.displayCate.width ? this.displayCate.width : this.width ? this.width : ''
      },
      getHeight () {
        return this.displayCate.height ? this.displayCate.height : this.height ? this.height : ''
      },
      getBodyMinHeight () {
        return this.bodyMinHeight ? 'min-height: '+this.bodyMinHeight : ''
      },
      getRandomIndex () {
        return Math.floor(Math.random() * (this.displayData.length - 1 + 1)) + 1
      },
      getInterval () {
        return this.displayCate.interval ? Math.abs(this.displayCate.interval) : false
      },
      getSectionTitle () {
        return this.displayCate.title ? this.displayCate.title : ''
      },
      getSectionSubTitle () {
        return this.displayCate.subtitle ? this.displayCate.subtitle : ''
      },
      getSectionInitial () {
        return this.displayCate.initial ? this.displayCate.initial : ''
      },
      getSectionInitialFirstCharacter () {
        return this.getSectionInitial.substr(0,1)
      },
      getInteractivePostfix () {
        return this.useInteractiveImage ? '_interactive' : ''
      },
      getIsActivated () {
        return this.isActivated
      }
    },
    methods: {
      ...CommonMethods,
      init () {
        if (!this.getInterval) {
          this.getDisplayCate()
          this.getDisplayData()
        }
      },
      getDisplayCate () {
        const cnt =  this.$store.getters.getDisplayCateDataCnt
        if (cnt) {
          this.getCurrentDisplayCate()
        } else {
          this.getDisplayCateDataFormDB()
        }
      },
      getDisplayCateDataFormDB () {
        if (this.section > 0) {
          const action = '/display_cates'
          if (!this.$store.getters.isExistListOfCalledAPI(action)) {
            this.$store.dispatch('putListOfCalledAPI', action).then(() => {
              this.$axios.get(action).then((rs) => {
                this.$store.dispatch('setDisplayCateData', rs.data.value).then(() => {
                  this.getCurrentDisplayCate()
                })
              }).catch(() => {})
            })
          } else {
            setTimeout(function() {
              this.getDisplayCate()
            }.bind(this), 100)
          }
        }
      },

      getCurrentDisplayCate () {
        const data = this.$store.getters.getDisplayCateData
        this.displayCate = this.getDisplayCateFlat(data).find(row => row.idx === this.section)
        // console.log('init', this.displayCate.name)
      },
      getDisplayData () {
        const cnt =  this.$store.getters.getDisplayDataCnt
        if (cnt) {
          this.getCurrentDisplayData()
        } else {
          this.getDisplayDataFromDB()
        }
      },
      getDisplayDataFromDB () {
        if (this.section > 0) {
          let params = {params: {access: this.getAccess}}
          if (this.advertising) params.params.is_advertising = true
          const action = '/displays'
          if (!this.$store.getters.isExistListOfCalledAPI(action)) {
            this.$store.dispatch('putListOfCalledAPI', action).then(() => {
              this.$axios.get(action, params).then((rs) => {
                this.$store.dispatch('setDisplayData', rs.data).then(() => {
                  this.getCurrentDisplayData()
                })
              }).catch(() => {})
            })
          } else {
            setTimeout(function() {
              this.getDisplayData()
            }.bind(this), 0)
          }
        }
      },
      getCurrentDisplayData () {
        const data = this.$store.getters.getDisplayData
        this.displayData = data.reduce((acc, cur) => {
          if (cur.section && cur.section.idx === this.section) acc.push(cur)
          return acc
        }, [])
        this.slide = this.getSlideIndex
        if (this.displayData.length > 0 && this.displayData[this.slide-1]) this.currentDisplayRow = this.cloneData(this.displayData[this.slide-1])
      },
      getDisplayCateFlat (obj) {
        return obj.reduce((acc, cur) => {
          acc.push(cur)
          cur.sections.map(cur2 => {
            acc.push(cur2)
            if (cur2.sections) cur2.sections.map(cur3 => {
              acc.push(cur3)
              // if (cur3.sections) cur2.sections.map(cur4 => {
              //   acc.push(cur4)
              //   if (cur4.sections) cur2.sections.map(cur5 => {
              //     acc.push(cur5)
              //   })
              // })
            })
          })
          return acc
        }, [])
      },
      isDpTypeTemplate (dp) {
        return dp.dp_type === 'ORIGINAL' || dp.dp_type === 'THUMB'
      },
      isDpTypeBanner (dp) {
        return dp.dp_type === 'BANNER'
      },
      getImage (dp, gb='_top') {
        return dp.dp_type === 'ORIGINAL'
          ? this.getImageSrcIfExist(dp, gb)
          : this.getFileExist(dp[this.getAccess+'_img' + this.getInteractivePostfix])
      },
      getImageInteractive (dp) {
        return this.getFileExist(dp[this.getAccess+'_img_interactive'])
      },
      getImageSrcIfExist (dp, gb='_top') {
        return (dp.model === 'hotels' && dp.model_data) ? dp.model_data.main_img.url
          : (dp.model === 'flights' && dp.model_data) ? dp.model_data[`${gb}_img`] ? dp.model_data[`${gb}_img`].url : 'about:blank'
          : (dp.model === 'videos' && dp.model_data) ? dp.model_data.thumbnail
          : (dp.model === 'posts' && dp.model_data) ? dp.model_data[`post_img`] ? dp.model_data[`post_img`].url : 'about:blank' // post 는 top 이미지가 아닌 섬네일 이미지로 고정(프고 요청 21/7/12)
          : ''
      },
      getImgSizeByStyle (dp) {
        const size = dp[this.getAccess+'_img_size']
        const width = size.width ? size.width+'px' : 'auto'
        const height = size.height ? size.height+'px' : this.getHeight ? this.getHeight : '100%'
        return `width: ${width}; height: ${height}`
      },
      getName (dp) {
        return (dp.model === 'hotels' && dp.model_data) ? dp.model_data.name
          : (dp.model === 'flights' && dp.model_data) ? dp.model_data.name
          : (dp.model === 'videos' && dp.model_data) ? dp.model_data.title
          : (dp.model === 'posts' && dp.model_data) ? dp.model_data.title
          : ''
      },
      getNameEng (dp) {
        return (dp.model_data) ? dp.model_data.name_eng : ''
      },
      getTitle (dp) {
        return dp && dp.dp_title && dp.dp_title !== '' ? dp.dp_title
          : (dp && dp.model === 'hotels' && dp.model_data) ? dp.model_data.name
          : (dp && dp.model === 'flights' && dp.model_data) ? dp.model_data.name
          : (dp && dp.model === 'videos' && dp.model_data) ? dp.model_data.title
          : (dp && dp.model === 'posts' && dp.model_data) ? dp.model_data.title
          : ''
      },
      getText1 (dp) {
        return dp.dp_text1
      },
      getText2 (dp) {
        return dp.dp_text2
      },
      getCate (dp) {
        return (dp.model === 'hotels' && dp.model_data) ? dp.model_data.cate
          : (dp.model === 'flights' && dp.model_data) ? 'flight'
          : (dp.model === 'videos' && dp.model_data) ? 'video'
          : (dp.model === 'posts' && dp.model_data) ? dp.model_data.post_cate.name
          : ''
      },
      getType (dp) {
        return (dp.model === 'posts' && dp.model_data) ? dp.model_data.type
          : ''
      },
      getCateIntial (dp) {
        return (dp.model === 'posts' && dp.model_data) ? dp.model_data.post_cate.initial : ''
      },
      getCateEng (dp) {
        return (dp.model === 'posts' && dp.model_data) ? dp.model_data.post_cate.name_eng : ''
      },
      getWriter (dp) {
        return (dp.model === 'posts' && dp.model_data) ? dp.model_data.writer.nick : 'Prestige Gorilla'
      },
      getWriterProfImage (dp) {
        return (dp.model === 'posts' && dp.model_data && dp.model_data.writer.prof_img) ? dp.model_data.writer.prof_img.url : ''
      },
      getRegDate (dp) {
        return dp.model === 'posts' && dp.model_data && dp.model_data.reg_dt ? dp.model_data.reg_dt : ''
      },
      getDpRegDate (dp) {
        return dp.reg_dt ? dp.reg_dt : ''
      },
      getTags (dp) {
        return dp.model === 'posts' && dp.model_data && dp.model_data.tags ? dp.tags : []
      },
      getContents (dp) {
        return this.getTextCroped(dp.contents)
      },
      attachaccess (val) {
        return val + '_' + this.getAccess
      },
      getLinkUrl (dp) {
        // if (this.noAutoLink) return ''
        // alert(dp.model)
        const url = dp[this.attachaccess('link_url')]
          ? dp[this.attachaccess('link_url')]
          : (dp.model == 'hotels' && dp.model_data) ? '/hotel/detail/'+dp.model_data.idx
          : (dp.model == 'flights' && dp.model_data) ? '/airline/detail/'+dp.model_data.idx
          : (dp.model == 'videos' && dp.model_data) ? '/video/detail/'+dp.model_data.idx
          : (dp.model === 'posts' && dp.model_data) ? '/contents/detail/'+dp.model_data.idx
          : ''
        return url
      },
      getLinkUrlATag (dp) {
        let url = this.getLinkUrl(dp)
        return url ? url : 'javascript:'
      },
      getLinkTarget (dp) {
        return dp[this.attachaccess('link_target')]
      },
      isUseRouterLink (dp) {
        // console.log(this.getLinkUrl(dp).substr(0, 4))
        const url = this.getLinkUrl(dp) ? this.getLinkUrl(dp).substr(0, 4) : ''
        const rtn = !(url === 'http')
        return rtn
      },
      getExposedADUrl (dp) { // TERA 광고코드 노출 스크립트 비동기 호출
        if (dp.section.idx === 2) console.log(this.getAccess, dp[this.attachaccess('ad_code')])
        return dp.is_advertising && dp[this.attachaccess('ad_code')] && dp.ad_channel !== this.getExclusionCodeAdChannel
          ? this.getAdViewUrl + dp[this.attachaccess('ad_code')]
          : ''
      },
      clickedAD (dp) {// TERA 광고코드 클릭 스크립트 비동기 호출
        if (dp.is_advertising && dp[this.attachaccess('ad_code')] && dp.ad_channel !== this.getExclusionCodeAdChannel) {
          _LA.BCT(dp[this.attachaccess('ad_code')])
          console.log('ad click')
        }
      },
      triggerExposedADUrl () {  // 롤링되지 않는 섹션의 TERA노출 이벤트를 강제 트리거 시킴 (페이지 이동시 노출 이벤트 발생)
        // console.log(this.getRootComponentName, this.getParentComponentName, this.getCurrentComponentName, this)
        // if (this.getCurrentDisplayRow) console.log(this.getIsActivated, this.getCurrentDisplayRow.section.pathName)
        if (this.getCurrentDisplayRow && (this.getCurrentDisplayRow.section.data[0].idx === 1 || this.getIsActivated) && this.getCurrentDisplayDataCnt === 1 ) {
          // console.log(this.getCurrentDisplayRow.did, this.getCurrentDisplayRow.section.pathName, this.getCurrentDisplayDataCnt)
          const rtn = this.getExposedADUrl(this.getCurrentDisplayRow)
          if (rtn) {
            const img = (new Image(0,0)).src=rtn
            // console.log('trigger tera exposed event.')
            // console.log(`did: ${this.getCurrentDisplayRow.did}, section: ${this.getCurrentDisplayRow.section.pathName.replace(/\ /g, '')}`)
          }
        }
      }
    }
  }
</script>

<style scoped>
  a {
    cursor: pointer;
  }

  /deep/ .on { color: #01e1c9; }

  /deep/ .el-carousel__arrow {
    display: none;
  }
  /deep/ .q-carousel__navigation .q-btn {
    margin: 2px 2px;
  }

  /deep/ .overflow_visible {
    overflow: visible !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
  }

  .display-container {
    -ms-overflow-style: none;
  }
  .display-container::-webkit-scrollbar {
      display:none;
  }

  /deep/  .scroll {
    overflow: hidden !important;
  }

</style>

<style>
  .display-container a > img {
    /*max-width: 320px !important;*/
    height: auto !important;
    width: 100% !important;
  }
  .display-container .q-carousel {
    background-color: transparent !important;
  }
</style>
