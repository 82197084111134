<script>
  import DisplayContainer from 'src/pc/components/DisplayContainer'
  export default {
    extends: DisplayContainer,
    computed: {
      getAccess() {
        return 'app'
      },
      getAdViewUrl() {
        // return 'https://teralog.techhub.co.kr/imp?la_gc=TR8703000270&la_src=ib&la_cnfg='  // 테라 실계정 Mobile
        // return 'https://teralog.techhub.co.kr/imp?la_gc=TR8703000270&la_src=ib&la_cnfg='  // 테라 테스트계정 Mobile
        // return 'https://teralog.techhub.co.kr/imp?la_gc=CP4B3643229850&la_src=ib&la_cnfg=' // 테라 실계정 PC
        // return 'https://teralog.techhub.co.kr/imp?la_gc=TR8703005279&la_src=ib&la_cnfg=' // 테라 테스트계정 PC
      }
    }
  }
</script>

<style scoped>
  a {
    cursor: pointer;
  }

  /deep/ .on { color: #01e1c9; }

  /deep/ .el-carousel__arrow {
    display: none;
  }
  /deep/ .q-carousel__navigation .q-btn {
    margin: 2px 2px;
  }

  /deep/ .overflow_visible {
    overflow: visible !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
  }

  .display-container {
    -ms-overflow-style: none;
  }
  .display-container::-webkit-scrollbar {
    display:none;
  }

  /deep/  .scroll {
    overflow: hidden !important;
  }

</style>

<style>
  .display-container a > img {
    /*max-width: 320px !important;*/
    height: auto !important;
    width: 100% !important;
  }
  .display-container .q-carousel {
    background-color: transparent !important;
  }
</style>
