<template>

  <div class="ad_bottom flex_center">

      <!--<display-container :section="7" min-height="300px">
        <div slot-scope="{ component, row }">
          <img :src="component.getImage(row)" width="100%" height="auto" style="max-height: 300px" />
        </div>
      </display-container>-->

  </div>

</template>

<script>
  import CommonComputed from 'src/_mixins/CommonComputed'
  import DisplayContainer from './DisplayContainer'
  export default {
    name: "SubHeader",
    components: {
      DisplayContainer
    },
    computed: {
      ...CommonComputed
    }
  }
</script>

<style scoped>
  .ad_bottom {
    padding-top: 120px;
		background: #fff;
		margin-top: -8px;
    display: none;
  }
</style>

<style>
  .ad_bottom {
  	/* height: 200px; */
  }
  .ad_bottom > div {
    width: 100% !important;
    height: auto !important;
  }
  .ad_bottom .display-container a > img {
    max-width: 320px !important;
  }
</style>