import Const from 'src/_mixins/Consts'
export default {
  getAppName () {
    return Const.APP_NAME
  },
  getCurrentComponentName () {
    return this.$options.name
  },
  getParentComponentName () {
    return this.$parent.$options.name
  },
  getRootComponentName () {
    return this.$parent.$parent && this.$parent.$parent.$options ? this.$parent.$parent.$options.name : this.$options.name
  },
  getRouteName () {
    return this.$route.name
  },
  getRouteRootPath () {
    return this.$route.path.split('/')[1]
  },
  getRouteFullPath () {
    return this.$route.path.substring(1)
  },
  getRouteParams () {
    return this.$route.params
  }
  ,getCurrentTime () {
    const dt = new Date()
    return `${dt.getFullYear()}년 ${dt.getMonth()+1}월 ${dt.getDate()}일 ${(dt.getHours()-1)}시`
  },
  getCurrentDate () {
    const dt = new Date()
    const rtn = `${dt.getFullYear()}.${dt.getMonth()+1}.${dt.getDate()}`
    return rtn
  },
  getLastMonthDate () {
    const dt = new Date()
    const rtn = `${dt.getFullYear()}.${dt.getMonth()}.${dt.getDate()}`
    return rtn
  },
  getCurrentDateForChart () {
    const dt = new Date()
    const rtn = `${dt.getFullYear()}-${('0'+(dt.getMonth()+1)).slice(-2)}-${('0'+(dt.getDate())).slice(-2)}`
    return rtn
  }
}
