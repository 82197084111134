import Vue from 'vue'
import * as types from './mutation_types'
import axios from 'axios'
import { date } from 'quasar'

export default {
  putListOfCalledAPI: (context, payload) => {
    context.commit(types.PUSH_LIST_OF_CALLED_API, payload)
  },
  setDisplayCateData: (context, payload) => {
    context.commit(types.UPDATE_DISPLAY_CATE_DATA, payload)
  },
  setDisplayData: (context, payload) => {
    context.commit(types.UPDATE_DISPLAY_DATA, payload)
  },
  setChartData: (context, payload) => {
    context.commit(types.UPDATE_CHART_DATA, payload)
  },
  setHotelRegionData: (context) => {
    const cnt = context.getters.getHotelRegionDataCnt
    if (cnt < 1) {
      axios.get('/hotel_regions/sub/1').then(rs => {
        context.commit(types.UPDATE_HOTEL_REGION_DATA, rs.data)
      })
    }
  },
  setHotelHashTagData: (context) => {
    const cnt = context.getters.getHotelHashTagData
    if (cnt < 1) {
      axios.get('/hash_tags').then(rs => {
        context.commit(types.UPDATE_HOTEL_HASH_TAG_DATA, rs.data)
      })
    }
  },
  setAirlineCorpData: (context) => {
    const cnt = context.getters.getAirlineCorpDataCnt
    if (cnt < 1) {
      axios.get('/airline_corps').then((rs) => {
        context.commit(types.UPDATE_AIRLINE_CORP_DATA, rs.data)
      })
    }
  },
  setToastBannerSmallSize: (context, payload) => {
    context.commit(types.CHANGE_SIZE_TOAST_BANNER, payload)
  },
  setCurrentChartTitle: (context, payload) => {
    context.commit(types.UPDATE_CURRENT_CHART_TITLE, payload)
  },
  setOpenAllChartDialog: (context, payload) => {
    context.commit(types.CHANGE_OPEN_ALL_CHART_DIALOG, payload)
  },
  setOpenShareDialog: (context, payload) => {
    context.commit(types.CHANGE_OPEN_SHARE_DIALOG, payload)
  },
  setOpenTermsDialog: (context, payload) => {
    context.commit(types.CHANGE_OPEN_TERMS_DIALOG, payload)
  },
  setOpenPolicyDialog: (context, payload) => {
    context.commit(types.CHANGE_OPEN_POLICY_DIALOG, payload)
  },
  setCurrentCalendarLabel: (context, payload) => {
    context.commit(types.UPDATE_CURRENT_CALENDAR_LABEL, payload)
  },
  setOpenCalendarDialog: (context, payload) => {
    context.commit(types.CHANGE_OPEN_CALENDAR_DIALOG, payload)
  },
  setOpenTeamDropdown: (context, payload) => {
    context.commit(types.UPDATE_OPEN_DROPDOWN, payload)
  },
  setCurrentContentsData: (context, payload) => {
    context.commit(types.UPDATE_CURRENT_CONTENTS_DATA, payload)
  },
  setCurrentHotel: (context, payload) => {
    context.commit(types.UPDATE_CURRENT_HOTEL, payload)
  },
  setSearchHotelQuery: (context, payload) => {
    context.commit(types.UPDATE_SEARCH_HOETL_QUERY, payload)
  },
  resetSearchHotelQuery: (context) => {
    context.commit(types.UPDATE_SEARCH_HOETL_QUERY, context.getters.cloneData(context.getters.getSearchHotelQueryOrigin))
  },
  setSearchHotelResult: (context, payload) => {
    context.commit(types.UPDATE_SEARCH_HOTEL_RESULT, payload)
  },
  setCalendarForYear: (context) => {
    const cnt = context.getters.getCalendarForYearCnt
    if (cnt < 1) {
      axios.get('/calendar/year').then((rs) => {
        context.commit(types.UPDATE_CALENDAR_FOR_YEAR, rs.data)
      })
    }
  },
  setLoginUserData: (context, payload) => {
    context.commit(types.UPDATE_LOGIN_USER_DATA, payload)
  },
  setLatestChartData: (context) => {
    const term = 'monthly'
    const chart = 'hot100'
    const hotelGb = 'DOMESTIC'
    const obj = context.getters.getLatestChartKey(term)

    const params = {
      params: {
        date: obj.day,
        term: term,
        hotel_gb: hotelGb,
        limit: 100,
        chart: chart
      }
    }
    axios.get('/charts/ranking', params).then(rs => {
      if (rs.data.length > 0) {
        vm.$store.dispatch('setChartData', {key: obj.key, data: rs.data})
      }
    })
  },
  setAppPlatform: (context, payload) => {
    console.log(payload)
    context.commit(types.UPDATE_APP_PLATFORM, payload)
  }
}
