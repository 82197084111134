<template>
  <div class="full-width full-height bg-primary">
    <transition name="el-fade-in-linear">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'BridgeLayout'
  }
</script>

