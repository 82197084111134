<template>

  <header class="con_hd">
    <div class="inner">
      <h2>{{getPageTitle}}</h2>
      <div class="con_hd_ad">

        <display-container :section="4" align="right" min-height="100px" empty-bg-color="transparent">
          <div slot-scope="{ component, row }">
            <img :src="component.getImage(row)" width="auto" height="auto" style="max-width: 1000px; max-height:100px" />
          </div>
        </display-container>

      </div>
    </div>
  </header>

</template>

<script>
  import CommonComputed from 'src/_mixins/CommonComputed'
  import DisplayContainer from './DisplayContainer'
  export default {
    name: "SubHeader",
    components: {
      DisplayContainer
    },
    computed: {
      ...CommonComputed,
      getPageTitle () {
        const titles = [
          {name: 'Home', title: ''},
          {name: 'Chart', title: '고릴라차트'},
          {name: 'Search', title: '카드검색'},
          {name: 'SearchAll', title: '통합검색결과'},
          {name: 'CardCompare', title: '카드혜택비교'},
          {name: 'CompareDetail', title: '카드혜택비교'},
          {name: 'Contents', title: '콘텐츠'},
					{name: 'ContentsEvent', title: '　'},
          {name: 'Team', title: '카드사'},
          {name: 'Player', title: 'PLAYER'},
          {name: 'Card', title: '카드검색'},
          {name: 'CardDetail', title: '카드혜택안내'},
					{name: 'Special', title: '11월의 추천'},
					{name: 'SpecialLoca', title: '롯데카드'},
					{name: 'SpecialGreenCard', title: '그린카드 v2'}
        ]
        const currentTitle = titles.find(row => row.name === this.getRouteName)
        const rootTitle = titles.find(row => row.name.toLowerCase().includes(this.getRouteRootPath))

        return currentTitle && currentTitle.title ? currentTitle.title : rootTitle && rootTitle.title ? rootTitle.title : ''
      }
    }
  }
</script>

<style scoped>
  .con_hd_ad {
    float: right;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>

<style>
  .con_hd {
    height: auto !important;
  }
  .con_hd .inner {
    background: #fff !important;
  }
  .con_hd .con_hd_ad {
    width: 100% !important;
    height: auto !important;
    max-width: 100vm !important;
    padding: 5px 0;
  }
  .con_hd .con_hd_ad img {
    width: 320px !important;
    max-width: 100vm !important;
    height:auto !important;
  }
  .con_hd .inner h2 {
    display: none !important;
  }
</style>